import React, { useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
} from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { ReactComponent as EditIcon } from "src/assets/icons/edit.svg";
import EditNameModal from "./EditNameModal";
import { useAppSelector, useMobile, useWeb3Context } from "../../hooks";
import NoData from "src/assets/images/noData.svg";
import { customNumberFormat, shorten, trim } from "src/helpers";
import { error } from "src/slices/MessagesSlice";
import { useDispatch } from "react-redux";
import { getRankingList, setTeamName } from "src/slices/CommunitySlice";

const rankingColor = ["#fcd000", "#62A1FF", "#FFAA0D"];

const RankingList = () => {
  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();

  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rankingTotal = useAppSelector((state) => {
    return state.community.rankingTotal;
  });
  const rankingList = useAppSelector((state) => {
    return state.community.rankingList;
  });
  const minPower = useAppSelector((state) => {
    return state.community.minPower;
  });

  const editName = async (teamName: string) => {
    console.log("editName", teamName);
    if (!teamName) {
      return dispatch(error(t`Please enter a name!`));
    }
    await dispatch(
      setTeamName({
        provider,
        networkID: chainID,
        name: teamName,
      })
    );
    setModalOpen(false);
    dispatch(
      getRankingList({
        page: 1,
        provider,
        networkID: chainID,
      })
    );
    console.log("edit name");
  };

  const handleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "300px",
          borderRadius: "6px",
          border: "1px solid #2d2d2d",
          background: "#151515",
          px: { xs: "15px", sm: "20px" },
          py: { xs: "20px", sm: "20px" },
          pt: "0 !important",
        }}
      >
        <Box
          sx={{
            height: { sm: "60px", xs: "40px" },
            // px: "20px",
          }}
          display="flex"
          alignItems={"center"}
          // flexDirection={{
          //   xs: "column",
          //   sm: "row",
          // }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: { sm: "24px", xs: "18px" },
              fontWeight: "600",
              mr: 1,
            }}
          >
            <Trans>List of 99 winners</Trans>
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems={"center"}
          sx={{
            height: { sm: "45px", xs: "30px" },
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: { sm: "18px", xs: "14px" },
              fontWeight: "600",
            }}
          >
            {"("}
            <Trans>Minimum Contribution Value</Trans>:
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: { sm: "20px", xs: "16px" },
              fontWeight: "600",
            }}
          >
            {customNumberFormat(Number(minPower), 4)}
            {")"}
          </Typography>
        </Box>
        <Grid container>
          {rankingList && rankingList.length > 0 ? (
            rankingList.map((item: any, index: number) => (
              <Grid item xs={6} sm={3} key={item.member}>
                <Box
                  display="flex"
                  alignItems={"center"}
                  sx={{
                    minHeight: "40px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#eaecef",
                      fontSize: "16px",
                      fontWeight: "500",
                      borderBottom: "none",
                      mr: 1,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.teamName ? item.teamName : shorten(item.member)}
                  </Typography>
                  {item.member &&
                    address &&
                    item.member.toLowerCase() == address.toLowerCase() && (
                      <Box
                        onClick={handleModal}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <EditIcon />
                      </Box>
                    )}
                </Box>
              </Grid>
            ))
          ) : (
            <Box
              sx={{ width: "100%", height: "100%", minHeight: "240px" }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <img src={NoData} alt="No Data" />
              <Typography
                sx={{
                  pt: 2,
                  color: "#848E9C",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "400",
                }}
              >
                <Trans>No data</Trans>
              </Typography>
            </Box>
          )}
        </Grid>
        {/* <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    color: "#848E9C",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderBottom: "none",
                    width: "20px",
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    color: "#848E9C",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderBottom: "none",
                  }}
                >
                  <Trans>Name</Trans>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#848E9C",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderBottom: "none",
                  }}
                >
                  <Trans>Contribution</Trans>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rankingList && rankingList.length > 0 ? (
                rankingList.map((item: any, index: number) => (
                  <TableRow
                    key={item.member}
                    sx={{
                      "&:nth-of-type(even)": {
                        backgroundColor: "#111",
                      },
                      // '&:nth-of-type(odd)':{
                      //   backgroundColor:'yellow'
                      // },
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        borderBottom: "none",
                        width: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          color:
                            index <= 2 && currentPage == 1
                              ? rankingColor[index]
                              : "#eaecef",
                          fontSize: "16px",
                          fontWeight: "500",
                          borderBottom: "none",
                        }}
                      >
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        borderBottom: "none",
                        flex: 1,
                      }}
                    >
                      <Box display="flex" alignItems={"center"}>
                        <Typography
                          sx={{
                            color:
                              index <= 2 && currentPage == 1
                                ? rankingColor[index]
                                : "#eaecef",
                            fontSize: "16px",
                            fontWeight: "500",
                            borderBottom: "none",
                            mr: 1,
                          }}
                        >
                          {item.teamName ? item.teamName : shorten(item.member)}
                        </Typography>
                        {item.member &&
                          address &&
                          item.member.toLowerCase() ==
                            address.toLowerCase() && (
                            <Box
                              onClick={handleModal}
                              sx={{
                                cursor: "pointer",
                              }}
                            >
                              <EditIcon />
                            </Box>
                          )}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        borderBottom: "none",
                        // flex:1
                      }}
                    >
                      <Typography
                        sx={{
                          color: index <= 2 ? rankingColor[index] : "#eaecef",
                          fontSize: "16px",
                          fontWeight: "500",
                          borderBottom: "none",
                        }}
                      >
                        {customNumberFormat(Number(item.power), 4)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={3}
                    sx={{
                      height: "300px",
                      borderBottom: "none",
                    }}
                  >
                    <Box
                      sx={{ width: "100%", height: "100%" }}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <img src={NoData} alt="No Data" />
                      <Typography
                        sx={{
                          pt: 2,
                          color: "#848E9C",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>No data</Trans>
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> */}
      </Box>
      <EditNameModal
        open={modalOpen}
        handleClose={handleModal}
        editName={editName}
      />
    </Box>
  );
};

export default RankingList;
