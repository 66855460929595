import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Modal, Paper, Box } from "@mui/material";
import NavContent from "./NavContent";
import { NavLink, useHistory } from "react-router-dom";

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
  },
}));

function NavDrawer({ mobileOpen, handleDrawerToggle }) {
  const classes = useStyles();

  return (
    <Modal
      open={mobileOpen}
      onClose={handleDrawerToggle}
      sx={{
        left: "15px",
        top: "20px",
      }}
    >
      <NavContent mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
    </Modal>
    // <Drawer
    //   variant="temporary"
    //   anchor={"left"}
    //   open={mobileOpen}
    //   onClose={handleDrawerToggle}
    //   onClick={handleDrawerToggle}
    //   classes={{
    //     paper: classes.drawerPaper,
    //   }}
    //   ModalProps={{
    //     keepMounted: true, // Better open performance on mobile.
    //   }}
    // >
    //   <NavContent mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
    // </Drawer>
  );
}

export default NavDrawer;
