import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { close, handle_obsolete } from "../../slices/MessagesSlice";
import store from "../../store";
import { LinearProgress, Snackbar, Box, Alert } from "@mui/material";
import { ReactComponent as MessageIcon } from "../../assets/icons/message.svg";
import { ReactComponent as MessageSuccessIcon } from "../../assets/icons/message-success.svg";
import { ReactComponent as MessageErrorIcon } from "../../assets/icons/message-error.svg";
import { ReactComponent as MessageInfoIcon } from "../../assets/icons/message-info.svg";
import PlaylistAddCheckCircleRoundedIcon from "@mui/icons-material/PlaylistAddCheckCircleRounded";

import AlertTitle from "@mui/material/AlertTitle";
// import "./ConsoleInterceptor.js";

function Linear({ message }) {
  const [progress, setProgress] = useState(100);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 0) {
          clearInterval(timer);
          dispatch(close(message));
          return 0;
        }
        const diff = oldProgress - 5;
        return diff;
      });
    }, 150);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%", mt: 1.25, display: "none" }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}
const severitiesColor = {
  success: "#5AF0BE",
  info: "#FCD000",
  error: "#F15958",
};
const severitiesIcon = {
  success: <MessageSuccessIcon />,
  info: <MessageInfoIcon />,
  error: <MessageErrorIcon />,
};
// A component that displays error messages
function Messages() {
  const messages = useSelector((state) => state.messages);
  const dispatch = useDispatch();
  // Returns a function that can closes a message
  const handleClose = function (message) {
    return function () {
      dispatch(close(message));
    };
  };
  return (
    <div>
      <div>
        {messages.items.map((message, index) => {
          return (
            <Snackbar
              open={message.open}
              key={index}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{
                zIndex: "11111",
                top: "80px !important",
              }}
            >
              <Alert
                variant="outlined"
                // variant="filled"
                // icon={false}
                icon={severitiesIcon[message.severity]}
                // startDecorator={<MessageIcon />}
                // icon={<PlaylistAddCheckCircleRoundedIcon />}
                severity={message.severity}
                // onClose={handleClose(message)}
                // NOTE (appleseed): mui includes overflow-wrap: "break-word", but word-break: "break-word" is needed for webKit browsers
                style={{ wordBreak: "break-word" }}
                sx={{
                  borderRadius: "8px",
                  border: `1px solid ${severitiesColor[message.severity]}`,
                  color: `${severitiesColor[message.severity]}`,
                  background:
                    message.severity === "success" ? "#151515" : "#000",
                }}
              >
                {message.text}
                <Linear message={message} sx={{ display: "none" }} />
              </Alert>
            </Snackbar>
          );
        })}
      </div>
    </div>
  );
}
// Invoke repetedly obsolete messages deletion (should be in slice file but I cannot find a way to access the store from there)
window.setInterval(() => {
  store.dispatch(handle_obsolete());
}, 1000);
export default Messages;
