import React from "react";
import ModularBg from "../../assets/images/modular-bg.png";
import Modular1 from "../../assets/images/modular-1.png";
import Modular2 from "../../assets/images/modular-2.png";
import Modular3 from "../../assets/images/modular-3.png";
import { Box, Tooltip, Typography } from "@mui/material";

const MapModular = () => {
  return (
    <Box
      sx={{
        width: "19.4%",
        height: "21.88%",
        // background: `url(${ModularBg}) no-repeat 100% 100%`,
        // backgroundSize: "cover",
        position: "absolute",
        inset: "18% auto auto 64%",
        "&> img": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <img src={ModularBg} />
      <Box
        sx={{
          position: "absolute",
          inset: "-12% auto auto 69%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Modular1} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "30% auto auto 83%",
          width: "92.47%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Modular2} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "78% auto auto 73%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Modular3} />
      </Box>
    </Box>
  );
};

export default MapModular;
