import { Trans } from "@lingui/macro";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import BorderBg from "../../assets/icons/border.svg";
import BorderBgMobile from "../../assets/icons/border-m.svg";
import LeftTenBg from "../../assets/icons/left-ten.svg";
import HighlightsBg from "../../assets/images/highlights-bg.png";
import Highlights1 from "../../assets/icons/heiglights-1.svg";
import Highlights2 from "../../assets/icons/heiglights-2.svg";
import Highlights3 from "../../assets/icons/heiglights-3.svg";
import Highlights4 from "../../assets/icons/heiglights-4.svg";
import { useMobile } from "../../hooks";

const Highlights = () => {
  const isSmallScreen = useMobile();
  return (
    <Box
      sx={{
        width: "100%",
        mb: {
          xs: "40px",
          md: "100px",
        },
        px: {
          xs: "15px",
          sm: "0",
        },
      }}
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      {/* title */}
      <Box
        sx={{
          mb: { md: "50px", xs: "20px" },
          mt: { xs: "20px", md: "0" },
        }}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography
          sx={{
            color: "#fcd000",
            fontSize: { md: "39.4px", sm: "24px" },
            fontWeight: "700",
            mr: 1.25,
            textTransform: "uppercase",
          }}
        >
          <Trans>Highlights </Trans>
        </Typography>
        {/* <Typography
          sx={{
            color: "#fff",
            fontSize: { md: "39.4px", sm: "24px" },
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          <Trans>of Agreement Ownership</Trans>
        </Typography> */}
      </Box>
      {/* content */}
      <Box
        sx={{
          maxWidth: "1340px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
          minHeight: "520px",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                border: "1px solid #fcd000",
                width: "100%",
                height: "520px",
                borderRadius: { xs: "4px 4px 0 0 ", md: "10px 0 0 10px" },
                borderRight: { md: "none" },
                position: "relative",
                // overflow: "hidden",
                backgroundImage: `url(${HighlightsBg})`,
                backgroundSize: "100% 253px",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* content */}
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                flexDirection={"column"}
                sx={{ height: "100%", p: "24px" }}
              >
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  flexDirection={"column"}
                  sx={{
                    height: "300px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: "400",
                      }}
                    >
                      1
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "135px",
                      height: "165px",
                      "&>img": {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    <img src={Highlights1} />
                  </Box>
                </Box>
                <Box sx={{ height: "130px" }}>
                  <Typography
                    sx={{
                      color: "#fcd000",
                      fontSize: "18px",
                      fontWeight: "500",
                      mb: "10px",
                    }}
                  >
                    <Trans>Protocol Owned Liquidity (POL)</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>
                      Nova Bank introduces Protocol Owned Liquidity, which
                      reduces the reliance on liquidity mining as opposed to
                      relying on external liquidity providers.
                    </Trans>
                  </Typography>
                </Box>
              </Box>
              {/* top right bg */}
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "initial",
                  },
                  width: "22px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 0,
                  right: "-11px",
                  top: "-14px",
                  rotate: "-90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={LeftTenBg} />
              </Box>
              {/* bottom left bg */}
              {/* mobile top right bg */}
              <Box
                sx={{
                  width: { xs: "12px", sm: "26px" },
                  height: { xs: "12px", sm: "26px" },
                  position: "absolute",
                  zIndex: 0,
                  left: { md: "-1px", sm: "-1px", xs: "initial" },
                  right: { md: "initial", sm: "initial", xs: "-6px" },
                  bottom: { md: "-1px", sm: "-1px", xs: "initial" },
                  top: { md: "initial", sm: "initial", xs: "-1px" },
                  rotate: "90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={isSmallScreen ? BorderBgMobile : BorderBg} />
              </Box>
              {/* right center bg */}
              <Box
                sx={{
                  display: {
                    md: "initial",
                    xs: "none",
                  },
                  width: "6px",
                  height: "38px",
                  position: "absolute",
                  zIndex: 1,
                  right: "-3px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                }}
              />
              {/* mobile bottom left bg */}
              <Box
                sx={{
                  display: { xs: "initial", sm: "none" },
                  width: "22px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 1,
                  bottom: "-21px",
                  left: "-5px",
                  rotate: "180deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={LeftTenBg} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                border: "1px solid #fcd000",
                width: "100%",
                height: "520px",
                borderRight: { md: "none" },
                borderTop: { xs: "none", sm: "1px solid #fcd000" },
                position: "relative",
                backgroundImage: `url(${HighlightsBg})`,
                backgroundSize: "100% 253px",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* content */}
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                flexDirection={"column"}
                sx={{ height: "100%", p: "24px" }}
              >
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  flexDirection={"column"}
                  sx={{
                    height: "300px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: "400",
                      }}
                    >
                      2
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "139px",
                      height: "139px",
                      "&>img": {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    <img src={Highlights2} />
                  </Box>
                </Box>
                <Box sx={{ height: "130px" }}>
                  <Typography
                    sx={{
                      color: "#fcd000",
                      fontSize: "18px",
                      fontWeight: "500",
                      mb: "10px",
                    }}
                  >
                    <Trans>Range stabilization mechanism (RBS)</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>
                      The RBS mechanism ensures that NVB The price fluctuates
                      within a certain range and is not linked to any legal
                      currency.
                    </Trans>
                  </Typography>
                </Box>
              </Box>
              {/* bottom right bg */}
              <Box
                sx={{
                  width: "22px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 0,
                  right: { sm: "-11px", xs: "-5px" },
                  bottom: { sm: "-14px", xs: "-21px" },
                  rotate: { xs: "0deg", sm: "90deg" },
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={LeftTenBg} />
              </Box>
              {/* right center bg */}
              <Box
                sx={{
                  display: { xs: "none", sm: "initial" },
                  width: "6px",
                  height: "38px",
                  position: "absolute",
                  zIndex: 1,
                  right: "-3px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                }}
              />
              {/* mobile top center bg */}
              <Box
                sx={{
                  display: { xs: "initial", sm: "none" },
                  width: "38px",
                  height: "3px",
                  position: "absolute",
                  zIndex: 1,
                  top: "-2px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                }}
              />
            </Box>
            {/* mobile bottom right bg */}
            {/* <Box
              sx={{
                display: { xs: "initial", sm: "none" },

                width: "22px",
                height: "40px",
                position: "absolute",
                zIndex: 1,
                right: "-4px",
                bottom: "-21px",
                rotate: { xs: "180deg", md: "0deg" },
                "&>img": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={LeftTenBg} />
            </Box> */}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                border: "1px solid #fcd000",
                borderTop: { xs: "none", sm: "1px solid #fcd000" },

                width: "100%",
                height: "520px",
                borderRight: { md: "none" },
                position: "relative",
                backgroundImage: `url(${HighlightsBg})`,
                backgroundSize: "100% 253px",
                backgroundRepeat: "no-repeat",
              }}
            >
              {/* content */}
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
                flexDirection={"column"}
                sx={{ height: "100%", p: "24px" }}
              >
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  flexDirection={"column"}
                  sx={{
                    height: "300px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "22px",
                        fontWeight: "400",
                      }}
                    >
                      3
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "138px",
                      height: "151px",
                      "&>img": {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    <img src={Highlights3} />
                  </Box>
                </Box>
                <Box sx={{ height: "130px" }}>
                  <Typography
                    sx={{
                      color: "#fcd000",
                      fontSize: "18px",
                      fontWeight: "500",
                      mb: "10px",
                    }}
                  >
                    <Trans>Lending protocol</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "13px",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>
                      Users can borrow against NVB s reserve assets at very low
                      interest rates, which provides users with a great deal of
                      financial flexibility.
                    </Trans>
                  </Typography>
                </Box>
              </Box>

              {/* top right bg */}
              <Box
                sx={{
                  display: { xs: "none", sm: "initial" },
                  width: "22px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 0,
                  right: "-11px",
                  top: "-14px",
                  rotate: "-90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={LeftTenBg} />
              </Box>
              {/* right center bg */}
              <Box
                sx={{
                  display: { xs: "none", md: "initial" },
                  width: "6px",
                  height: "38px",
                  position: "absolute",
                  zIndex: 1,
                  right: "-3px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                }}
              />
              {/* mobile top center bg */}
              <Box
                sx={{
                  display: { xs: "initial", sm: "none" },
                  width: "38px",
                  height: "3px",
                  position: "absolute",
                  zIndex: 1,
                  top: "-2px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  border: "1px solid #fcd000",
                  width: "100%",
                  height: "520px",
                  borderRadius: { xs: "0 0 3px 3px", md: "0 10px 10px 0" },
                  borderTop: { xs: "none", sm: "1px solid #fcd000" },
                  position: "relative",
                  overflow: "hidden",
                  backgroundImage: `url(${HighlightsBg})`,
                  backgroundSize: "100% 253px",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* content */}
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  flexDirection={"column"}
                  sx={{ height: "100%", p: "24px" }}
                >
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    flexDirection={"column"}
                    sx={{
                      height: "300px",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "22px",
                          fontWeight: "400",
                        }}
                      >
                        4
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "152px",
                        height: "152px",
                        "&>img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={Highlights4} />
                    </Box>
                  </Box>
                  <Box sx={{ height: "130px" }}>
                    <Typography
                      sx={{
                        color: "#fcd000",
                        fontSize: "18px",
                        fontWeight: "500",
                        mb: "10px",
                      }}
                    >
                      <Trans>Decentralized governance</Trans>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "13px",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>
                        The sNVB will participate in governance, allowing
                        community members to participate in major decisions
                        about the agreement
                      </Trans>
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: { xs: "12px", sm: "26px" },
                    height: { xs: "12px", sm: "26px" },
                    position: "absolute",
                    zIndex: 0,
                    top: { md: "-1px", xs: "initial" },
                    right: { md: "-1px", xs: "initial" },
                    bottom: { md: "initial", xs: "-1px" },
                    left: { md: "initial", xs: "5px" },
                    rotate: "90deg",
                    "&>img": {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <img src={isSmallScreen ? BorderBgMobile : BorderBg} />
                </Box>
              </Box>
              {/* mobile top center bg */}
              <Box
                sx={{
                  display: { xs: "initial", sm: "none" },
                  width: "38px",
                  height: "3px",
                  position: "absolute",
                  zIndex: 1,
                  top: "-2px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                }}
              />
              {/* top right bg */}
              <Box
                sx={{
                  display: { md: "none" },
                  width: "22px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 0,
                  left: "-4px",
                  top: "-21px",
                  rotate: "180deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={LeftTenBg} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Highlights;
