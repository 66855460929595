import React from "react";
import { Box, Typography } from "@mui/material";

import { Trans } from "@lingui/macro";
import Footer from "../../components/Footer/Footer";
import { useMobile } from "../../hooks";
import ReactPlayer from "react-player";
import BannerVideo from "../../assets/images/home.mp4";
import BannerBg from "../../assets/images/home.png";
import { useAppSelector } from "../../hooks";
import { toolNumber } from "src/helpers";
import Banner from "./Banner";
import Highlights from "./Highlights";
import CardList from "./CardList";
import { PartnerCard } from "./PartnerCard";
import Map from "./Map";
const Home = ({ handleScrollTop }: any) => {
  const { isSmallScreen } = useMobile();
  // const [isPlaying, setIsPlaying] = React.useState(false);
  // const treasuryMarketValue = useAppSelector(state => state.app.treasuryMarketValue);
  // const marketValue = useAppSelector(state => state.app.marketValue);
  // // const stakingAPY = useAppSelector(state => state.app.stakingAPY);
  // const stakingAPY = useAppSelector(state => {
  //   return state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0;
  // });
  // const tokenBalInStaking = useAppSelector(state => state.app.tokenBalInStaking);
  // const trimmedStakingAPY = stakingAPY * 100;

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems="center"
      sx={{
        position: "relative",
        background: "#151515",
        // px: isSmallScreen ? "15px" : "0",
        pt: { xs: "15px", sm: "0" },
      }}
    >
      <Banner />
      <Highlights />
      <CardList />
      <PartnerCard />
      <Map />
    </Box>
  );
};

export default Home;
