import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import ierc20ABIJson from "../abi/IERC20.json";
import OlympusStakingABIJson from "../abi/OlympusStakingv2.json";
import StakingHelperABIJson from "../abi/StakingHelper.json";

import {
  clearPendingTxn,
  fetchPendingTxns,
  getStakingTypeText,
} from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAccountSuccess,
  getBalances,
  loadAccountDetails,
} from "./AccountSlice";
import { error, info } from "../slices/MessagesSlice";
import {
  IActionValueAsyncThunk,
  IChangeApprovalAsyncThunk,
  IJsonRPCError,
  IBaseAddressAsyncThunk,
} from "./interfaces";
import { segmentUA } from "../helpers/userAnalyticHelpers";
import { IERC20, OlympusStakingv2, StakingHelper } from "src/typechain";
import ReleasePoolABIJson from "../abi/ReleasePool.json";
import ReleasePoolHelperABIJson from "../abi/ReleasePoolHelper.json";
import { t } from "@lingui/macro";
const ierc20ABI = ierc20ABIJson.abi;
const OlympusStakingABI = OlympusStakingABIJson.abi;
const StakingHelperABI = StakingHelperABIJson.abi;
const ReleasePoolABI = ReleasePoolABIJson.abi;
const ReleasePoolHelperABI = ReleasePoolHelperABIJson.abi;
interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ token, provider, address, networkID }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    const signer = provider;
    const ohmContract = new ethers.Contract(
      addresses[networkID].OHM_ADDRESS as string,
      ierc20ABI,
      signer
    ) as IERC20;
    const sohmContract = new ethers.Contract(
      addresses[networkID].SOHM_ADDRESS as string,
      ierc20ABI,
      signer
    ) as IERC20;
    let approveTx;

    try {
      if (token === "ohm") {
        // won't run if stakeAllowance > 0
        const estimateGas = await ohmContract.estimateGas.approve(
          addresses[networkID].STAKING_HELPER_ADDRESS,
          ethers.utils.parseUnits("1000000000", "gwei").toString()
        );

        approveTx = await ohmContract.approve(
          addresses[networkID].STAKING_HELPER_ADDRESS,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      } else if (token === "sOhm") {
        const estimateGas = await sohmContract.estimateGas.approve(
          addresses[networkID].STAKING_ADDRESS,
          ethers.utils.parseUnits("1000000000", "gwei").toString()
        );
        approveTx = await sohmContract.approve(
          addresses[networkID].STAKING_ADDRESS,
          ethers.utils.parseUnits("1000000000", "gwei").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      }

      const text = "Approve " + (token === "ohm" ? "Staking" : "Unstaking");
      const pendingTxnType =
        token === "ohm" ? "approve_staking" : "approve_unstaking";
      if (approveTx) {
        dispatch(
          fetchPendingTxns({
            txnHash: approveTx.hash,
            text,
            type: pendingTxnType,
          })
        );

        await approveTx.wait();
      }
    } catch (e) {
      // dispatch(error((e as IJsonRPCError).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    // go get fresh allowances
    const stakeAllowance = await ohmContract.allowance(
      address,
      addresses[networkID].STAKING_HELPER_ADDRESS
    );
    const unstakeAllowance = await sohmContract.allowance(
      address,
      addresses[networkID].STAKING_ADDRESS
    );
    // console.log("approve staking", {
    //   staking: {
    //     ohmStake: Number(ethers.utils.formatUnits(stakeAllowance, 9)),
    //     ohmUnstake: Number(ethers.utils.formatUnits(unstakeAllowance, 9)),
    //   },
    // });
    return dispatch(
      fetchAccountSuccess({
        staking: {
          ohmStake: Number(ethers.utils.formatUnits(stakeAllowance, 9)),
          ohmUnstake: Number(ethers.utils.formatUnits(unstakeAllowance, 9)),
        },
      })
    );
  }
);

export const changeApprovalInterest = createAsyncThunk(
  "stake/changeApprovalInterest",
  async ({ provider, address, networkID, token }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    const signer = provider;
    // const bTokenContract = new ethers.Contract(
    //   addresses[networkID].bToken as string,
    //   ierc20ABI,
    //   signer
    // ) as IERC20;
    const wbnbContract = new ethers.Contract(
      addresses[networkID].WBNB_ADDRESS as string,
      ierc20ABI,
      signer
    ) as IERC20;
    const sohmContract = new ethers.Contract(
      addresses[networkID].SOHM_ADDRESS as string,
      ierc20ABI,
      signer
    ) as IERC20;

    let approveTx;

    try {
      // if (token == "btoken") {
      //   const estimateGas = await bTokenContract.estimateGas.approve(
      //     addresses[networkID].stakingReleasePool,
      //     ethers.utils.parseUnits("10000000", "18").toString()
      //   );
      //   approveTx = await bTokenContract.approve(
      //     addresses[networkID].stakingReleasePool,
      //     ethers.utils.parseUnits("10000000", "18").toString(),
      //     {
      //       gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      //     }
      //   );
      // } else
      if (token == "sohm") {
        const estimateGas = await sohmContract.estimateGas.approve(
          addresses[networkID].STAKING_ADDRESS,
          ethers.utils.parseUnits("10000000", "gwei").toString()
        );
        approveTx = await sohmContract.approve(
          addresses[networkID].STAKING_ADDRESS,
          ethers.utils.parseUnits("10000000", "gwei").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      } else if (token == "WBNB") {
        const estimateGas = await wbnbContract.estimateGas.approve(
          addresses[networkID].stakingReleasePool,
          ethers.utils.parseUnits("1000", "18").toString()
        );
        approveTx = await wbnbContract.approve(
          addresses[networkID].stakingReleasePool,
          ethers.utils.parseUnits("1000", "18").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      }

      const text = "Approve Claim Interest";
      const pendingTxnType = "approve_claim_interest";
      if (approveTx) {
        dispatch(
          fetchPendingTxns({
            txnHash: approveTx.hash,
            text,
            type: pendingTxnType,
          })
        );

        await approveTx.wait();
      }
    } catch (e) {
      // dispatch(error((e as IJsonRPCError).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    // const releasePoolAllowance = await bTokenContract.allowance(
    //   address,
    //   addresses[networkID].stakingReleasePool
    // );
    // const releasePoolAllowance = await wbnbContract.allowance(
    //   address,
    //   addresses[networkID].stakingReleasePool
    // );
    // const unstakeAllowance = await sohmContract.allowance(
    //   address,
    //   addresses[networkID].STAKING_ADDRESS
    // );

    // return dispatch(
    //   fetchAccountSuccess({
    //     releasePoolAllowance: +releasePoolAllowance,
    //     staking: {
    //       ohmUnstake: +unstakeAllowance,
    //     },
    //   })
    // );
  }
);

export const changeStake = createAsyncThunk(
  "stake/changeStake",
  async (
    { action, value, provider, address, networkID }: IActionValueAsyncThunk,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    // const signer = provider.getSigner();
    const signer = provider;
    const staking = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingABI,
      signer
    ) as OlympusStakingv2;
    const stakingHelper = new ethers.Contract(
      addresses[networkID].STAKING_HELPER_ADDRESS as string,
      StakingHelperABI,
      signer
    ) as StakingHelper;

    let stakeTx;

    try {
      if (action === "stake") {
        const estimateGas = await stakingHelper.estimateGas.stake(
          ethers.utils.parseUnits(value, "gwei")
        );
        stakeTx = await stakingHelper.stake(
          ethers.utils.parseUnits(value, "gwei"),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      } else {
        const estimateGas = await staking.estimateGas.unstake(
          ethers.utils.parseUnits(value, "gwei"),
          true,
          true,
          "0",
          "0"
        );

        stakeTx = await staking.unstake(
          ethers.utils.parseUnits(value, "gwei"),
          true,
          true,
          "0",
          "0",
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          }
        );
      }
      const pendingTxnType = action === "stake" ? "staking" : "unstaking";
      dispatch(
        fetchPendingTxns({
          txnHash: stakeTx.hash,
          text: getStakingTypeText(action),
          type: pendingTxnType,
        })
      );
      await stakeTx.wait();
    } catch (e) {
      const rpcError = e as IJsonRPCError;
      if (
        rpcError.code === -32603 &&
        rpcError.message.indexOf("ds-math-sub-underflow") >= 0
      ) {
        dispatch(
          error(
            "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"
          )
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (stakeTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
    dispatch(loadAccountDetails({ address, networkID, provider }));
  }
);

export const cliamWarmupBalance = createAsyncThunk(
  "stake/cliamWarmupBalance",
  async (
    { provider, address, networkID }: IBaseAddressAsyncThunk,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    // const signer = provider.getSigner();
    const signer = provider;
    const staking = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingABI,
      signer
    ) as OlympusStakingv2;
    let claimTx;
    try {
      const estimateGas = await staking.estimateGas.claim(address);
      claimTx = await staking.claim(address, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const pendingTxnType = "claim";
      dispatch(
        fetchPendingTxns({
          txnHash: claimTx.hash,
          text: "claim",
          type: pendingTxnType,
        })
      );
      await claimTx.wait();
    } catch (e) {
      const rpcError = e as IJsonRPCError;
      if (
        rpcError.code === -32603 &&
        rpcError.message.indexOf("ds-math-sub-underflow") >= 0
      ) {
        dispatch(
          error(
            "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"
          )
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (claimTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(claimTx.hash));
      }
    }
    dispatch(getBalances({ address, networkID, provider }));
  }
);

// startRelease
export const releaseInterest = createAsyncThunk(
  "stake/releaseInterest",
  async (
    { vestingAmt, provider, address, networkID, burnAmt, releaseLevel }: any,
    { dispatch }
  ) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    const vesting = ethers.utils.parseUnits(vestingAmt, "9");
    const burnAmount = ethers.utils.parseUnits(burnAmt, "18");
    // const signer = provider.getSigner();
    const signer = provider;
    const stakingContract = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingABI,
      signer
    ) as any;
    let unstakingTx;

    const sohmContract = new ethers.Contract(
      addresses[networkID].SOHM_ADDRESS as string,
      ierc20ABI,
      signer
    ) as IERC20;
    const principal = await stakingContract.principal(address);
    const sohmBal = await sohmContract.balanceOf(address);
    console.log(
      {
        bal: ethers.utils.formatUnits(sohmBal, "9"),
        principalAmt: ethers.utils.formatUnits(principal, "9"),
        amount: ethers.utils.formatUnits(vesting, "9"),
        params: "params",
        "1": vesting,
        "2": true,
        "3": false,
        "4": burnAmount,
        "5": releaseLevel,
      },
      "releaseInterest"
    );
    try {
      const estimateGas = await stakingContract.estimateGas.unstake(
        vesting,
        true,
        false,
        burnAmount,
        releaseLevel
      );
      unstakingTx = await stakingContract.unstake(
        vesting,
        true,
        false,
        burnAmount,
        releaseLevel,
        {
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        }
      );
      const pendingTxnType = "claim_interest";
      dispatch(
        fetchPendingTxns({
          txnHash: unstakingTx.hash,
          text: "claim",
          type: pendingTxnType,
        })
      );
      await unstakingTx.wait();
    } catch (e) {
      const rpcError = e as IJsonRPCError;
      if (
        rpcError.code === -32603 &&
        rpcError.message.indexOf("ds-math-sub-underflow") >= 0
      ) {
        dispatch(
          error(
            "You may be trying to stake more than your balance! Error code: 32603. Message: ds-math-sub-underflow"
          )
        );
      } else if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(
          error(
            (e as any).reason ||
              (e as any).message ||
              (e as any).data ||
              (e as any)
          )
        );
      }
      return;
    } finally {
      if (unstakingTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(unstakingTx.hash));
      }
    }
  }
);
