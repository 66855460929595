import { Box, SvgIcon } from "@mui/material";
import { useMobile } from "src/hooks";
function BondLogo({ bond }) {
  let viewBox = "0 0 32 32";
  let style = { height: "32px", width: "32px" };
  const { isSmallScreen } = useMobile();
  // Need more space if its an LP token
  if (bond.isLP) {
    viewBox = "0 0 64 32";
    style = { height: "32px", width: "62px" };
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={
        !bond.isLP ? (isSmallScreen ? "center" : "flex-end") : "center"
      }
      width={bond.isLP ? "64px" : "32px"}
    >
      <SvgIcon component={bond.bondIconSvg} viewBox={viewBox} style={style} />
    </Box>
  );
}

export default BondLogo;
