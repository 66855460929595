import { Paper, Box, Typography } from "@mui/material";
import "./customtooltip.scss";
import { customNumberFormat, trim } from "src/helpers";
import dayjs from "dayjs";
import { i18n } from "@lingui/core";
import "dayjs/locale/zh-hk";
import { Trans } from "@lingui/macro";

const renderDate = (index, payload, item) => {
  return index === payload.length - 1 ? (
    <Box
      className="tooltip-date"
      sx={{
        color: "#FFF",
        textAlign: "center",
        fontSize: "18.017px",
        fontStyle: "normal",
        fontWeight: "400",
      }}
    >
      {dayjs(item.payload.timestamp * 1000)
        .locale(i18n.locale === "en" ? "en" : "zh-hk")
        .format("MMMM D, YYYY")}
      {/* {new Date(item.payload.timestamp * 1000).toLocaleString("default", { month: "long" }).charAt(0).toUpperCase()}
      {new Date(item.payload.timestamp * 1000).toLocaleString("default", { month: "long" }).slice(1)}
      &nbsp;
      {new Date(item.payload.timestamp * 1000).getDate()}, {new Date(item.payload.timestamp * 1000).getFullYear()} */}
    </Box>
  ) : (
    ""
  );
};

const renderItem = (type, item) => {
  return type === "$" ? (
    <Typography
      sx={{
        color: "#FFF",
        textAlign: "center",
        fontSize: "18.017px",
        fontStyle: "normal",
        fontWeight: "400",
      }}
    >{`${type}${Math.round(item).toLocaleString("en-US")}`}</Typography>
  ) : (
    <Typography
      sx={{
        color: "#FFF",
        textAlign: "center",
        fontSize: "18.017px",
        fontStyle: "normal",
        fontWeight: "400",
      }}
    >{`${Math.round(item).toLocaleString("en-US")}${type}`}</Typography>
  );
};

const renderTooltipItems = (
  payload,
  bulletpointColors,
  itemNames,
  itemType,
  isStaked = false,
  isPOL = false,
  isMinted = false,
  isBurning = false
) => {
  return isStaked ? (
    <Box>
      <Box className="item" display="flex" justifyContent="space-between">
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
            mr: 1,
          }}
        >
          <span
            className="tooltip-bulletpoint"
            style={bulletpointColors[0]}
          ></span>
          <Trans>Staked</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >{`${trim(payload[0].value, 2)}%`}</Typography>
      </Box>
      <Box className="item" display="flex" justifyContent="space-between">
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
            mr: 1,
          }}
        >
          <span
            className="tooltip-bulletpoint"
            style={bulletpointColors[1]}
          ></span>
          <Trans>Not staked</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >{`${trim(100 - payload[0].value, 2)}%`}</Typography>
      </Box>
      <Box>{renderDate(0, payload, payload[0])}</Box>
    </Box>
  ) : isPOL ? (
    <Box>
      <Box className="item" display="flex" justifyContent="space-between">
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
            mr: 1,
          }}
        >
          <span
            className="tooltip-bulletpoint"
            style={bulletpointColors[0]}
          ></span>
          {itemNames[0]}
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
            mr: 1,
          }}
        >{`${trim(payload[0].value, 2)}%`}</Typography>
      </Box>
      {/* <Box className="item" display="flex" justifyContent="space-between">
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          <span className="tooltip-bulletpoint" style={bulletpointColors[1]}></span>
          {itemNames[1]}
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >{`${Math.round(100 - payload[0].value)}%`}</Typography>
      </Box> */}
      <Box>{renderDate(0, payload, payload[0])}</Box>
    </Box>
  ) : isMinted || isBurning ? (
    <Box>
      <Box className="item" display="flex" justifyContent="space-between">
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
            mr: 1,
          }}
        >
          <span
            className="tooltip-bulletpoint"
            style={bulletpointColors[0]}
          ></span>
          {itemNames}
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          {customNumberFormat(payload[0].value, 4)}
          {isBurning && "NVB"}
          {isMinted && "AVC"}
        </Typography>
      </Box>
      {/* <Box className="item" display="flex" justifyContent="space-between">
        <Typography
          sx={{
            color: "#FFF",
            textAlign: "center",
            fontSize: "18.017px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          <span className="tooltip-bulletpoint" style={bulletpointColors[1]}></span>
          {itemNames[1]}
        </Typography>
        <Typography>{`${Math.round(100 - payload[0].value)}%`}</Typography>
      </Box> */}
      <Box>{renderDate(0, payload, payload[0])}</Box>
    </Box>
  ) : (
    payload.map((item, index) => (
      <Box key={index}>
        <Box className="item" display="flex">
          <Box display="flex" justifyContent="space-between">
            <Typography
              sx={{
                color: "#FFF",
                textAlign: "center",
                fontSize: "18.017px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              <span
                className="tooltip-bulletpoint"
                style={bulletpointColors[index]}
              ></span>
              {`${itemNames[index]}`}
            </Typography>
          </Box>
          {renderItem(itemType, item.value)}
        </Box>
        <Box>{renderDate(index, payload, item)}</Box>
      </Box>
    ))
  );
};

function CustomTooltip({
  active,
  payload,
  bulletpointColors,
  itemNames,
  itemType,
  isStaked,
  isPOL,
  isMinted,
  isBurning,
}) {
  if (active && payload && payload.length) {
    return (
      <Paper
        className={`ohm-card tooltip-container`}
        sx={{
          background: "rgba(26, 28, 31, 0.90)",
          boxShadow:
            "0px 3.275862216949463px 13.103448867797852px rgba(26, 28, 31, 0.80))",
          px: 1.475,
          py: 1,
        }}
      >
        {renderTooltipItems(
          payload,
          bulletpointColors,
          itemNames,
          itemType,
          isStaked,
          isPOL,
          isMinted,
          isBurning
        )}
      </Paper>
    );
  }
  return null;
}

export default CustomTooltip;
