import {
  AppBar,
  Toolbar,
  Box,
  Button,
  SvgIcon,
  Link,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
// import { ReactComponent as MenuIcon } from "../../assets/icons/hamburger.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu.svg";
import LocaleSwitcher from "./LocaleSwitch.jsx";
import ConnectMenu from "./ConnectMenu.jsx";
import { t, Trans } from "@lingui/macro";
import { useIsPath } from "../../hooks";
import { addresses } from "src/constants";
import { useDispatch } from "react-redux";

import "./topbar.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import ComingIcon from "../../assets/images/coming.png";
import Certik from "../../assets/icons/certik-pc.svg";
import { info } from "src/slices/MessagesSlice";
const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      // padding: "10px",
      // paddingBottom: "60px",
    },
    justifyContent: "center",
    alignItems: "center",
    background: "#FCD000",
    backgroundColor: "#FCD000!important",
    backdropFilter: "none",
    zIndex: 10,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("981")]: {
      display: "none",
    },
  },
}));

function TopBar({ handleDrawerToggle }) {
  const dispatch = useDispatch();

  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles();
  const { chainID } = useWeb3Context();
  const isVerySmallScreen = useMediaQuery("(max-width: 355px)");
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const buttonStyles = isHovering ? " hovered-button" : "";
  const isDashboard = useIsPath("/dashboard");
  const isInvite = useIsPath("/invite");
  const isBond = useIsPath("/bonds");
  const isStake = useIsPath("/stake");
  const isContribution = useIsPath("/contribution");
  const isHome = useIsPath("/home");
  const isSwap = useIsPath("/swap");

  const handleGoOfficalPage = () => {
    // window.open("", "_blank");
  };

  const handleComing = () => {
    dispatch(info("Coming soon"));
  };

  return (
    <AppBar
      position={isSmallScreen ? "fixed" : "sticky"}
      className={`${classes.appBar}`}
      elevation={0}
    >
      <Toolbar disableGutters className={`dapp-topbar`}>
        <Box
          display="flex"
          sx={{
            // px: isSmallerScreen ? 1.25 : "0",
            maxWidth: "1340px",
            minWidth: "345px",
            width: { xs: "100%", sm: "97%" },
          }}
          className={`barContainer`}
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          {/* {!isVerySmallScreen && <OhmMenu />}
          <ConnectMenu theme={theme} />
          <ThemeSwitcher theme={theme} toggleTheme={toggleTheme} /> */}
          {isSmallerScreen ? (
            <>
              <Box onClick={handleDrawerToggle}>
                <MenuIcon />
              </Box>
              <Box display="flex">
                <LocaleSwitcher />
                <ConnectMenu showPopper={true} />
              </Box>
            </>
          ) : (
            <>
              <Link
                href="/"
                onClick={handleGoOfficalPage}
                sx={{
                  cursor: "pointer",
                  width: "291.6px",
                  height: "63px",
                }}
              >
                <Logo style={{ width: "100%", height: "100%" }} />
              </Link>
              <Box display="flex" alignItems={"center"}>
                <Link
                  sx={{
                    px: 3,
                    cursor: "pointer",
                    // mr: 1,
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="/#/home"
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: isHome ? "underline" : "none",
                      fontWeight: isHome ? "600" : "400",
                      fontSize: "18px",
                    }}
                  >
                    <Trans>Home</Trans>
                  </Typography>
                </Link>
                <Link
                  sx={{
                    px: 3,
                    cursor: "pointer",
                    position: "relative",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="/#/dashboard"
                  // component={"div"}
                  // onClick={handleComing}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: isDashboard ? "underline" : "none",
                      fontWeight: isDashboard ? "600" : "400",
                      fontSize: "18px",
                    }}
                  >
                    <Trans>Dashboard</Trans>
                  </Typography>
                  {/* <Box
                    sx={{
                      width: "58.5px",
                      height: "14px",
                      position: "absolute",
                      top: "-14px",
                      right: "-14px",
                      "&>img": {
                        width: "100%",
                        height: "100%",
                      },
                    }}
                  >
                    <img src={ComingIcon} />
                  </Box> */}
                </Link>
                <Link
                  sx={{
                    px: 3,
                    cursor: "pointer",
                    position: "relative",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="/#/stake"
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: isStake ? "underline" : "none",
                      fontWeight: isStake ? "600" : "400",
                      fontSize: "18px",
                    }}
                  >
                    <Trans>Stake</Trans>
                  </Typography>
                </Link>
                <Link
                  sx={{
                    px: 3,
                    cursor: "pointer",
                    position: "relative",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="/#/bonds"
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: isBond ? "underline" : "none",
                      fontWeight: isBond ? "600" : "400",
                      fontSize: "18px",
                    }}
                  >
                    <Trans>Bond</Trans>
                  </Typography>
                </Link>
                <Link
                  sx={{
                    px: 3,
                    cursor: "pointer",
                    position: "relative",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="/#/contribution"
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: isContribution ? "underline" : "none",
                      fontWeight: isContribution ? "600" : "400",
                      fontSize: "18px",
                    }}
                  >
                    <Trans>Contribution</Trans>
                  </Typography>
                </Link>
                <Link
                  sx={{
                    px: 3,
                    cursor: "pointer",
                    // mr: 1,
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="/#/invite"
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: isInvite ? "underline" : "none",
                      fontWeight: isInvite ? "600" : "400",
                      fontSize: "18px",
                    }}
                  >
                    <Trans>Invite</Trans>
                  </Typography>
                </Link>
                <Link
                  sx={{
                    px: 3,
                    cursor: "pointer",
                    // mr: 1,
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="/#/swap"
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: isSwap ? "underline" : "none",
                      fontWeight: isSwap ? "600" : "400",
                      fontSize: "18px",
                    }}
                  >
                    <Trans>Swap</Trans>
                  </Typography>
                </Link>
                <Link
                  sx={{
                    px: 3,
                    cursor: "pointer",
                    // mr: 1,
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  href="https://skynet.certik.com/projects/nova-bank#active-monitor"
                  target="_blank"
                  // component={"div"}
                  // onClick={handleComing}
                  // href="/#/invite"
                >
                  <img src={Certik} alt="certik" />
                </Link>

                <LocaleSwitcher />
                <ConnectMenu />
              </Box>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
