import Chart from "src/components/Chart/Chart.jsx";
import { useTheme } from "@material-ui/core/styles";
import { trim, formatCurrency, customNumberFormat } from "../../../../helpers";
import { useTreasuryMetrics } from "../../hooks/useTreasuryMetrics";
import { useTreasuryRebases } from "../../hooks/useTreasuryRebases";
import {
  bulletpoints,
  tooltipItems,
  tooltipInfoMessages,
  itemType,
} from "../../treasuryData";
import { t } from "@lingui/macro";

export const Graph = ({ children }) => <>{children}</>;

export const TotalValueDepositedGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });
  return (
    <Chart
      type="area"
      data={data}
      itemType={itemType.dollar}
      itemNames={[t`Total value of sOHM circulating supply`]}
      dataKey={["totalValueLocked"]}
      headerText={t`Total value of sOHM circulating supply`}
      stopColor={[["rgb(100, 240, 192)", "rgb(33, 56, 48)", "rgb(25, 34, 31)"]]}
      bulletpointColors={bulletpoints.tvl}
      infoTooltipMessage={t`Total value of sOHM circulating supply`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${data && formatCurrency(data[0].totalValueLocked)}`}
      stroke={"rgb(100,240,192)"}
    />
  );
};

export const MarketValueGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      type="area"
      data={data}
      dataKey={["treasuryMarketValue"]}
      stopColor={[["#53E1C9", "#4BBFD1", "#2E3138"]]}
      headerText={t`Market Value of Treasury Assets`}
      headerSubText={`${data && formatCurrency(data[0].treasuryMarketValue)}`}
      bulletpointColors={bulletpoints.coin}
      itemNames={tooltipItems.coin}
      itemType={itemType.dollar}
      infoTooltipMessage={tooltipInfoMessages.mvt}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
    />
  );
};

export const RiskFreeValueGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      type="area"
      data={data}
      format="currency"
      dataKey={["treasuryRiskFreeValue"]}
      stopColor={[["rgb(50, 87, 118)", "rgb(32, 45, 56)", "rgb(25, 30, 34)"]]}
      headerText={t`Risk Free Value of Treasury Assets`}
      headerSubText={`${data && formatCurrency(data[0].treasuryRiskFreeValue)}`}
      bulletpointColors={bulletpoints.rfv}
      itemNames={tooltipItems.rfv}
      itemType={itemType.dollar}
      infoTooltipMessage={tooltipInfoMessages.rfv}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      stroke={"rgb(93, 167, 236)"}
    />
  );
};

export const ProtocolOwnedLiquidityGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      isPOL
      type="area"
      data={data}
      dataFormat="percent"
      itemNames={[t`Market SLP`]}
      itemType={itemType.percentage}
      dataKey={["treasuryOhmUsdtPOL"]}
      bulletpointColors={bulletpoints.pol}
      infoTooltipMessage={tooltipInfoMessages.pol}
      headerText={t`Protocol Owned Liquidity OHM-DAI`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${data && trim(data[0].treasuryOhmUsdtPOL, 2)}% `}
      stopColor={[["rgb(51, 39, 76) ", "rgb(39, 32, 54)", "rgb(25, 30, 34)"]]}
      stroke={"rgb(133, 90, 235)"}
    />
  );
};

export const OHMStakedGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  const staked =
    data &&
    data
      .map((metric) => ({
        stakeRate: metric.stakeRate * 100,
        timestamp: metric.timestamp,
      }))
      .filter((metric) => metric.stakeRate < 100);

  return (
    <Chart
      isStaked
      type="area"
      data={staked}
      dataKey={["stakeRate"]}
      dataFormat="percent"
      headerText={t`OHM Staked`}
      stopColor={[["rgb(54, 54, 33)", "rgb(37, 37, 27) ", "rgb(25, 30, 34)"]]}
      bulletpointColors={bulletpoints.staked}
      infoTooltipMessage={tooltipInfoMessages.staked}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${staked && trim(staked[0].stakeRate, 2)}% `}
      stroke={"rgb(251, 208, 57)"}
    />
  );
};

export const APYOverTimeGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryRebases({ refetchOnMount: false });

  let apy =
    data &&
    data
      .map((entry) => ({
        timestamp: entry.timestamp,
        apy: Math.pow(parseFloat(entry.percentage) + 1, 365 * 3) * 100,
      }))
      .filter((pm) => pm.apy < 300000);

  return (
    <Chart
      type="line"
      scale="log"
      data={apy}
      dataKey={["apy"]}
      dataFormat="percent"
      headerText={t`APY over time`}
      itemNames={tooltipItems.apy}
      itemType={itemType.percentage}
      color={theme.palette.text.primary}
      bulletpointColors={bulletpoints.apy}
      stroke={[theme.palette.text.primary]}
      infoTooltipMessage={tooltipInfoMessages.apy}
      headerSubText={`${data && trim(apy[0].apy, 2)}%`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
    />
  );
};

export const RunwayAvailableGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  const runway = data && data.filter((metric) => metric.runway10k > 5);

  const [current, ...others] = bulletpoints.runway;
  const runwayBulletpoints = [{ ...current, background: "#7adf8d" }, ...others];
  const colors = runwayBulletpoints.map((b) => b.background);
  return (
    <Chart
      type="multi"
      data={runway}
      dataKey={["runwayCurrent"]}
      color={theme.palette.text.primary}
      stroke={["rgb(234, 236, 239)"]}
      headerText={t`Runway Available`}
      headerSubText={`${data && trim(data[0].runwayCurrent, 1)} Days`}
      dataFormat="days"
      bulletpointColors={runwayBulletpoints}
      itemNames={tooltipItems.runway}
      itemType={""}
      infoTooltipMessage={tooltipInfoMessages.runway}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
    />
  );
};

export const OHMBurningGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });
  // console.log("OHMBurningGraph", data);
  return (
    <Chart
      isBurning
      type="area"
      data={data}
      itemNames={t`NVB Burning`}
      itemType={itemType.dollar}
      dataKey={["totalBurn"]}
      bulletpointColors={bulletpoints.burning}
      infoTooltipMessage={""}
      headerText={t`Total NVB Burning`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${data && trim(data[0].totalBurn, 4)}`}
      stopColor={[["rgb(112, 84, 53)", "rgb(60, 48, 35)", "rgb(25, 30, 34)"]]}
      stroke={"rgb(238, 170, 99)"}
    />
  );
};

export const BTokenMintingGraph = () => {
  const theme = useTheme();
  const { data } = useTreasuryMetrics({ refetchOnMount: false });

  return (
    <Chart
      isMinted
      type="area"
      data={data}
      itemNames={t`AVC Burning`}
      itemType={itemType.dollar}
      dataKey={["bTokenTotalBurn"]}
      bulletpointColors={bulletpoints.minting}
      infoTooltipMessage={tooltipInfoMessages.pol}
      headerText={t`Total AVC Burning`}
      expandedGraphStrokeColor={theme.palette.graphStrokeColor}
      headerSubText={`${
        data && customNumberFormat(data[0].bTokenTotalBurn, 4)
      }`}
      stopColor={[["rgb(53, 113, 93)", "rgb(35, 61, 52)", "rgb(25, 30, 34)"]]}
      stroke={"rgb(100, 240, 192)"}
    />
  );
};
