import { Trans } from "@lingui/macro";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Partner1 from "../../assets/icons/partner1.svg";
import Partner2 from "../../assets/icons/partner2.svg";
import Partner3 from "../../assets/icons/partner3.svg";
import Partner4 from "../../assets/icons/partner4.svg";
import Partner5 from "../../assets/icons/partner5.svg";
import Partner6 from "../../assets/icons/partner6.svg";
import Partner7 from "../../assets/icons/partner7.svg";
import Partner8 from "../../assets/icons/partner8.svg";
import Partner9 from "../../assets/icons/partner9.svg";
import Partner10 from "../../assets/icons/partner10.svg";
import BorderBg from "../../assets/icons/border.svg";
import BorderLineBg from "../../assets/icons/border-line.svg";
import BorderBgMobile from "../../assets/icons/border-m.svg";
import BorderLineBgMobile from "../../assets/icons/border-line-m.svg";

import { useMobile } from "../../hooks";

export const PartnerCard = () => {
  const isSmallScreen = useMobile();

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={"column"}
      sx={{
        width: "100%",
        mb: {
          xs: "40px",
          md: "100px",
        },
        px: {
          xs: "15px",
          sm: "0",
        },
        position: "relative",
      }}
    >
      {/* title */}
      <Box
        sx={{
          mb: { md: "50px", xs: "20px" },
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontSize: { xs: "24px", sm: "39.435px" },
            fontWeight: "700",
            textTransform: "uppercase",
          }}
          align="center"
        >
          <Trans>Cooperative partner</Trans>
        </Typography>
      </Box>
      {/* content */}
      <Box
        sx={{
          maxWidth: "1340px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
          minHeight: "320px",
          border: "1px solid #fcd000",
          overflow: "hidden",
          borderRadius: { xs: "4px", sm: "10px" },
          position: "relative",
        }}
      >
        <Grid container>
          <Grid item xs={6} sm={6} md={2.4}>
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: "0 100%, 100% 0, 1px 100%, 100% 1px",
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "106px", sm: "180px" },
                  height: { xs: "21px", sm: "37px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner1} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.4}
            sx={{
              background: "#000",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: { xs: "0 100%, 100% 0, 0 100%, 100% 1px", sm: "0 100%, 100% 0, 1px 100%, 100% 1px" },
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "104px", sm: "173px" },
                  height: { xs: "20.9px", sm: "30px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner2} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.4}
            sx={{
              background: { xs: "#000", sm: "transparent" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: "0 100%, 100% 0, 1px 100%, 100% 1px",
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "113.5px", sm: "142px" },
                  height: { xs: "19.74px", sm: "36px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner3} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.4}
            sx={{
              background: { xs: "transparent", sm: "#000" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: { xs: "0 100%, 100% 0, 0 100%, 100% 1px", sm: "0 100%, 100% 0, 1px 100%, 100% 1px" },
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "127.86px", sm: "130px" },
                  height: { xs: "24.52px", sm: "43px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner4} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: "0 100%, 100% 0, 0 100%, 100% 1px",
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "80.94", sm: "129px" },
                  height: { xs: "25.25px", sm: "42px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner5} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.4}
            sx={{
              background: "#000",
              borderRadius: { xs: "none", sm: "0 0 0 10px" },
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: { xs: "1px 100%, 100% 0, 0 100%, 100% 1px", sm: "0 100%, 100% 0, 1px 100%, 100% 0" },
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "118.77px", sm: "166px" },
                  height: { xs: "25.94px", sm: "37px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner6} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.4}
            sx={{
              background: { xs: "#000", sm: "transparent" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: "0 100%, 100% 0, 1px 100%, 100% 0",
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "98.73px", sm: "184px" },
                  height: { xs: "27.657px", sm: "35px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner7} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.4}
            sx={{
              background: { xs: "transparent", sm: "#000" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                // backgroundSize: "0 100%, 100% 0, 1px 100%, 100% 0",
                backgroundSize: { xs: "0 100%, 100% 0, 0 100%, 100% 1px", sm: "0 100%, 100% 0, 1px 100%, 100% 0" },

                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "121px", sm: "204px" },
                  height: { xs: "21.911px", sm: "35px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner8} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: { xs: "0 100%, 100% 1px, 1px 100%, 100% 0", sm: "0 100%, 100% 0, 1px 100%, 100% 0" },
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "122px", sm: "190px" },
                  height: { xs: "21px", sm: "38px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner9} alt="" />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={2.4}
            sx={{
              background: "#000",
              borderRadius: { xs: " 0 0 4px 0", sm: "0 0 10px 0" },
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "114px", sm: "161px" },
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { xs: "116px", sm: "150px" },
                  height: { xs: "23.2px", sm: "42px" },
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Partner10} alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* bg */}
        {/* top left bg */}
        <Box
          sx={{
            width: { xs: "12px", sm: "26px" },
            height: { xs: "12px", sm: "26px" },
            position: "absolute",
            zIndex: 0,
            top: { xs: "-7px", sm: "-1px" },
            left: "-1px",
            borderRadius: { xs: "4px 0 0 0 ", sm: "10px 0 0 0" },
            // overflow: "hidden",
            // rotate: "90deg",
            "&>img": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <img src={isSmallScreen ? BorderBgMobile : BorderBg} />
        </Box>
        {/* bottom right  bg */}
        <Box
          sx={{
            width: { xs: "12px", sm: "26px" },
            height: { xs: "12px", sm: "26px" },
            position: "absolute",
            zIndex: 0,
            right: { xs: "-6px", sm: "-1px" },
            bottom: "-1px",
            rotate: "90deg",
            "&>img": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <img src={isSmallScreen ? BorderLineBgMobile : BorderLineBg} />
        </Box>
        {/* mobile top right bg*/}
        <Box
          sx={{
            display: { xs: "initial", sm: "none" },
            width: "12px",
            height: "12px",
            position: "absolute",
            zIndex: 0,
            right: "-1px",
            top: "-7px",
            // rotate: "90deg",
            "&>img": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <img src={BorderLineBgMobile} />
        </Box>
        {/* mobile bottom left bg */}
        <Box
          sx={{
            display: { xs: "initial", sm: "none" },

            width: "12px",
            height: "12px",
            position: "absolute",
            zIndex: 0,
            bottom: "-1px",
            left: "5px",
            // overflow: "hidden",
            rotate: "90deg",
            "&>img": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <img src={BorderBgMobile} />
        </Box>
      </Box>
    </Box>
  );
};
