import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { useState, MouseEvent } from "react";
import { Popper, Button, Paper, Typography, Box, Fade } from "@mui/material";

import FlagIcon from "../../helpers/flagicon.js";
import { locales, selectLocale } from "../../locales";

import "./localesmenu.scss";
import { useMobile } from "src/hooks";

function getLocaleFlag(locale) {
  return locales[locale].flag;
}

function LocaleSwitcher() {
  const { isSmallScreen } = useMobile();
  const id = "locales-popper";
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Box
      component="div"
      onMouseEnter={isSmallScreen ? null : handleClick}
      onMouseLeave={isSmallScreen ? null : handleClick}
      onClick={isSmallScreen ? handleClick : null}
      id="locales-menu-button-hover"
      sx={{
        borderRadius: "50%",
        boxShadow: "-3px 3px 0px #ECAA00",
        width: "42px",
        height: "42px",
        mx: 1.625,
        border: " 1px solid #000",
        // "&:hover": {
        //   boxShadow: "2px 4px 9px 0px #070707, -3px -2px 16px 0px #455059",
        // },
      }}
      display="flex"
      alignItems="center"
      justifyContent={"center"}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        sx={{
          width: "42px",
          height: "42px",
          borderRadius: "50%",

          "&:hover": {},
        }}
      >
        <Button
          className="toggle-button"
          // size="large"
          // variant="contained"
          // color="secondary"
          title={t`Change locale`}
          aria-describedby={id}
          sx={{ minWidth: "auto" }}
        >
          <FlagIcon code={getLocaleFlag(i18n.locale)} />
        </Button>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          transition
          sx={{ zIndex: "1112" }}
        >
          {({ TransitionProps }) => {
            return (
              <Fade {...TransitionProps} timeout={100}>
                <Paper className="locales-menu" elevation={1}>
                  <Box
                    component="div"
                    sx={{
                      py: "10px",
                    }}
                  >
                    {Object.keys(locales).map((locale, key) => (
                      <Box
                        key={key}
                        onClick={() => selectLocale(locale)}
                        sx={{ cursor: "pointer", p: 0.675 }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography align="center">
                          <FlagIcon code={getLocaleFlag(locale)} />
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </Fade>
            );
          }}
        </Popper>
      </Box>
    </Box>
  );
}

export default LocaleSwitcher;
