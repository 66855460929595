import Social from "./Social";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  SvgIcon,
  Link,
  Typography,
  Paper,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./footer.scss";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo-footer.svg";
import { Trans } from "@lingui/macro";
import { useIsPath } from "../../hooks";
import { useMobile } from "../../hooks";
import FooterBg from "../../assets/images/footer-bg.png";
import { useDispatch } from "react-redux";
import { info } from "src/slices/MessagesSlice";

function Footer({ handleScrollTop }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isSmallScreen } = useMobile();
  const handleComing = () => {
    dispatch(info("Coming soon"));
  };

  const handleGoOfficalPage = () => {
    // window.open("", "_blank");
    location.reload();
  };
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        // background: "#151515",
        pb: { xs: "80px", sm: "100px" },
        px: {
          xs: "24px",
          sm: "0",
        },
      }}
    >
      {/* content */}
      <Box
        display="flex"
        // alignItems={"center"}
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "flex-start", sm: "space-between" },
          maxWidth: "1340px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
          position: "relative",
          zIndex: "1",
        }}
      >
        <Box
          sx={{
            mb: { xs: "50px", sm: "0" },
          }}
        >
          <Link
            href="/"
            // onClick={handleGoOfficalPage}
            sx={{
              mb: "24px",
              height: "47px",
              display: "block",
            }}
          >
            <LogoIcon />
          </Link>
          <Box
            sx={{
              mb: "20px",
            }}
          >
            <Social />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mb: "20px",
            }}
          >
            <Box
              component={"span"}
              // sx={{
              //   mb: 0.5,
              // }}
            >
              <Typography
                sx={{
                  color: "#FBD000",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Email:
              </Typography>
            </Box>
            <Box component={"span"}>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                support@novabank.finance
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <Trans>© 2024 NovaBank. All Rights Reserved.</Trans>
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box>
            <Box
              sx={{
                mb: "10px",
                mr: { xs: "100px", sm: "150px" },
              }}
            >
              <Typography
                sx={{
                  color: "#fcd000",
                  fontSize: { xs: "20px", sm: "24px" },
                  fontWeight: "500",
                }}
              >
                <Trans>Navigation</Trans>
              </Typography>
            </Box>
            <Link onClick={handleScrollTop} href="/home">
              <Box
                sx={{
                  py: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    cursor: "pointer",
                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Home</Trans>
                </Typography>
              </Box>
            </Link>
            <Link
              href="/dashboard"
              // onClick={handleComing}
              // component={"div"}
            >
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",

                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    // cursor: "pointer",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Dashboard</Trans>
                </Typography>
              </Box>
            </Link>
            <Link
              href="/stake"
              // onClick={handleComing}
              // component={"div"}
            >
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",

                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    // cursor: "pointer",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Stake</Trans>
                </Typography>
              </Box>
            </Link>
            <Link
              href="/bonds"
              // onClick={handleComing}
              // component={"div"}
            >
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",

                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    // cursor: "pointer",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Bond</Trans>
                </Typography>
              </Box>
            </Link>
            <Link
              href="/contribution"
              // onClick={handleComing}
              // component={"div"}
            >
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",

                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    // cursor: "pointer",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Contribution</Trans>
                </Typography>
              </Box>
            </Link>
            <Link href="/invite">
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",

                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    // cursor: "pointer",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Invite</Trans>
                </Typography>
              </Box>
            </Link>
            <Link href="https://docs.novabank.finance" target="_blank">
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Docs</Trans>
                </Typography>
              </Box>
            </Link>
          </Box>
          <Box>
            <Box
              sx={{
                mb: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "#fcd000",
                  fontSize: { xs: "20px", sm: "24px" },
                  fontWeight: "500",
                }}
              >
                <Trans>Community</Trans>
              </Typography>
            </Box>

            <Link href="https://x.com/NovaBank0" target="_blank">
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",

                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Twitter</Trans>
                </Typography>
              </Box>
            </Link>
            <Link href="https://t.me/NovaBankChat" target="_blank">
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    // cursor: "pointer",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Telegram</Trans>
                </Typography>
              </Box>
            </Link>
            <Link href="https://t.me/NovaBankAnnouncement" target="_blank">
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    // cursor: "pointer",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Telegram Channel</Trans>
                </Typography>
              </Box>
            </Link>
            <Link href="https://medium.com/@novabank" target="_blank">
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Medium</Trans>
                </Typography>
              </Box>
            </Link>
            <Link
              href="https://www.youtube.com/@NovaBankOfficial"
              target="_blank"
            >
              <Box sx={{ py: 1.25 }}>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: "400",
                    "&:hover": {
                      color: "#fcd000",
                    },
                  }}
                >
                  <Trans>Youtube</Trans>
                </Typography>
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
      {/* bg */}
      <Box
        sx={{
          position: "absolute",
          left: "-20px",
          top: { xs: "-50px", sm: "-173px" },
          width: { xs: "186px", sm: "390px" },
          height: { xs: "309px", sm: "509px" },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={FooterBg} />
      </Box>
    </Box>
  );
}

export default Footer;
