import React, { useEffect } from "react";
import { Box, Button, Grid, Link, Skeleton, Typography } from "@mui/material";
import { useAppSelector, useMobile } from "../../hooks";
import { Trans } from "@lingui/macro";
import "./banner.scss";
import ConnectMenu from "src/components/TopBar/ConnectMenu";
import BannerLeftBg from "../../assets/images/banner-content-bg.png";
import BorderLineBg from "../../assets/icons/border-line.svg";
import BorderBg from "../../assets/icons/border.svg";
import BorderBgMobile from "../../assets/icons/border-m.svg";
import TenBg from "../../assets/icons/ten.svg";
import LeftTenBg from "../../assets/icons/left-ten.svg";
import NovaBottom from "../../assets/images/nova-bottom.png";
import NovaTop from "../../assets/images/nova-top.png";
import BannerBottomRight from "../../assets/images/banner-bottom-right.png";
import ReactPlayer from "react-player";
import BannerVideo from "../../assets/images/banner-bg.mp4";
// import NovaBankVideo from "../../assets/images/novabank.mp4";
import NovaBankVideo from "../../assets/images/nova2.mp4";
import BannerBg from "../../assets/images/banner-bg.png";
import { info } from "src/slices/MessagesSlice";
import { useDispatch } from "react-redux";
import { formatCurrency } from "src/helpers";

const Banner = () => {
  const dispatch = useDispatch();

  const { isSmallScreen } = useMobile();
  const [isPlaying, setIsPlaying] = React.useState(false);
  const tokenBalInStaking = useAppSelector(
    (state) => state.app.tokenBalInStaking
  );
  const marketPrice = useAppSelector((state) => state.app.marketPrice);
  const totalBurn = useAppSelector((state) => state.app.totalBurn);
  const marketCap = useAppSelector((state) => state.app.marketCap);
  const treasuryMarketValue = useAppSelector(
    (state) => state.app.treasuryMarketValue
  );
  const totalStakedValue = Number(tokenBalInStaking) * Number(marketPrice);
  const destoryValue =
    totalBurn && marketPrice ? Number(totalBurn) * Number(marketPrice) : 0;
  console.log("destoryValue", destoryValue);
  useEffect(() => {
    setIsPlaying(true);
  }, []);

  const handleComing = () => {
    dispatch(info("Coming soon"));
  };

  // const goStakePage = ()

  return (
    <Box
      sx={{
        // px: { xs: "15px", sm: "0" },
        pt: isSmallScreen ? "0" : "50px",
        width: "100%",
        position: "relative",
        mb: { md: "100px", xs: "40px" },
        px: {
          xs: "15px",
          sm: "0",
        },
        // background: "skyblue"
      }}
      display="flex"
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
    >
      {/* banner top */}
      <Box
        sx={{
          maxWidth: "1340px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
          minHeight: "440px",
          borderRadius: "10px 10px 0 0",
          border: "1px solid rgb(251, 208, 57)",
          position: "relative",
          padding: isSmallScreen ? "15px" : "40px",
          overflow: "hidden",
          zIndex: "1",
        }}
        display="flex"
        flexDirection={"column"}
        justifyContent={"space-between"}
        // className='bannerCard'
      >
        {/* <Box className="bannerCardBg" /> */}

        <Box
          sx={{
            position: "relative",
            zIndex: 1,
          }}
        >
          <Box
            display="flex"
            sx={{
              mb: isSmallScreen ? "10px" : "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: isSmallScreen ? "30px" : "39.435px",
                fontStyle: "normal",
                fontWeight: "700",
                color: "#fff",
                // whiteSpace: "wrap",
                wordBreak: "break-word",
                "&>span": {
                  color: "#FCD000",
                  display: isSmallScreen ? "block" : "initial",
                  mr: 0.5,
                },
              }}
            >
              <span>
                <Trans>NovaBank</Trans>
              </span>
              <Trans>Decentralized Banking Protocol</Trans>
            </Typography>
            {/* <Typography
              sx={{
                color: "#fff",
                fontSize: "39.435px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              <Trans>Decentralized Banking Protocol</Trans>
            </Typography> */}
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#848E9C",
                fontSize: isSmallScreen ? "14px" : "18px",
                fontWeight: "500",
              }}
            >
              <Trans>
                Decentralized Reserve Currency Protocol Based on Algorithmic
                Non-Stable Coin NVB
              </Trans>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
          }}
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid container>
            <Grid
              item
              md={9}
              xs={12}
              sx={{
                mb: {
                  xs: "40px",
                  md: "0",
                },
              }}
            >
              <Grid
                container
                rowSpacing={isSmallScreen ? 2 : 0}
                sx={{
                  pt: isSmallScreen ? "40px" : "80px",
                }}
              >
                <Grid item md={4} xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "12px",
                          md: "14px",
                        },
                        fontWeight: "400",
                        textTransform: "uppercase",
                      }}
                    >
                      <Trans>Total staked value</Trans>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "24px",
                          md: "30px",
                        },
                        fontWeight: "600",
                      }}
                    >
                      {totalStakedValue ? (
                        formatCurrency(totalStakedValue, 2)
                      ) : (
                        <Skeleton width="150px" />
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "12px",
                          md: "14px",
                        },
                        fontWeight: "400",
                        textTransform: "uppercase",
                        // mb: 1.25,
                      }}
                    >
                      <Trans>Market value</Trans>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "24px",
                          md: "30px",
                        },
                        fontWeight: "600",
                      }}
                    >
                      {marketCap ? (
                        formatCurrency(marketCap, 2)
                      ) : (
                        <Skeleton width="150px" />
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "12px",
                          md: "14px",
                        },
                        fontWeight: "400",
                        textTransform: "uppercase",
                        // mb: 1.25,
                      }}
                    >
                      <Trans>Treasury Value</Trans>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "24px",
                          md: "30px",
                        },
                        fontWeight: "600",
                      }}
                    >
                      {typeof treasuryMarketValue == "number" ? (
                        `${formatCurrency(treasuryMarketValue, 2)}`
                      ) : (
                        <Skeleton width="150px" />
                      )}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item md={3} xs={6}>
                  <Box>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "12px",
                          md: "14px",
                        },
                        fontWeight: "400",
                        textTransform: "uppercase",
                        // mb: 1.25,
                      }}
                    >
                      <Trans>Destroy value NVB</Trans>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "20px",
                          md: "30px",
                        },
                        fontWeight: "600",
                      }}
                    >
                      {typeof treasuryMarketValue == "number" ? (
                        `${formatCurrency(destoryValue, 2)}`
                      ) : (
                        <Skeleton width="150px" />
                      )}
                    </Typography>
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item md={3} xs={12}>
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: "500",
                    fontSize: {
                      xs: "12px",
                      md: "14px",
                    },
                    mb: "20px",
                  }}
                >
                  <Trans>
                    Relying on the innovative DeFi protocol, NovaBank combines
                    the RWA framework, modular blockchain, decentralized lending
                    mechanism and meta-universe ecosystem to create a fully
                    integrated financial solution.
                  </Trans>
                </Typography>
                {/* <ConnectMenu showPopper={false} /> */}
                <Link
                  // component={"div"}
                  href="/stake"
                  sx={{
                    width: "160px",
                    borderRadius: "6px",
                    height: "44px",
                    display: "block",
                    border: "1px solid #000",
                    background: "#FCD000",
                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                    "&:hover": {
                      transform: "scale(1.02)",
                    },
                  }}
                  // onClick={handleComing}
                >
                  <Button
                    sx={{
                      width: "100%",
                      height: "100%",
                      background: "transparent",
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    // onClick={goStakePage}
                    variant="contained"
                    // onMouseOver={() => setIsHovering(true)}
                    // onMouseLeave={() => setIsHovering(false)}
                  >
                    <Typography
                      sx={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                    >
                      <Trans>Stake</Trans>
                    </Typography>
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* mp4 bg */}
        <Box
          sx={{
            position: "absolute",
            // maxWidth: "1440px",
            maxWidth: "100%",
            maxHeight: isSmallScreen ? "100%" : "800px",
            left: "50%",
            top: isSmallScreen ? "0" : "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            transform: "translateX(-50%)",
            zIndex: 0,
            "& .react-player": {
              width: "100% !important",
              height: "100% !important",
              "& video": {
                width: "100% !important",
                height: "100% !important",
                objectFit: "cover",
              },
            },
          }}
        >
          {/* {isSmallScreen ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                background: `url(${BannerBg}) no-repeat center center`,
                backgroundSize: "cover",
              }}
            />
          ) : ( */}
          <ReactPlayer
            url={BannerVideo}
            playing={isPlaying}
            onReady={() => {
              setIsPlaying(true);
            }}
            loop
            muted
            playsinline
            className="react-player"
            style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
          />
          {/* )} */}
        </Box>
      </Box>
      {/* banner bottom */}
      <Box
        display="flex"
        sx={{
          maxWidth: "1340px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
          minHeight: "440px",
          flexDirection: {
            xs: "column",
            md: "row",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Box
            sx={{
              border: "1px solid #fcd000",
              width: { sx: "100%", md: "384px" },
              height: "440px",
              position: "relative",
              borderRadius: { xs: "none", md: "0 0 0 11px" },
              borderRight: { md: "none" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "292px",
                position: "absolute",
                left: "0",
                top: "0",
                zIndex: "0",
                "&>img": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={NovaTop} />
            </Box>
            <Box
              sx={{
                width: "334px",
                height: "261px",
                position: "absolute",
                left: "50%",
                bottom: "60px",
                transform: "translate(-50%)",
                zIndex: "1",
                "&>img": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={NovaBottom} />
            </Box>
            {/* bottom left icon */}
            <Box
              sx={{
                width: "26px",
                height: "26px",
                position: "absolute",
                zIndex: 0,
                left: "0",
                bottom: "0",
                display: { xs: "none", md: "initial" },
                // rotate: "90deg",
                "&>img": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={BorderLineBg} />
            </Box>
            {/* top right icon */}
            <Box
              sx={{
                width: isSmallScreen ? "22px" : "40px",
                height: "40px",
                position: "absolute",
                zIndex: 1,
                right: isSmallScreen ? "-5px" : "-21px",
                top: "-21px",
                // rotate: "90deg",
                "&>img": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={isSmallScreen ? LeftTenBg : TenBg} />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              // width: "100%",
              flex: 1,
              height: { xs: "239px", sm: "440px" },
              position: "relative",
              overflow: "hidden",
              backgroundImage:
                "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
              backgroundSize: "1px 100%, 0, 1px 100%, 100% 1px",
              backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
              backgroundRepeat: "no-repeat",
              p: "1px",
              borderRadius: { sm: "0 0 10px 0", xs: "0 0 10px 10px" },
            }}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
          >
            {/* <Box
              sx={{
                position: "absolute",
                zIndex: "1",
                display: {
                  sm: "initial",
                  md: "none",
                },
              }}
            >
              <Typography
                sx={{
                  color: "#fcd000",
                  fontSize: "62px",
                  fontWeight: "400",
                }}
                align="center"
              >
                <Trans>NovaBank</Trans>
              </Typography>
            </Box> */}
            {/* content bg */}
            {/* mp4 bg */}
            <Box
              sx={{
                position: "absolute",
                // maxWidth: "1440px",
                maxWidth: "100%",
                maxHeight: isSmallScreen ? "500px" : "800px",
                left: "50%",
                top: isSmallScreen ? "0" : "0",
                width: "calc(100% - 2px)",
                height: "calc(100% - 1px)",
                backgroundColor: "rgba(0,0,0,0.5)",
                transform: "translateX(-50%)",
                zIndex: 0,
                "& .react-player": {
                  width: "100% !important",
                  height: "100% !important",
                  "& video": {
                    width: "100% !important",
                    height: "100% !important",
                    objectFit: "cover",
                  },
                },
              }}
            >
              {/* {isSmallScreen ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: `url(${BannerBottomRight}) no-repeat center center`,
                    backgroundSize: "cover",
                  }}
                />
              ) : ( */}
              <ReactPlayer
                url={NovaBankVideo}
                playing={isPlaying}
                onReady={() => {
                  setIsPlaying(true);
                }}
                loop
                muted
                playsinline
                autoPlay
                className="react-player"
                style={{ position: "absolute", top: 0, left: 0, zIndex: -1 }}
              />
              {/* )} */}
            </Box>

            {/* bottom right icon */}
            {/* mobile bottom left icon */}
            <Box
              sx={{
                width: { xs: "12px", sm: "26px" },
                height: { xs: "12px", sm: "26px" },
                position: "absolute",
                zIndex: 0,
                right: { md: "-2px", xs: "initial" },
                left: { md: "initial", xs: "0" },
                bottom: { xs: "6px", sm: "-2px", md: "-2px" },
                display: { xs: "none", sm: "initial" },
                // rotate: { xs: "90deg", md: "0deg" },
                "&>img": {
                  width: "100%",
                  height: "100%",
                },
              }}
            >
              <img src={isSmallScreen ? BorderBgMobile : BorderBg} />
            </Box>
          </Box>
          {/* top right bg */}
          {/* mobile top left bg */}
          <Box
            sx={{
              width: "22px",
              height: "40px",
              position: "absolute",
              zIndex: 1,
              right: { xs: "initial", md: "-4px" },
              left: { xs: "-4px", md: "initial" },
              top: "-21px",
              rotate: { xs: "180deg", md: "0deg" },
              "&>img": {
                width: "100%",
                height: "100%",
              },
            }}
          >
            <img src={LeftTenBg} />
          </Box>
        </Box>
      </Box>
      {/* left bg */}
      <Box
        sx={{
          position: "absolute",
          left: "-80px",
          top: "-40px",
          width: "390px",
          height: "509px",
          zIndex: "0",
          rotate: "180deg",
          "&>img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={BannerLeftBg} alt="bg" />
      </Box>
    </Box>
  );
};

export default Banner;
