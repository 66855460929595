import { styled } from "@material-ui/core";
import { Pagination } from "@mui/material";

export const CustormPagination = styled(Pagination)(() => ({
  "& .MuiPaginationItem-root": {
    borderRadius: "8px",
    color: "#000",
    height: "32px",
    border: "1px solid #F1F1F1",
    background: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&.Mui-selected": {
      border: "none",
      background: "#FCD000",
      "&:hover": {
        backgroundColor: "#FCD000",
      },
    },
  },
}));
