import { useCallback, useState, useRef, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Typography,
  Zoom,
  Divider,
  ButtonBase,
  SvgIcon,
} from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { LoadingButton } from "@mui/lab";
import { t, Trans } from "@lingui/macro";
import SmoothScroll from "smooth-scroll";
import { ethers } from "ethers";
import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
import { trim, toolNumber, customNumberFormat } from "../../helpers";
import { getReleaseRecordsByPage } from "src/slices/ReleaseSlice";
import {
  changeApproval,
  changeStake,
  cliamWarmupBalance,
  changeApprovalInterest,
  releaseInterest,
} from "../../slices/StakeThunk";
import {
  getBalances,
  getBurnAmount,
  loadAccountDetails,
} from "src/slices/AccountSlice";
import "./stake.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@mui/material";
import { error } from "../../slices/MessagesSlice";
import { useAppSelector, useMobile } from "src/hooks";
import useBonds, { IAllBondData } from "../../hooks/Bonds";
import { addresses, INVITE_PER_PAGE } from "src/constants";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";
import StakeIcon from "src/assets/icons/stake.svg";
import { ReactComponent as CalcIcon } from "src/assets/icons/calc.svg";
import Record from "../../components/Record/Record";
import FooterBg from "../../assets/images/footer-bg.png";
import CalculatorModal from "./Calculator";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
let debounceTimeout: NodeJS.Timeout;

const releaseLevelObj: any = {
  "30": 5,
  "60": 4,
  "100": 3,
  "150": 2,
  "180": 1,
};

function Stake() {
  const { isSmallScreen } = useMobile();
  const dispatch = useDispatch();
  // const { address }: { address: any } = useAccount();
  // const chainID = useChainId();
  // const { connect } = useConnect();
  const { provider, address, connected, connect, chainID } = useWeb3Context();
  const scrollTarget = useRef<HTMLDivElement>(null);
  const scroll = useMemo(() => new SmoothScroll(), []);

  const { bonds } = useBonds(chainID);
  const lpBond: any = bonds.filter((_) => _.name === "nvb-usdt_lp")[0];
  const [view, setView] = useState(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<any>();
  const [interestValue, setInterestValue] = useState<any>();
  const [days, setDays] = useState<string>("30");
  const isAppLoading = useAppSelector((state) => state.app.loading);
  const currentIndex = useAppSelector((state) => {
    return state.app.currentIndex;
  });
  const tokenPrice = useAppSelector((state) => {
    return state.app.marketPrice;
  });
  const fiveDayRate = useAppSelector((state) => {
    return state.app.fiveDayRate;
  });
  const releaseRecords = useAppSelector((state) => {
    return state.release.releaseRecords;
  });
  const releasePage = useAppSelector((state) => {
    return state.release.releasePage;
  });
  const releaseTotal = useAppSelector((state) => {
    return state.release.releaseTotal;
  });
  const ohmBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.ohm;
  });
  const principalAmt = useAppSelector((state) => {
    return state.account.balances && state.account.balances.principalAmt;
  });
  const avaiablePrincipalAmt = useAppSelector((state) => {
    return (
      state.account.balances && state.account.balances.avaiablePrincipalAmt
    );
  });
  const gonsBal = useAppSelector((state) => {
    return state.account.balances && state.account.balances.gonsBal;
  });
  const interestBal = useAppSelector((state) => {
    return state.account.balances && state.account.balances.interest;
  });
  const avaiableInterest = useAppSelector((state) => {
    return state.account.balances && state.account.balances.avaiableInterest;
  });
  const isLocked = useAppSelector((state) => {
    return state.account && state.account.isLocked;
  });

  const sohmBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.sOHM;
  });

  const bTokenBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.bTokenBalance;
  });
  const wbnbBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.wbnb;
  });
  const stakeAllowance = useAppSelector((state) => {
    return state.account.staking && state.account.staking.ohmStake;
  });

  const unstakeAllowance = useAppSelector((state) => {
    return state.account.staking && state.account.staking.ohmUnstake;
  });
  const releasePoolAllowance = useAppSelector((state) => {
    return state.account.releasePoolAllowance;
  });
  const stakingRebase = useAppSelector((state) => {
    return state.app.stakingRebase || 0;
  });
  const tokenBalInStaking = useAppSelector((state) => {
    return state.app.tokenBalInStaking || 0;
  });
  const stakingAPY = useAppSelector((state) => {
    return state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0;
  });

  const burnAmt30 = useAppSelector((state) => {
    return state.account.burn && state.account.burn.amt30;
  });
  const burnAmt60 = useAppSelector((state) => {
    return state.account.burn && state.account.burn.amt60;
  });
  const burnAmt100 = useAppSelector((state) => {
    return state.account.burn && state.account.burn.amt100;
  });
  const burnAmt150 = useAppSelector((state) => {
    return state.account.burn && state.account.burn.amt150;
  });
  const burnAmt180 = useAppSelector((state) => {
    return state.account.burn && state.account.burn.amt180;
  });

  const stakingTVL = (tokenPrice as number) * tokenBalInStaking;

  const burnObj: any = {
    "30": burnAmt30 ?? 0,
    "60": burnAmt60 ?? 0,
    "100": burnAmt100 ?? 0,
    "150": burnAmt150 ?? 0,
    "180": burnAmt180 ?? 0,
  };
  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const setMax = () => {
    if (view === 0) {
      setQuantity(Math.trunc(Number(ohmBalance) * 10000) / 10000);
    } else {
      setQuantity(Number(avaiablePrincipalAmt));
    }
  };
  // console.log("[releaseLength releaseRecords]", { releaseRecords });

  const setInterestMax = () => {
    // console.log("interest max");
    setInterestValue(avaiableInterest);
    dispatch(
      getBurnAmount({
        provider,
        networkID: chainID,
        amount: avaiableInterest,
      })
    );
  };

  useEffect(() => {
    if (!interestValue) {
      dispatch(
        getBurnAmount({
          provider,
          networkID: chainID,
          amount: "0",
        })
      );
    }
  }, [interestValue]);

  const handleChangeInterest = useCallback(
    (event: any) => {
      let value = event.target.value.replace(/[^\d.]/g, "");
      if (value < 0) {
        setInterestValue("");
        return;
      }
      setInterestValue(value);

      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      debounceTimeout = setTimeout(() => {
        // console.log("burn", value);
        dispatch(
          getBurnAmount({
            provider,
            networkID: chainID,
            amount: value,
          })
        );
      }, 500);
    },
    [provider, chainID, dispatch]
  );

  useEffect(() => {
    dispatch(
      getReleaseRecordsByPage({
        address,
        provider,
        networkID: chainID,
        page: 1,
        limit: INVITE_PER_PAGE,
      })
    );
  }, [address]);

  const onAmountChange = (event: any) => {
    let value = event.target.value.replace(/[^\d.]/g, "");
    if (value < 0) {
      setQuantity("");
      return;
    }
    setQuantity(Number(value));
  };

  const onSeekApproval = async (token: string) => {
    await dispatch(
      changeApproval({ address, token, provider, networkID: chainID })
    );
    // console.log("loadAccountDetails approve staking", {
    //   stakeAllowance,
    //   unstakeAllowance,
    // });
    await dispatch(
      loadAccountDetails({
        address,
        provider,
        networkID: chainID,
      })
    );
    // console.log("loadAccountDetails approve staking", {
    //   stakeAllowance,
    //   unstakeAllowance,
    // });
  };

  const onChangeStake = async (action: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || quantity == 0) {
      // eslint-disable-next-line no-alert
      return dispatch(error(t`Please enter a value!`));
    } else if (quantity < 0) {
      return dispatch(error(t`Please enter a valid value!`));
    }
    // console.log("qua", quantity);
    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(quantity.toString(), "gwei");
    if (
      action === "stake" &&
      gweiValue.gt(ethers.utils.parseUnits(ohmBalance, "gwei"))
    ) {
      return dispatch(error(t`You cannot stake more than your OHM balance.`));
    }

    if (
      action === "unstake" &&
      gweiValue.gt(ethers.utils.parseUnits(avaiablePrincipalAmt, "gwei"))
    ) {
      return dispatch(error(t`Insufficient available principal!`));
    }

    await dispatch(
      changeStake({
        address,
        action,
        value: quantity.toString(),
        provider,
        networkID: chainID,
      })
    );
    setQuantity(0);
  };

  const hasAllowance = useCallback(
    (token) => {
      if (token === "OHM") return stakeAllowance ? stakeAllowance > 0 : 0;
      if (token === "sOHM") return unstakeAllowance ? unstakeAllowance > 0 : 0;
      return 0;
    },
    [stakeAllowance, unstakeAllowance]
  );

  const isAllowanceDataLoading =
    (stakeAllowance == null && view === 0) ||
    (unstakeAllowance == null && view === 1);

  const handleSelect = (_days: string) => {
    setDays(_days);
  };

  const changeView = (_event: React.ChangeEvent<{}>, newView: number) => {
    setView(newView);
    (quantity || quantity == 0) && setQuantity("");
  };

  const trimmedBalance = Number(
    [sohmBalance]
      .filter(Boolean)
      .map((balance) => Number(balance))
      .reduce((a, b) => a + b, 0)
      .toFixed(4)
  );
  const trimmedStakingAPY = stakingAPY * 100;
  // console.log("stakingRebase", stakingRebase);
  // const trimmedStakingAPY = stakingAPY * 100;
  // const trimmedStakingAPY = trim(stakingAPY * 100, 2);
  const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  // console.log(
  //   "stakingRebasePercentage",
  //   stakingRebasePercentage,
  //   trimmedStakingAPY
  // );
  const nextRewardValue = trim(
    (Number(stakingRebasePercentage) / 100) *
      (trimmedBalance + Number(gonsBal)),
    4
  );
  const formatGonsBal = trim(Number(gonsBal), 4);
  const claimWarmupBal = () => {
    dispatch(cliamWarmupBalance({ address, provider, networkID: chainID }));
  };

  const handleStakeInterest = async () => {
    // console.log("handleStakeInterest", { interestValue, interestBal });
    if (
      Number(interestValue) > Number(interestBal) ||
      Number(interestValue) > Number(avaiableInterest)
    ) {
      return dispatch(error(t`Insufficient available interest to claim!`));
    } else if (Number(burnObj[days]) > Number(wbnbBalance)) {
      return dispatch(error(t`WBNB balance is insufficient to destroy!`));
    }
    // } else if (Number(burnObj[days]) > Number(bTokenBalance)) {
    //   return dispatch(error(t`AVC balance is insufficient to destroy!`));
    // }
    await dispatch(
      releaseInterest({
        address,
        provider,
        networkID: chainID,
        vestingAmt: interestValue,
        burnAmt: burnObj[days],
        releaseLevel: releaseLevelObj[days],
      })
    );

    setInterestValue("");
    setDays("30");
    dispatch(
      getReleaseRecordsByPage({
        address,
        provider,
        networkID: chainID,
        page: 1,
        limit: INVITE_PER_PAGE,
      })
    );
    dispatch(getBalances({ address, networkID: chainID, provider }));
  };

  const handleBuyBToken = () => {
    // window.open(
    //   `https://pancakeswap.finance/swap?chain=${
    //     chainID === 56 ? "bsc" : "bscTestnet"
    //   }&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${
    //     addresses[chainID].bToken
    //   }`,
    //   "_blank"
    // );
  };

  const handleApproveForStakeInterest = async () => {
    const token =
      (releasePoolAllowance as number) >= Number(burnObj[days]) &&
      releasePoolAllowance != 0
        ? "sohm"
        : "WBNB";
    // : "btoken";

    await dispatch(
      changeApprovalInterest({
        address,
        provider,
        networkID: chainID,
        token,
      })
    );
  };

  // console.log("[allowance stake]", {
  //   releasePoolAllowance: releasePoolAllowance as number,
  //   select: Number(burnObj[days]),
  //   unstakeAllowance,
  //   interestValue,
  //   releaseRecords,
  // });

  return (
    <Box
      display="flex"
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        position: "relative",
        mb: "100px",
      }}
    >
      <Box
        sx={{
          maxWidth: "1340px",
          width: { xs: "100%", sm: "97%" },
          pt: { sm: "87px", xs: "18px" },
          px: { xs: "15px", sm: "0" },
          position: "relative",
          zIndex: "1",
        }}
      >
        {/* stake top */}
        <Box
          sx={{
            mb: {
              xs: "20px",
              sm: "50px",
            },
          }}
          display="flex"
          alignItems={"cneter"}
          justifyContent={"space-between"}
          flexDirection={{
            sm: "row",
            xs: "column",
          }}
        >
          <Box
            display="flex"
            alignItems={"center"}
            sx={{
              mb: { xs: "15px", sm: "0" },
            }}
          >
            <Box
              sx={{
                mr: { sm: "20px", xs: "12px" },
              }}
            >
              <StarIcon
                style={{
                  width: isSmallScreen ? "37px" : "61px",
                  height: isSmallScreen ? "37px" : "61px",
                }}
              />
            </Box>
            <Box
              sx={{
                "&>img": {
                  width: { xs: "70px", sm: "115px" },
                },
              }}
              display="flex"
              flexDirection={"column"}
              // alignItems={"center"}
              justifyContent={"center"}
            >
              <img src={StakeIcon} alt="skake" />

              <RebaseTimer />
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#848E9C",
                fontSize: "18px",
                fontWeight: "400",
                textAlign: {
                  sm: "right",
                  xs: "left",
                },
              }}
            >
              <Trans>APY</Trans>
            </Typography>
            {stakingAPY ? (
              <Typography
                sx={{
                  color: "#EAECEF",
                  fontSize: "24px",
                  fontWeight: "600",
                  width: "100%",
                  maxWidth: "888px",
                  // overflow: "hidden",
                  // textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  textAlign: {
                    sm: "right",
                    xs: "left",
                  },
                }}
              >
                {new Intl.NumberFormat("en-US").format(
                  Number(trimmedStakingAPY)
                )}
                %{/* </span> */}
              </Typography>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                <Skeleton width="150px" data-testid="apy-loading" />
              </Box>
            )}
          </Box>
        </Box>
        {/* stake bottom */}
        <Grid
          container
          columnSpacing={2}
          sx={{
            mb: { xs: "20px", sm: "40px" },
          }}
        >
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              mb: { xs: "8px", sm: "0" },
            }}
          >
            <Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #2d2d2d",
                  background: "#151515",
                  width: "100%",
                  height: { xs: "72px", sm: "125.5px" },
                  px: { xs: "15px", sm: "20px" },
                  py: { sm: "32px" },
                  mb: { xs: "8px", sm: "16px" },
                }}
                display="flex"
                alignItems={"cneter"}
                flexDirection={"column"}
                justifyContent={{ xs: "center", sm: "flex-start" }}
              >
                <Box
                  sx={
                    {
                      // mb: "10px",
                    }
                  }
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "18px" },
                      color: "#848e9c",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>Total Value Deposited</Trans>
                  </Typography>
                </Box>
                {stakingTVL ? (
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "16px", sm: "24px" },
                      fontStyle: "normal",
                      fontWeight: "600",
                      width: "100%",
                    }}
                  >
                    <span data-testid="tvl-value">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      }).format(stakingTVL)}
                    </span>
                  </Typography>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    // justifyContent="center"
                    sx={{ width: "100%" }}
                  >
                    <Skeleton width="150px" data-testid="tvl-loading" />
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #2d2d2d",
                  background: "#151515",
                  width: "100%",
                  height: { xs: "72px", sm: "125.5px" },
                  px: { xs: "15px", sm: "20px" },
                  py: { sm: "32px" },
                  mb: { xs: "8px", sm: "16px" },
                }}
                display="flex"
                alignItems={"cneter"}
                flexDirection={"column"}
                justifyContent={{ xs: "center", sm: "flex-start" }}
              >
                <Box
                  sx={
                    {
                      // mb: "10px",
                    }
                  }
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "18px" },
                      color: "#848e9c",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>Current Index</Trans>
                  </Typography>
                </Box>
                {currentIndex ? (
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "16px", sm: "24px" },
                      fontStyle: "normal",
                      fontWeight: "600",
                      width: "100%",
                    }}
                  >
                    <span data-testid="index-value">
                      {customNumberFormat(Number(currentIndex), 4)} NVB
                    </span>
                  </Typography>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    <Skeleton width="150px" data-testid="tvl-loading" />
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #2d2d2d",
                  background: "#151515",
                  width: "100%",
                  height: { xs: "72px", sm: "125.5px" },
                  px: { xs: "15px", sm: "20px" },
                  py: { sm: "32px" },
                  mb: { xs: "8px", sm: "16px" },
                }}
                display="flex"
                alignItems={"cneter"}
                flexDirection={"column"}
                justifyContent={{ xs: "center", sm: "flex-start" }}
              >
                <Box
                  sx={
                    {
                      // mb: "10px",
                    }
                  }
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "18px" },
                      color: "#848e9c",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>Staked Principal(My Account)</Trans>
                  </Typography>
                </Box>
                {address ? (
                  principalAmt ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "16px", sm: "24px" },
                        fontStyle: "normal",
                        fontWeight: "600",
                        width: "100%",
                      }}
                    >
                      <span data-testid="index-value">
                        {Number(principalAmt) >= 0.0001
                          ? customNumberFormat(Number(principalAmt), 4)
                          : "0"}{" "}
                        sNVB
                      </span>
                    </Typography>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Skeleton width="150px" data-testid="tvl-loading" />
                    </Box>
                  )
                ) : (
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "16px", sm: "24px" },
                      fontStyle: "normal",
                      fontWeight: "600",
                      width: "100%",
                    }}
                  >
                    <span data-testid="index-value">0 sNVB</span>
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "1px solid #2d2d2d",
                  background: "#151515",
                  width: "100%",
                  height: { xs: "72px", sm: "125.5px" },
                  px: { xs: "15px", sm: "20px" },
                  py: { sm: "32px" },
                }}
                display="flex"
                alignItems={"cneter"}
                flexDirection={"column"}
                justifyContent={{ xs: "center", sm: "flex-start" }}
              >
                <Box
                  sx={
                    {
                      // mb: "10px",
                    }
                  }
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", sm: "18px" },
                      color: "#848e9c",
                      fontWeight: "400",
                    }}
                  >
                    <Trans>Interest(My Account)</Trans>
                  </Typography>
                </Box>
                {address ? (
                  interestBal ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: { xs: "16px", sm: "24px" },
                        fontStyle: "normal",
                        fontWeight: "600",
                        width: "100%",
                      }}
                    >
                      <span data-testid="index-value">
                        {customNumberFormat(Number(interestBal), 4)} sNVB
                      </span>
                    </Typography>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ width: "100%" }}
                    >
                      <Skeleton width="150px" data-testid="tvl-loading" />
                    </Box>
                  )
                ) : (
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "16px", sm: "24px" },
                      fontStyle: "normal",
                      fontWeight: "600",
                      width: "100%",
                    }}
                  >
                    <span data-testid="index-value">0 sNVB</span>
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: { xs: "6px", sm: "10px" },
                border: "1px solid #2d2d2d",
                background: "#151515",
                pb: "20px",
              }}
            >
              {/* stake tab */}
              <Box>
                <Tabs
                  value={view}
                  onChange={changeView}
                  sx={{
                    borderBottom: "1px solid #2D2D2D",
                    "& .MuiTabs-indicator": {
                      background: "#fff",
                    },
                  }}
                >
                  <Tab
                    label={t({
                      id: "do_stake",
                    })}
                    sx={{
                      color: "#848E9C",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "400",
                      "&.Mui-selected": {
                        color: "#fff",
                        fontWeight: "600",
                      },
                    }}
                  />
                  <Tab
                    label={t`Unstake`}
                    sx={{
                      color: "#848E9C",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "400",
                      "&.Mui-selected": {
                        color: "#fff",
                        fontWeight: "600",
                      },
                    }}
                  />
                  <Tab
                    label={t`claim interest`}
                    sx={{
                      color: "#848E9C",
                      fontSize: { xs: "14px", sm: "16px" },
                      fontWeight: "400",
                      "&.Mui-selected": {
                        color: "#fff",
                        fontWeight: "600",
                      },
                    }}
                  />
                </Tabs>
              </Box>
              {/*  stake action  */}
              {view !== 2 ? (
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  sx={{
                    px: {
                      sm: "30px",
                      xs: "15px",
                    },
                    // height: "144px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: { sm: "170px", xs: "auto" },
                    }}
                    display="flex"
                    alignItems={"center"}
                    flexDirection={"column"}
                    justifyContent={view == 1 ? "flex-start" : "center"}
                  >
                    <Box
                      sx={{
                        height: "70px",
                        width: "100%",
                      }}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        {view == 1 && (
                          <>
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: {
                                  sm: "18px",
                                  xs: "14px",
                                },
                                fontWeight: "400",
                              }}
                            >
                              <Trans>Available Principal:</Trans>
                            </Typography>
                            {address ? (
                              avaiablePrincipalAmt ? (
                                <Typography
                                  sx={{
                                    color: "#EAECEF",
                                    fontSize: {
                                      sm: "18px",
                                      xs: "14px",
                                    },
                                    fontWeight: "400",
                                  }}
                                >
                                  {Number(avaiablePrincipalAmt) >= 0.0001
                                    ? customNumberFormat(
                                        Number(avaiablePrincipalAmt),
                                        4
                                      )
                                    : 0}{" "}
                                  sNVB
                                </Typography>
                              ) : (
                                <Skeleton width="80px" />
                              )
                            ) : (
                              <Typography
                                sx={{
                                  color: "#EAECEF",
                                  fontSize: {
                                    sm: "18px",
                                    xs: "14px",
                                  },
                                  fontWeight: "400",
                                }}
                              >
                                0 sNVB
                              </Typography>
                            )}
                          </>
                        )}
                      </Box>

                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={handleOpenModal}
                      >
                        <SvgIcon
                          sx={{
                            width: { sm: "32px", xs: "24px" },
                            height: { sm: "32px", xs: "24px" },
                          }}
                          viewBox="0 0 24 24"
                          component={CalcIcon}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        // py: "50px",
                        height: { sm: "100%", xs: "auto" },
                        width: "100%",
                      }}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={{
                        xs: "column",
                        sm: "row",
                      }}
                    >
                      {!address && view !== 2 ? (
                        <Box
                          sx={{
                            width: "100%",
                          }}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                        >
                          <Box
                            sx={{
                              width: "200px",
                              borderRadius: "6px",
                              height: "42px",
                              border: "1px solid #000",
                              background: "#FCD000",
                              boxShadow: "-3px 3px 0px 0px #ECAA00",
                              mb: "10px",
                              "&:hover": {
                                transform: "scale(1.02)",
                              },
                            }}
                          >
                            <Button
                              sx={{
                                width: "100%",
                                height: "100%",
                                background: "transparent",
                                "&:hover": {
                                  background: "transparent",
                                },
                              }}
                              onClick={connect}
                              variant="contained"
                            >
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                <Trans>Connect Wallet</Trans>
                              </Typography>
                            </Button>
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {view == 0 ? (
                                <Trans>Connect your wallet to stake NVB</Trans>
                              ) : (
                                <Trans>
                                  Connect your wallet to unstake sNVB
                                </Trans>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      ) : address && !isAllowanceDataLoading ? (
                        (!hasAllowance("OHM") && view === 0) ||
                        (!hasAllowance("sOHM") && view === 1) ? (
                          <Box
                            sx={{
                              mb: isSmallScreen ? 2.5 : 0,
                              px: isSmallScreen ? 2.5 : 0,
                              width: { xs: "100%", sm: "60%" },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#848E9C",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="center"
                            >
                              {view === 0 && (
                                <>
                                  <Trans>First time staking</Trans> <b>NVB</b>?
                                  <br />
                                  <Trans>
                                    Please approve NovaBank to use your
                                  </Trans>{" "}
                                  <b>NVB</b> <Trans>for staking</Trans>.
                                </>
                              )}
                              {view === 1 && (
                                <>
                                  <Trans>First time unstaking</Trans>{" "}
                                  <b>sNVB</b>?
                                  <br />
                                  <Trans>
                                    Please approve NovaBank to use your
                                  </Trans>{" "}
                                  <b>sNVB</b> <Trans>for unstaking</Trans>.
                                </>
                              )}
                            </Typography>
                          </Box>
                        ) : (
                          <FormControl
                            variant="outlined"
                            color="primary"
                            sx={{
                              px: 0,
                              width: "100%",
                              "&.ohm-input": {
                                m: isSmallScreen ? "0 !important" : 0,
                                mb: isSmallScreen ? "20px !important" : 0,
                              },
                              mb: { xs: "20px", sm: "0" },
                            }}
                          >
                            <OutlinedInput
                              id="amount-input"
                              type="number"
                              placeholder={t`Enter an amount`}
                              className="stake-input"
                              value={quantity}
                              onChange={onAmountChange}
                              sx={{
                                height: "44px",
                                borderRadius: "6px",
                                border: "1px solid #2d2d2d",
                                background: "#0c0c0c",
                                color: "#fff",
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <Button
                                    sx={{
                                      color: "#FCD000",
                                      textAlign: "right",
                                      fontSize: "16px",
                                      fontWeight: "400",
                                    }}
                                    variant="text"
                                    onClick={setMax}
                                  >
                                    <Trans>Max</Trans>
                                  </Button>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        )
                      ) : (
                        <Skeleton width="150px" />
                      )}
                      {address &&
                        view == 0 &&
                        (isAllowanceDataLoading ? (
                          ""
                        ) : address && hasAllowance("OHM") ? (
                          (stakeAllowance &&
                            stakeAllowance >= Number(quantity)) ||
                          isNaN(quantity) ||
                          quantity == 0 ? (
                            <Box
                              sx={{
                                // p: isSmallScreen ? 2.5 : 0,
                                p: 0,
                                ml: { xs: "0", sm: "16px" },
                                mb: { xs: "20px", sm: "0" },
                                width: { xs: "100%", sm: "auto" },
                              }}
                            >
                              <Box
                                sx={{
                                  width: { xs: "100%", sm: "200px" },
                                  borderRadius: "6px",
                                  height: "44px",
                                  border: "1px solid #000",
                                  background: "#FCD000",
                                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                                  mb: { xs: "10px", sm: "0" },

                                  "&:hover": {
                                    transform: "scale(1.02)",
                                  },
                                }}
                              >
                                <LoadingButton
                                  variant="contained"
                                  disabled={
                                    isPendingTxn(
                                      pendingTransactions,
                                      "staking"
                                    ) || Number(quantity) == 0
                                  }
                                  loading={isPendingTxn(
                                    pendingTransactions,
                                    "staking"
                                  )}
                                  loadingPosition="end"
                                  onClick={() => {
                                    onChangeStake("stake");
                                  }}
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    background: "transparent",
                                    color: "#000",
                                    "&:hover": {
                                      background: "transparent",
                                    },
                                  }}
                                >
                                  {txnButtonText(
                                    pendingTransactions,
                                    "staking",
                                    t`Stake OHM`
                                  )}
                                </LoadingButton>
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                p: 0,
                                ml: { xs: "0", sm: "16px" },
                                mb: { xs: "20px", sm: "0" },
                                width: { xs: "100%", sm: "auto" },
                              }}
                            >
                              <Box
                                sx={{
                                  width: { xs: "100%", sm: "200px" },
                                  borderRadius: "6px",
                                  height: "44px",
                                  border: "1px solid #000",
                                  background: "#FCD000",
                                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                                  mb: { xs: "10px", sm: "0" },

                                  "&:hover": {
                                    transform: "scale(1.02)",
                                  },
                                }}
                              >
                                <LoadingButton
                                  variant="contained"
                                  disabled={
                                    isPendingTxn(
                                      pendingTransactions,
                                      "approve_staking"
                                    ) || Number(quantity) == 0
                                  }
                                  loading={isPendingTxn(
                                    pendingTransactions,
                                    "approve_staking"
                                  )}
                                  loadingPosition="end"
                                  onClick={() => {
                                    onSeekApproval("ohm");
                                  }}
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    background: "transparent",
                                    color: "#000",
                                    "&:hover": {
                                      background: "transparent",
                                    },
                                  }}
                                >
                                  {txnButtonText(
                                    pendingTransactions,
                                    "approve_staking",
                                    t`Approve`
                                  )}
                                </LoadingButton>
                              </Box>
                            </Box>
                          )
                        ) : (
                          <Box
                            sx={{
                              // p: isSmallScreen ? 2.5 : 0,
                              mb: { xs: "20px", sm: "0" },
                              width: { xs: "100%", sm: "40%" },
                            }}
                          >
                            <Box
                              sx={{
                                width: { xs: "100%", sm: "200px" },
                                borderRadius: "6px",
                                height: "44px",
                                border: "1px solid #000",
                                background: "#FCD000",
                                boxShadow: "-3px 3px 0px 0px #ECAA00",
                                mb: { xs: "10px", sm: "0" },
                                "&:hover": {
                                  transform: "scale(1.02)",
                                },
                              }}
                            >
                              <LoadingButton
                                variant="contained"
                                disabled={isPendingTxn(
                                  pendingTransactions,
                                  "approve_staking"
                                )}
                                loading={isPendingTxn(
                                  pendingTransactions,
                                  "approve_staking"
                                )}
                                loadingPosition="end"
                                onClick={() => {
                                  onSeekApproval("ohm");
                                }}
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  background: "transparent",
                                  color: "#000",
                                  "&:hover": {
                                    background: "transparent",
                                  },
                                }}
                              >
                                {txnButtonText(
                                  pendingTransactions,
                                  "approve_staking",
                                  t`Approve`
                                )}
                              </LoadingButton>
                            </Box>
                          </Box>
                        ))}
                      {address &&
                        view == 1 &&
                        (isAllowanceDataLoading ? (
                          <Skeleton />
                        ) : address && hasAllowance("sOHM") ? (
                          (unstakeAllowance && unstakeAllowance >= quantity) ||
                          isNaN(quantity) ||
                          quantity == 0 ? (
                            <Box
                              sx={{
                                p: 0,
                                ml: { xs: "0", sm: "16px" },
                                mb: { xs: "20px", sm: "0" },
                                width: { xs: "100%", sm: "auto" },
                              }}
                            >
                              <Box
                                sx={{
                                  width: {
                                    xs: "100%",
                                    sm: "200px",
                                  },
                                  height: "44px",
                                  borderRadius: "6px",
                                  border: "1px solid #000",
                                  background: "#fcd000",
                                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                                  mb: { xs: "10px", sm: "0" },
                                  "&:hover": {
                                    transform: "scale(1.02)",
                                  },
                                }}
                              >
                                <LoadingButton
                                  variant="contained"
                                  disabled={
                                    isPendingTxn(
                                      pendingTransactions,
                                      "unstaking"
                                    ) || Number(quantity) == 0
                                  }
                                  loading={isPendingTxn(
                                    pendingTransactions,
                                    "unstaking"
                                  )}
                                  loadingPosition="end"
                                  onClick={() => {
                                    onChangeStake("unstake");
                                  }}
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    background: "transparent",
                                    color: "#000",
                                    "&:hover": {
                                      background: "transparent",
                                    },
                                  }}
                                >
                                  {txnButtonText(
                                    pendingTransactions,
                                    "unstaking",
                                    t`Unstake sNVB`
                                  )}
                                </LoadingButton>
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                p: 0,
                                ml: { xs: "0", sm: "16px" },
                                mb: { xs: "20px", sm: "0" },
                                width: { xs: "100%", sm: "auto" },
                              }}
                            >
                              <Box
                                sx={{
                                  width: {
                                    xs: "100%",
                                    sm: "200px",
                                  },
                                  height: "44px",
                                  borderRadius: "6px",
                                  border: "1px solid #000",
                                  background: "#fcd000",
                                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                                  mb: { xs: "10px", sm: "0" },
                                  "&:hover": {
                                    transform: "scale(1.02)",
                                  },
                                }}
                              >
                                <LoadingButton
                                  variant="contained"
                                  disabled={
                                    isPendingTxn(
                                      pendingTransactions,
                                      "approve_unstaking"
                                    ) || Number(quantity) == 0
                                  }
                                  loading={isPendingTxn(
                                    pendingTransactions,
                                    "approve_unstaking"
                                  )}
                                  loadingPosition="end"
                                  onClick={() => {
                                    onSeekApproval("sOhm");
                                  }}
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    background: "transparent",
                                    color: "#000",
                                    "&:hover": {
                                      background: "transparent",
                                    },
                                  }}
                                >
                                  {txnButtonText(
                                    pendingTransactions,
                                    "approve_unstaking",
                                    t`Approve`
                                  )}
                                </LoadingButton>
                              </Box>
                            </Box>
                          )
                        ) : (
                          <Box
                            sx={{
                              // ml: { xs: "0", sm: "40px" },
                              width: { xs: "100%", sm: "40%" },
                              mb: { xs: "20px", sm: "0" },
                            }}
                          >
                            <Box
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: "200px",
                                },
                                height: "44px",
                                borderRadius: "6px",
                                border: "1px solid #000",
                                background: "#fcd000",
                                boxShadow: "-3px 3px 0px 0px #ECAA00",
                                mb: { xs: "10px", sm: "0" },

                                "&:hover": {
                                  transform: "scale(1.02)",
                                },
                              }}
                            >
                              <LoadingButton
                                variant="contained"
                                disabled={isPendingTxn(
                                  pendingTransactions,
                                  "approve_unstaking"
                                )}
                                loading={isPendingTxn(
                                  pendingTransactions,
                                  "approve_unstaking"
                                )}
                                loadingPosition="end"
                                onClick={() => {
                                  onSeekApproval("sOhm");
                                }}
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  background: "transparent",
                                  color: "#000",
                                  "&:hover": {
                                    background: "transparent",
                                  },
                                }}
                              >
                                {txnButtonText(
                                  pendingTransactions,
                                  "approve_unstaking",
                                  t`Approve`
                                )}
                              </LoadingButton>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {/* account balance */}
                    <Box
                      sx={{
                        width: "100%",
                        borderBottom: "1px solid #2d2d2d",
                        mb: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          mb: "16px",
                        }}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>Unstaked Balance</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {isAppLoading ? (
                            <Skeleton width="80px" />
                          ) : (
                            <>{customNumberFormat(Number(ohmBalance), 4)} NVB</>
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          mb: "16px",
                        }}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>Staked Balance</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {isAppLoading ? (
                            <Skeleton width="80px" />
                          ) : (
                            <>
                              {customNumberFormat(Number(trimmedBalance), 4)}{" "}
                              sNVB
                            </>
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          mb: "16px",
                        }}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>Your Warmup Balance(2 epochs)</Trans>
                        </Typography>
                        {isAppLoading ? (
                          <Skeleton width="80px" />
                        ) : (
                          <Box
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Typography
                              sx={{
                                color: "#EAECEF",
                                fontSize: "14px",
                                fontWeight: "500",
                                mr: 1,
                              }}
                            >
                              <>{customNumberFormat(Number(gonsBal), 4)} sNVB</>
                            </Typography>
                            <Box
                              sx={{
                                width: {
                                  xs: "100%",
                                  sm: isPendingTxn(pendingTransactions, "claim")
                                    ? "120px"
                                    : "85px",
                                },

                                height: "33px",
                                borderRadius: "6px",
                                border: "1px solid #000",
                                background: "#fcd000",
                                boxShadow: "-3px 3px 0px 0px #ECAA00",
                                "&:hover": {
                                  transform: "scale(1.02)",
                                },
                              }}
                            >
                              <LoadingButton
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  background: "transparent",
                                  color: "#000",
                                  "&:hover": {
                                    background: "transparent",
                                  },
                                }}
                                variant="contained"
                                disabled={
                                  isPendingTxn(pendingTransactions, "claim") ||
                                  isPendingTxn(
                                    pendingTransactions,
                                    "staking"
                                  ) ||
                                  isPendingTxn(
                                    pendingTransactions,
                                    "unstaking"
                                  ) ||
                                  (Number(gonsBal) != 0 && isLocked) ||
                                  Number(gonsBal) == 0
                                }
                                loading={isPendingTxn(
                                  pendingTransactions,
                                  "claim"
                                )}
                                loadingPosition="end"
                                onClick={claimWarmupBal}
                              >
                                <Typography
                                  sx={{
                                    color: "#000",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    fontWeight: "5500",
                                  }}
                                >
                                  {txnButtonText(
                                    pendingTransactions,
                                    "claim",
                                    "Claim"
                                  )}
                                </Typography>
                              </LoadingButton>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {/* reward data */}
                    <Box
                      sx={{
                        width: "100%",
                        mb: "24px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          mb: "16px",
                        }}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>Next Reward Amount</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {isAppLoading ? (
                            <Skeleton width="80px" />
                          ) : (
                            <>{nextRewardValue} sNVB</>
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          mb: "16px",
                        }}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>Next Reward Yield</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {isAppLoading ? (
                            <Skeleton width="80px" />
                          ) : (
                            <>{stakingRebasePercentage}%</>
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          mb: "16px",
                        }}
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>ROI (5-Day Rate)</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "500",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                          }}
                        >
                          {isAppLoading ? (
                            <Skeleton width="80px" />
                          ) : (
                            <>
                              {trim(toolNumber(Number(fiveDayRate) * 100), 4)}%
                            </>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    {/* desc */}
                    <Box>
                      <Typography
                        sx={{
                          color: "#848E9C",
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "1.2",
                        }}
                      >
                        <Trans>
                          Note: There are two warm-up staking periods, and users
                          must stake for more than two periods to receive funds.
                          Users will accumulate rewards during the warm-up
                          period but cannot claim them within two periods. When
                          two periods have passed, your preheating balance can
                          be withdrawn from the preheating contract to your
                          wallet, and you continue to receive rebase rewards.
                        </Trans>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    p: { xs: "15px", sm: "30px" },
                    // px: "30px",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      mb: "26px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#848E9C",
                        fontSize: {
                          sm: "18px",
                          xs: "14px",
                          // whiteSpace: "nowrap",
                        },
                        fontWeight: "400",
                        mr: 0.5,
                      }}
                    >
                      <Trans>AVC Balance</Trans>:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#EAECEF",
                        fontSize: { sm: "18px", xs: "14px", mr: "5px" },
                        fontWeight: "400",
                        mr: 1,
                      }}
                    >
                      {customNumberFormat(Number(wbnbBalance), 4)} WBNB
                      {/* {customNumberFormat(Number(bTokenBalance), 4)} AVC */}
                    </Typography>
                    <Box
                      sx={{
                        borderRadius: "6px",
                        border: "1px solid #000",
                        background: "#FCD000",
                        boxShadow: "-3px 3px 0px 0px #ECAA00",
                        // width: "110px",
                        width: "auto",
                        px: "10px",
                        height: "33px",
                        "&:hover": {
                          transform: "scale(1.02)",
                        },
                      }}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Link
                        href="/swap"
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <Button
                          sx={{
                            width: "100%",
                            height: "100%",
                            background: "transparent",
                            "&:hover": {
                              background: "transparent",
                            },
                          }}
                          // onClick={handleBuyBToken}
                        >
                          <Typography
                            sx={{
                              color: "#000",
                              fontSize: { xs: "14px", sm: "16px" },
                              fontWeight: "500",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Trans>Buy $WBNB</Trans>
                            {/* <Trans>Buy $AVC</Trans> */}
                          </Typography>
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                  {/* select option */}
                  <Grid
                    container
                    rowSpacing={2.5}
                    columnSpacing={2}
                    sx={{
                      mb: { sm: "40px", xs: "30px" },
                    }}
                  >
                    {/* 30d */}
                    <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          cursor: "pointer",
                          border:
                            days == "30"
                              ? "1px solid #FCD000"
                              : "1px solid #2d2d2d",
                          p: "14px",
                          borderRadius: "6px",
                        }}
                        onClick={() => handleSelect("30")}
                      >
                        <Typography
                          sx={{
                            color: days == "30" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>30D</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: days == "30" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>Estimated destruction</Trans>{" "}
                          {customNumberFormat(Number(burnAmt30), 4)} WBNB
                          {/* {customNumberFormat(Number(burnAmt30), 4)} AVC */}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* 60d */}
                    <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          cursor: "pointer",
                          border:
                            days == "60"
                              ? "1px solid #FCD000"
                              : "1px solid #2d2d2d",
                          p: "14px",
                          borderRadius: "6px",
                        }}
                        onClick={() => handleSelect("60")}
                      >
                        <Typography
                          sx={{
                            color: days == "60" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>60D</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: days == "60" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>Estimated destruction</Trans>{" "}
                          {/* {customNumberFormat(Number(burnAmt60), 4)} AVC */}
                          {customNumberFormat(Number(burnAmt60), 4)} WBNB
                        </Typography>
                      </Box>
                    </Grid>
                    {/* 100d */}
                    <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          cursor: "pointer",
                          border:
                            days == "100"
                              ? "1px solid #FCD000"
                              : "1px solid #2d2d2d",
                          p: "14px",
                          borderRadius: "6px",
                        }}
                        onClick={() => handleSelect("100")}
                      >
                        <Typography
                          sx={{
                            color: days == "100" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>100D</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: days == "100" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>Estimated destruction</Trans>{" "}
                          {customNumberFormat(Number(burnAmt100), 4)} WBNB
                          {/* {customNumberFormat(Number(burnAmt100), 4)} AVC */}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* 150d */}
                    <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          cursor: "pointer",
                          border:
                            days == "150"
                              ? "1px solid #FCD000"
                              : "1px solid #2d2d2d",
                          p: "14px",
                          borderRadius: "6px",
                        }}
                        onClick={() => handleSelect("150")}
                      >
                        <Typography
                          sx={{
                            color: days == "150" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>150D</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: days == "150" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>Estimated destruction</Trans>{" "}
                          {customNumberFormat(Number(burnAmt150), 4)} WBNB
                          {/* {customNumberFormat(Number(burnAmt150), 4)} AVC */}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* 180d */}
                    <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                          cursor: "pointer",
                          border:
                            days == "180"
                              ? "1px solid #FCD000"
                              : "1px solid #2d2d2d",
                          p: "14px",
                          borderRadius: "6px",
                        }}
                        onClick={() => handleSelect("180")}
                      >
                        <Typography
                          sx={{
                            color: days == "180" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>180D</Trans>
                        </Typography>
                        <Typography
                          sx={{
                            color: days == "180" ? "#fcd000" : "#eaecef",
                            fontSize: { sm: "16px", xs: "14px" },
                          }}
                        >
                          <Trans>Estimated destruction</Trans>{" "}
                          {customNumberFormat(Number(burnAmt180), 4)} WBNB
                          {/* {customNumberFormat(Number(burnAmt180), 4)} AVC */}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      width: "100%",
                      mb: {
                        xs: "20px",
                        sm: "0",
                      },
                    }}
                    display="flex"
                    flexDirection={{
                      xs: "column",
                      sm: "row",
                    }}
                    alignItems={"center"}
                    justifyContent={{ xs: "center", sm: "space-between" }}
                  >
                    <OutlinedInput
                      type="number"
                      placeholder={t`Please enter the staked interest`}
                      className="stake-input"
                      value={interestValue}
                      onChange={handleChangeInterest}
                      sx={{
                        height: "44px",
                        borderRadius: "6px",
                        border: "1px solid #2d2d2d",
                        background: "#0c0c0c",
                        color: "#fff",
                        flex: { sm: 1 },
                        width: { xs: "100%" },
                        mr: { xs: "0", sm: "16px" },
                        mb: { xs: "10px", sm: "0" },
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <Button
                            sx={{
                              color: "#FCD000",
                              fontSize: "16px",
                              fontWeight: "400",
                            }}
                            variant="text"
                            onClick={setInterestMax}
                            color="inherit"
                          >
                            <Trans>Max</Trans>
                          </Button>
                        </InputAdornment>
                      }
                    />
                    {isSmallScreen && (
                      <Box
                        display="flex"
                        // alignItems={"center"}
                        sx={{
                          mb: "20px",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#848e9c",
                            fontSize: "14px",
                            fontWeight: "400",
                            "&>span": {
                              color: "#EAECEF",
                              ml: 0.5,
                            },
                          }}
                        >
                          <Trans>Available Interest</Trans>:
                        </Typography>
                        <Typography
                          sx={{
                            color: "#EAECEF",
                            fontSize: "14px",
                            fontWeight: "400",
                            ml: 0.5,
                          }}
                        >
                          {isAppLoading ? (
                            <Skeleton width="80px" />
                          ) : (
                            <span>
                              {customNumberFormat(Number(avaiableInterest), 4)}{" "}
                              sNVB
                            </span>
                          )}
                        </Typography>
                      </Box>
                    )}

                    <Box
                      sx={{
                        width: { xs: "100%", sm: "200px" },
                        borderRadius: "6px",
                        height: "44px",
                        border: "1px solid #000",
                        background: "#FCD000",
                        boxShadow: "-3px 3px 0px 0px #ECAA00",
                        mb: "10px",
                        "&:hover": {
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      {address ? (
                        ((releasePoolAllowance as number) >
                          Number(burnObj[days]) &&
                          Number(releasePoolAllowance) != 0 &&
                          (unstakeAllowance as number) >= interestValue) ||
                        !interestValue ? (
                          <LoadingButton
                            sx={{
                              width: "100%",
                              height: "100%",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            }}
                            onClick={handleStakeInterest}
                            variant="contained"
                            disabled={
                              isPendingTxn(
                                pendingTransactions,
                                "claim_interest"
                              ) ||
                              Number(interestValue) == 0 ||
                              !interestValue
                            }
                            loading={isPendingTxn(
                              pendingTransactions,
                              "claim_interest"
                            )}
                            loadingPosition="end"
                          >
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              {txnButtonText(
                                pendingTransactions,
                                "claim_interest",
                                t`Claim interest`
                              )}
                              {/* <Trans>Claim interest</Trans> */}
                            </Typography>
                          </LoadingButton>
                        ) : (
                          <LoadingButton
                            sx={{
                              width: "100%",
                              height: "100%",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            }}
                            onClick={handleApproveForStakeInterest}
                            variant="contained"
                            disabled={isPendingTxn(
                              pendingTransactions,
                              "approve_claim_interest"
                            )}
                            loading={isPendingTxn(
                              pendingTransactions,
                              "approve_claim_interest"
                            )}
                            loadingPosition="end"
                          >
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              {txnButtonText(
                                pendingTransactions,
                                "approve_claim_interest",
                                t`Approve` +
                                  " " +
                                  `${
                                    (releasePoolAllowance as number) >=
                                      Number(burnObj[days]) &&
                                    releasePoolAllowance != 0
                                      ? "sNVB"
                                      : "WBNB"
                                    //  "AVC"
                                  }`
                              )}
                            </Typography>
                          </LoadingButton>
                        )
                      ) : (
                        <Box
                          sx={{
                            width: { xs: "100%", sm: "200px" },
                            borderRadius: "6px",
                            height: "42px",
                            border: "1px solid #000",
                            background: "#FCD000",
                            boxShadow: "-3px 3px 0px 0px #ECAA00",
                            mb: "10px",
                            "&:hover": {
                              transform: "scale(1.02)",
                            },
                          }}
                        >
                          <Button
                            sx={{
                              width: "100%",
                              height: "100%",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            }}
                            onClick={connect}
                            variant="contained"
                          >
                            <Typography
                              sx={{
                                color: "#000",
                                fontSize: "14px",
                                fontWeight: "500",
                              }}
                            >
                              <Trans>Connect Wallet</Trans>
                            </Typography>
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  {!isSmallScreen && (
                    <Box
                      display="flex"
                      alignItems={"center"}
                      sx={{
                        mb: "40px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#848e9c",
                          fontSize: "18px",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>Available Interest</Trans>:
                      </Typography>
                      <Typography
                        sx={{
                          color: "#EAECEF",
                          fontSize: "18px",
                          fontWeight: "400",
                          ml: 0.5,
                        }}
                      >
                        {isAppLoading ? (
                          <Skeleton width="80px" />
                        ) : (
                          <span>
                            {customNumberFormat(Number(avaiableInterest), 4)}{" "}
                            sNVB
                          </span>
                        )}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#848e9c",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      <Trans>
                        Tip: To claim the interest, you must destroy the
                        corresponding amount of WBNB
                      </Trans>
                      {/* <Trans>
                        Tip: To claim the interest, you must destroy the
                        corresponding amount of AVC
                      </Trans> */}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* record */}
        {/* <StakeRecord /> */}
        {((releaseRecords && releaseRecords.length >= 0) || !address) && (
          <Box
            sx={{
              width: "100%",
            }}
          >
            {/* record tab */}
            <Tabs
              sx={{
                borderBottom: "1px solid #2D2D2D",
                mb: {
                  xs: "15px",
                  sm: "40px",
                },
                "& .MuiTabs-indicator": {
                  background: "#fff",
                  fontWeight: "600",
                },
              }}
              value={0}
            >
              <Tab
                label={t({
                  id: "record",
                })}
                sx={{
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "400",
                  textTransform: "capitalize",
                  "&.Mui-selected": {
                    color: "#fff",
                    fontWeight: "600",
                  },
                }}
              />
            </Tabs>
            {/* data */}

            <Record recordData={!address ? [] : releaseRecords} />
          </Box>
        )}
      </Box>
      {/* left top bg */}
      <Box
        sx={{
          position: "absolute",
          left: "-20px",
          top: { xs: "-50px", sm: "-25px" },
          width: { xs: "186px", sm: "390px" },
          height: { xs: "309px", sm: "509px" },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={FooterBg} />
      </Box>
      <CalculatorModal
        open={modalOpen}
        handleClose={handleClose}
        stakingRebasePercentage={stakingRebasePercentage}
      />
    </Box>
  );
}

export default Stake;
