import { useSelector } from "react-redux";
import { Skeleton, Box, Typography } from "@mui/material";
import {
  trim,
  formatCurrency,
  toolNumber,
  customNumberFormat,
} from "../../../../helpers";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip.jsx";
import { t, Trans } from "@lingui/macro";
import { allBondsMap } from "src/helpers/AllBonds";

export const Metric = (props) => (
  <Box
    sx={{
      width: "100%",
      px: { xs: "15px", sm: "20px" },
      height: { xs: "72px", sm: "97px" },
      border: "1px solid #2d2d2d",
      background: "#151515",
      borderRadius: { sm: "10px", xs: "6px" },
      mb: { sm: props.isBottom ? "0" : "16px", xs: "8px" },
    }}
    display="flex"
    // alignItems={"center"}
    justifyContent={"center"}
    flexDirection={"column"}
  >
    {props.children}
  </Box>
);

Metric.Value = (props) => (
  <Typography
    sx={{
      color: "#EAECEF",
      fontSize: { xs: "16px", sm: "24px" },
      fontWeight: "600",
    }}
    // align="center"
  >
    {props.children || <Skeleton type="text" />}
  </Typography>
);

Metric.Title = (props) => (
  <Typography
    sx={{
      color: "#848E9C",
      fontSize: { xs: "12px", sm: "18px" },
      fontWeight: "400",
    }}
    // align="center"
  >
    {props.children}
  </Typography>
);

export const MarketCap = () => {
  const marketCap = useSelector((state) => state.app.marketCap);

  return (
    <Metric className="market">
      <Metric.Title>
        <Trans>Market Cap</Trans>
      </Metric.Title>
      <Metric.Value>{marketCap && formatCurrency(marketCap, 2)}</Metric.Value>
    </Metric>
  );
};

export const OHMPrice = () => {
  const marketPrice = useSelector((state) => state.app.marketPrice);

  return (
    <Metric className="price" isBottom>
      <Metric.Title>
        <Trans>OHM Price</Trans>
      </Metric.Title>
      <Metric.Value>
        {marketPrice && formatCurrency(marketPrice, 2)}
      </Metric.Value>
    </Metric>
  );
};
export const TotalMinting = () => {
  // const marketPrice = useSelector(state => state.app.marketPrice);
  const totalSupply = useSelector((state) => state.app.totalSupply);
  return (
    <Metric className="price">
      <Metric.Title>
        <Trans>Total minting</Trans>(NVB)
      </Metric.Title>
      <Metric.Value>
        {totalSupply && customNumberFormat(totalSupply, 4)}
      </Metric.Value>
    </Metric>
  );
};

export const SOHMPrice = () => {
  const marketPrice = useSelector((state) => state.app.marketPrice);

  return (
    <Metric className="price">
      <Metric.Title>
        <Trans>sOHM Price</Trans>
      </Metric.Title>
      <Metric.Value>
        {marketPrice && formatCurrency(marketPrice, 2)}
      </Metric.Value>
    </Metric>
  );
};

export const CircSupply = () => {
  const circSupply = useSelector((state) => state.app.circSupply);
  const totalSupply = useSelector((state) => state.app.totalSupply);

  const isDataLoaded = circSupply && totalSupply;

  return (
    <Metric className="circ">
      <Metric.Title>
        <Trans>Circulating Supply (total)</Trans>
      </Metric.Title>
      <Metric.Value>
        {isDataLoaded && parseInt(circSupply) + " / " + parseInt(totalSupply)}
      </Metric.Value>
    </Metric>
  );
};

export const TotalSupply = () => {
  const totalSupply = useSelector((state) => state.app.totalSupply);

  return (
    <Metric className="circ">
      <Metric.Title>
        <Trans>Total Supply</Trans>
      </Metric.Title>
      <Metric.Value>{totalSupply && parseInt(totalSupply)}</Metric.Value>
    </Metric>
  );
};

export const BackingPerOHM = () => {
  const treasuryBalance = useSelector((state) => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          tokenBalances += state.bonding[bond].purchased;
        }
      }
      if (tokenBalances) {
        return tokenBalances;
      } else {
        return 0;
      }
    }
  });

  const totalSupply = useSelector((state) => {
    return state.app.totalSupply || 0;
  });

  const daoBal = useSelector((state) => {
    return state.app.daoBal;
  });
  const totalReserves = useSelector((state) => {
    return state.app.totalReserves;
  });
  const backingPerOhm = totalReserves / totalSupply;
  return (
    <Metric className="bpo">
      <Metric.Title>
        <Trans>Backing per OHM</Trans>
      </Metric.Title>
      <Metric.Value>
        {!isNaN(backingPerOhm) && formatCurrency(backingPerOhm, 2)}
      </Metric.Value>
    </Metric>
  );
};

export const CurrentIndex = () => {
  const currentIndex = useSelector((state) => state.app.currentIndex);

  return (
    <Metric className="index">
      <Metric.Title>
        <Trans>Current Index</Trans>
        <InfoTooltip
          message={t`The current index tracks the amount of sOHM accumulated since the betting started. Basically, how many sOHM a person would have if they wagered and held one OHM from day one.`}
        />
      </Metric.Title>
      <Metric.Value>
        {currentIndex && trim(currentIndex, 2) + " OHM"}
      </Metric.Value>
    </Metric>
  );
};

export const WSOHMPrice = () => {
  const wsOhmPrice = useSelector(
    (state) => state.app.marketPrice * state.app.currentIndex
  );

  return (
    <Metric className="wsoprice">
      <Metric.Title>
        <Trans>OHM Price</Trans>
        <InfoTooltip
          message={
            "wsOHM = sOHM * index\n\nThe price of wsOHM is equal to the price of OHM multiplied by the current index"
          }
        />
      </Metric.Title>
      <Metric.Value>{wsOhmPrice && formatCurrency(wsOhmPrice, 2)}</Metric.Value>
    </Metric>
  );
};
