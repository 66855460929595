import { Box, Button, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";
import { t, Trans } from "@lingui/macro";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { i18n } from "@lingui/core";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useAppSelector, useMobile } from "src/hooks";
import { useWeb3Context } from "src/hooks/web3Context";
import {
  getContributionRecordsByPage,
  getReleaseRecordsByPage,
  getTokenContributionRecordsByPage,
  releaseClaim,
} from "src/slices/ReleaseSlice";

import "dayjs/locale/zh-hk";
import {
  compareDuration,
  customNumberFormat,
  prettifySeconds,
  prettyReleaseVestingPeriod,
  trim,
} from "src/helpers";

export const RecordDataCard = ({
  record,
  openAccelerate,
  isStakeRecord,
  index,
  isBurnToken,
}: // releaseClaim,
any) => {
  console.log("vestingPeriod", record);

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "claim_all_record") ||
      isPendingTxn(pendingTransactions, "release_claim")
    ) {
      return true;
    }

    return false;
  };
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const levelDuration15 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration15;
  });
  const levelDuration30 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration30;
  });
  const isGtTime15 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration15)
  );
  const isGtTime30 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration30)
  );

  console.log("isGtTime15", isGtTime15, {
    startAt: Number(record.startAt),
    duration: Number(record.duration),
    levelDuration15: Number(levelDuration15),
  });
  const vestingPeriod = () => {
    return prettyReleaseVestingPeriod(
      Number(record.startAt),
      Number(record.duration)
    );
  };

  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });

  const handleReleaseClaim = async () => {
    console.log("handleReleaseClaim", { record, index, isStakeRecord });
    await dispatch(
      releaseClaim({
        address,
        networkID: chainID,
        provider,
        id: index,
        isStakeRecord,
        isBurnToken,
      })
    );
    if (isBurnToken) {
      dispatch(
        getTokenContributionRecordsByPage({
          address,
          page: 1,
          provider,
          networkID: chainID,
        })
      );
    } else {
      if (isStakeRecord) {
        dispatch(
          getReleaseRecordsByPage({
            address,
            provider,
            networkID: chainID,
            page: 1,
          })
        );
      } else {
        dispatch(
          getContributionRecordsByPage({
            address,
            provider,
            networkID: chainID,
            page: 1,
          })
        );
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        borderBottom: "1px solid #2d2d2d",
        py: "20px",
      }}
    >
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#848e9c",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Trans>Time</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {record.startAt &&
            dayjs(Number(record.startAt) * 1000).format("YYYY/MM/DD HH:mm:ss")}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#848e9c",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Trans>Destruction </Trans>
          {isBurnToken ? " NVB" : " WBNB"}
        </Typography>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {customNumberFormat(Number(record.burnedAmt), 4)}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#848e9c",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Trans>Available NVB</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {customNumberFormat(Number(record.available), 4)}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#848e9c",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Trans>Pending</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {customNumberFormat(Number(record.pending), 4)}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#848e9c",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Trans>Fully Vested</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {vestingPeriod()}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box
          sx={{
            width: "50%",
            borderRadius: "6px",
            height: "35px",
            border: "1px solid #000",
            background: "#FCD000",
            boxShadow: "-3px 3px 0px 0px #ECAA00",
            mr: "10px",
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
        >
          <LoadingButton
            sx={{
              width: "100%",
              height: "100%",
              background: "transparent",
              "&:hover": {
                background: "transparent",
              },
            }}
            // loading
            loadingPosition="end"
            onClick={handleReleaseClaim}
            variant="contained"
            loading={
              isPendingTxn(pendingTransactions, "release_claim") ||
              pendingClaim()
            }
            disabled={
              isPendingTxn(pendingTransactions, "release_claim") ||
              pendingClaim()
            }
          >
            <Typography
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <Trans>Claim</Trans>
            </Typography>
          </LoadingButton>
        </Box>
        <Box
          sx={{
            width: "50%",
            borderRadius: "6px",
            height: "35px",
            border: "1px solid #000",
            background: "#FCD000",
            boxShadow: "-3px 3px 0px 0px #ECAA00",
            "&:hover": {
              transform: "scale(1.02)",
            },
          }}
        >
          <LoadingButton
            sx={{
              width: "100%",
              height: "100%",
              background: "transparent",
              "&:hover": {
                background: "transparent",
              },
            }}
            loadingPosition="end"
            onClick={() => openAccelerate(record, index)}
            variant="contained"
            disabled={!isGtTime15 || (!isGtTime30 && isStakeRecord)}
          >
            <Typography
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              <Trans>Accelerate</Trans>
            </Typography>
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export const RecordTableRow = ({
  record,
  openAccelerate,
  isStakeRecord,
  index,
  isBurnToken,
}: // releaseClaim,
any) => {
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const levelDuration15 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration15;
  });
  const levelDuration30 = useAppSelector((state) => {
    return state.app.level && state.app.level.duration30;
  });
  const isGtTime15 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration15)
  );
  const isGtTime30 = compareDuration(
    Number(record.startAt),
    Number(record.duration),
    Number(levelDuration30)
  );
  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "claim_all_record") ||
      isPendingTxn(pendingTransactions, "release_claim")
    ) {
      return true;
    }

    return false;
  };
  // const handleClaim = () => {
  //   console.log("claim", record);
  // };
  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });
  const vestingPeriod = () => {
    // const seconds =
    //   Number(record.startAt) + Number(record.duration) - Number(dayjs().unix());
    // console.log("vestingPeriod", seconds);
    console.log(
      "vestingPeriod",

      { startAt: Number(record.startAt), duration: Number(record.duration) }
    );
    // return prettifySeconds(seconds);
    return prettyReleaseVestingPeriod(
      Number(record.startAt),
      Number(record.duration)
    );
  };

  const handleReleaseClaim = async () => {
    console.log("handleReleaseClaim", { record, index, isStakeRecord });

    await dispatch(
      releaseClaim({
        address,
        networkID: chainID,
        provider,
        id: index,
        isStakeRecord,
        isBurnToken,
      })
    );
    if (isBurnToken) {
      dispatch(
        getTokenContributionRecordsByPage({
          address,
          page: 1,
          provider,
          networkID: chainID,
        })
      );
    } else {
      if (isStakeRecord) {
        dispatch(
          getReleaseRecordsByPage({
            address,
            provider,
            networkID: chainID,
            page: 1,
          })
        );
      } else {
        dispatch(
          getContributionRecordsByPage({
            address,
            page: 1,
            provider,
            networkID: chainID,
          })
        );
      }
    }
  };

  return (
    <TableRow>
      <TableCell
        align="left"
        sx={{
          borderBottom: "none",
        }}
      >
        <Typography
          sx={{
            color: "#eaecef",
            fontSize: "16px",
            fontWeight: "500",
            borderBottom: "none",
          }}
        >
          {record.startAt &&
            dayjs(Number(record.startAt) * 1000)
              // .locale(i18n.locale === "en" ? "en" : "zh-hk")
              .format("YYYY/MM/DD HH:mm:ss")}
          {/* {Number(record.startAt)} */}
        </Typography>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          borderBottom: "none",
        }}
      >
        <Typography
          sx={{
            color: "#eaecef",
            fontSize: "16px",
            fontWeight: "500",
            borderBottom: "none",
          }}
        >
          {record.burnedAmt && customNumberFormat(Number(record.burnedAmt), 4)}
        </Typography>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          borderBottom: "none",
        }}
      >
        <Typography
          sx={{
            color: "#eaecef",
            fontSize: "16px",
            fontWeight: "500",
            borderBottom: "none",
          }}
        >
          {record.available && customNumberFormat(Number(record.available), 4)}
        </Typography>
      </TableCell>

      <TableCell
        align="left"
        sx={{
          borderBottom: "none",
        }}
      >
        <Typography
          sx={{
            color: "#eaecef",
            fontSize: "16px",
            fontWeight: "500",
            borderBottom: "none",
          }}
        >
          {record.pending && customNumberFormat(Number(record.pending), 4)}
        </Typography>
      </TableCell>
      <TableCell
        align="left"
        sx={{
          borderBottom: "none",
        }}
      >
        <Typography
          sx={{
            color: "#eaecef",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          {vestingPeriod()}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          borderBottom: "none",
        }}
      >
        <Box display="flex" alignItems={"center"} justifyContent={"center"}>
          <Box
            sx={{
              width: "113px",
              borderRadius: "6px",
              height: "35px",
              border: "1px solid #000",
              background: "#FCD000",
              boxShadow: "-3px 3px 0px 0px #ECAA00",
              mr: "10px",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <LoadingButton
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              // loading
              loadingPosition="end"
              onClick={handleReleaseClaim}
              variant="contained"
              loading={
                isPendingTxn(pendingTransactions, "release_claim") ||
                pendingClaim()
              }
              disabled={
                isPendingTxn(pendingTransactions, "release_claim") ||
                pendingClaim()
              }
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <Trans>Claim</Trans>
              </Typography>
            </LoadingButton>
          </Box>
          <Box
            sx={{
              width: "113px",
              borderRadius: "6px",
              height: "35px",
              border: "1px solid #000",
              background: "#FCD000",
              boxShadow: "-3px 3px 0px 0px #ECAA00",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <LoadingButton
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              loadingPosition="end"
              onClick={() => openAccelerate(record, index)}
              variant="contained"
              disabled={!isGtTime15 || (isStakeRecord && !isGtTime30)}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                <Trans>Accelerate</Trans>
              </Typography>
            </LoadingButton>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};
