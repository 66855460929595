import React from "react";
import UniverseBg from "../../assets/images/universe-bg.png";
import Universe1 from "../../assets/images/universe-1.png";
import Universe2 from "../../assets/images/universe-2.png";
import Universe3 from "../../assets/images/universe-3.png";
import Universe4 from "../../assets/images/universe-4.png";
import Universe5 from "../../assets/images/universe-5.png";
import { Box, Tooltip, Typography } from "@mui/material";
const MapUniverse = () => {
  return (
    <Box
      sx={{
        width: "19.93%",
        height: "22.29%",
        // background: `url(${universeBg}) no-repeat 100% 100%`,
        // backgroundSize: "cover",
        position: "absolute",
        inset: { xs: "74% auto auto 66%", sm: "66% auto auto 66%" },
        "&> img": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <img src={UniverseBg} />
      <Box
        sx={{
          position: "absolute",
          inset: "41% auto auto -75%",
          width: "92.47%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Universe5} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "40% auto auto 95%",
          width: "92.47%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Universe2} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "90% auto auto 90%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Universe3} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "90% auto auto -40%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Universe4} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "-8% auto auto 88%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Universe1} />
      </Box>
    </Box>
  );
};

export default MapUniverse;
