import React from "react";
import RWABg from "../../assets/images/rwa.png";
import RWA1 from "../../assets/images/rwa-1.png";
import RWA2 from "../../assets/images/rwa-2.png";
import RWA3 from "../../assets/images/rwa-3.png";
import RWA4 from "../../assets/images/rwa-4.png";
import { Box, Tooltip, Typography } from "@mui/material";

const MapRwa = () => {
  return (
    <Box
      sx={{
        width: "17.33%",
        height: "20.56%",
        // background: `url(${RWABg}) no-repeat 100% 100%`,
        // backgroundSize: "cover",
        position: "absolute",
        inset: "20% auto auto 27%",
        "&> img": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <img src={RWABg} />
      <Box
        sx={{
          position: "absolute",
          inset: "33% auto auto -87%",
          width: "92.47%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={RWA1} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "-14% auto auto -63%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={RWA2} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "-14% auto auto 67%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={RWA4} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "78% auto auto -63%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={RWA3} />
      </Box>
    </Box>
  );
};

export default MapRwa;
