import { Typography, Box } from "@mui/material";
import "./notfound.scss";
import { Trans } from "@lingui/macro";

export default function NotFound() {
  return (
    <div id="not-found">
      <Box
        sx={{
          width: "100%",
          height: "40vh",
        }}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <h4>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "40px",
            }}
          >
            <Trans>Page not found</Trans>
          </Typography>
        </h4>
      </Box>
    </div>
  );
}
