import React from "react";
import {
  Typography,
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
  Skeleton,
} from "@mui/material";
import { useAppSelector } from "src/hooks";
import { t, Trans } from "@lingui/macro";
import { ReactComponent as XIcon } from "src/assets/icons/x.svg";
import { ReactComponent as InviteIcon } from "src/assets/icons/logo.svg";
import { useMobile } from "src/hooks";
import { useWeb3Context } from "src/hooks";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";

const InviteModal = ({ open, handleClose, inviteAddr, handleJoin, isInvited }: any) => {
  const { isSmallScreen } = useMobile();
  const { connected, connect } = useWeb3Context();
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });
  return (
    <Modal
      id="invite-modal"
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: isSmallScreen ? 1.875 : 0,
      }}
      onClose={handleClose}
      // hideBackdrop
    >
      <Box
        sx={{
          width: { xs: "345px", sm: "540px" },
          height: { xs: "232px", sm: "324px" },
          borderRadius: { xs: "6px", sm: "12px" },
          background: "#151515",
          px: { xs: "15px", sm: "30px" },
          py: 2.5,
        }}
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{
            mb: { xs: "31px", sm: "50px" },
          }}
        >
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: { xs: "18px", sm: "24px" },
              fontWeight: "600",
            }}
            align="center"
          >
            <Trans>NovaBank Invitation</Trans>
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0,
            }}
          >
            <SvgIcon sx={{ color: "#EAECEF" }} component={XIcon} />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              px: { xs: "16px", sm: "32px  " },
              py: { xs: "14px", sm: "22px" },
              mb: { xs: "20px", sm: "50px" },
              borderRadius: "6px",
              border: "1px solid #2d2d2d",
              background: "#0C0C0C",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: isSmallScreen ? "13px" : "20px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  wordBreak: "break-all",
                }}
                align="center"
              >
                {inviteAddr ?? <Skeleton width={"200px"} />}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {connected ? (
              <LoadingButton
                variant="contained"
                onClick={handleJoin}
                // disabled
                disabled={isInvited || isPendingTxn(pendingTransactions, "community_join") || !connected}
                loading={isPendingTxn(pendingTransactions, "community_join")}
                // loading
                loadingPosition="end"
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  height: "44px",
                  border: "1px solid #000",
                  background: "#FCD000",
                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    transform: "scale(1.02)",
                    background: "#FCD000",
                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                  },
                  "&.Mui-disabled": {
                    background: "#FCD000",
                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  {txnButtonText(pendingTransactions, "community_join", t`Join`)}
                </Typography>
              </LoadingButton>
            ) : (
              <LoadingButton
                onClick={connect}
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  height: "44px",
                  border: "1px solid #000",
                  background: "#FCD000",
                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    transform: "scale(1.02)",
                    background: "#FCD000",
                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                  },
                  "&.Mui-disabled": {
                    background: "#FCD000",
                    boxShadow: "-3px 3px 0px 0px #ECAA00",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  <Trans>Connect wallet</Trans>
                </Typography>
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default InviteModal;
