import { useEffect, useState } from "react";
import { t, Trans } from "@lingui/macro";
import { ClaimBondTableData, ClaimBondCardData } from "./ClaimRow";
import {
  txnButtonText,
  isPendingTxn,
  txnButtonTextGeneralPending,
} from "src/slices/PendingTxnsSlice";
import { redeemAllBonds, redeemBond } from "src/slices/BondSlice";
import { calculateUserBondDetails } from "src/slices/AccountSlice";
import CardHeader from "../../components/CardHeader/CardHeader";
import { useWeb3Context } from "src/hooks/web3Context";
import useBonds from "src/hooks/Bonds";
import {
  Button,
  Box,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Zoom,
  Typography,
  Pagination,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./choosebond.scss";
import { useSelector, useDispatch } from "react-redux";
import NoData from "src/assets/images/noData.svg";
import { LoadingButton } from "@mui/lab";
import { useMobile } from "../../hooks";
import { styled } from "@material-ui/core";
import { CustormPagination } from "src/components";

export function ClaimDirectBonds({
  directBonds,
  directBondChangePage,
  directBondCount,
}) {
  let isClaimAllNumber = 0;
  directBonds.map((_) => {
    isClaimAllNumber += Number(_.pendingPayout);
  });
  const { isSmallScreen } = useMobile();

  const [activeBond, setActiveBond] = useState(0);
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const { bonds } = useBonds(chainID);
  const [numberOfDirectBonds, setNumberOfDirectBonds] = useState(0);
  // const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });
  useEffect(() => {
    let directBondCount = Object.keys(directBonds).length;
    setNumberOfDirectBonds(directBondCount);
  }, [directBonds]);

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
      isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
    ) {
      return true;
    }

    return false;
  };

  const onRedeemAll = async ({ autostake }) => {
    // console.log("redeeming all bonds");

    await dispatch(
      redeemAllBonds({
        address,
        bonds,
        networkID: chainID,
        provider,
        autostake,
      })
    );

    // console.log("redeem all complete");
  };

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "350px",
      }}
    >
      {/* pc view */}
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          minHeight: "330px",
          height: "100%",
        }}
      >
        <TableContainer>
          <Table aria-label="Claimable bonds">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    color: "#868B8F",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderBottom: "none",
                  }}
                >
                  <Trans>Bond</Trans>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#868B8F",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderBottom: "none",
                  }}
                >
                  <Trans>Claimable</Trans>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    color: "#868B8F",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderBottom: "none",
                  }}
                >
                  <Trans>Pending</Trans>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: "#868B8F",
                    fontSize: "16px",
                    fontWeight: "400",
                    borderBottom: "none",
                  }}
                >
                  <Trans>Fully Vested</Trans>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ borderBottom: "none" }}
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {numberOfDirectBonds > 0
                ? Object.entries(directBonds).map((bond, i) => (
                    <ClaimBondTableData
                      key={i}
                      userBond={bond}
                      isInvite={true}
                    />
                  ))
                : !isSmallScreen && (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{
                          height: "300px",
                          borderBottom: "none",
                        }}
                      >
                        <Box
                          sx={{ width: "100%", height: "100%" }}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"center"}
                          flexDirection={"column"}
                        >
                          <img src={NoData} alt="No Data" />
                          <Typography
                            sx={{
                              pt: 2,
                              color: "#C7C8CC",
                              fontSize: "12px",
                              fontWeight: "400",
                            }}
                          >
                            <Trans>No data</Trans>
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* mobile view */}
      <Box
        sx={{
          width: "100%",
          display: {
            xs: "initial",
            sm: "none",
          },
        }}
      >
        {Object.entries(directBonds).length > 0 ? (
          <Box>
            {Object.entries(directBonds).map((bond, i) => (
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <ClaimBondCardData key={i} userBond={bond} isInvite={true} />
              </Box>
            ))}
          </Box>
        ) : (
          <Box
            sx={{ width: "100%", height: "274px" }}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <img src={NoData} alt="No Data" />
            <Typography
              sx={{
                pt: 2,
                color: "#C7C8CC",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              <Trans>No data</Trans>
            </Typography>
          </Box>
        )}
      </Box>
      {/* <Box
        sx={{
          width: { xs: "100%", sm: "200px" },
          borderRadius: "6px",
          height: "44px",
          border: "1px solid #000",
          background: "#FCD000",
          boxShadow: "-3px 3px 0px 0px #ECAA00",
          mb: "10px",
          "&:hover": {
            transform: "scale(1.02)",
          },
        }}
      >
        <LoadingButton
          variant="contained"
          disabled={
            pendingClaim() ||
            isClaimAllNumber <= 0 ||
            isPendingTxn(pendingTransactions, "redeem_bond")
          }
          loading={
            pendingClaim() || isPendingTxn(pendingTransactions, "redeem_bond")
          }
          loadingPosition="end"
          onClick={() => {
            onRedeemAll({ autostake: true });
          }}
          sx={{
            width: "100%",
            height: "100%",
            background: "transparent",
            color: "#000",
            "&:hover": {
              background: "transparent",
            },
          }}
        >
          {txnButtonTextGeneralPending(
            pendingTransactions,
            "redeem_all_bonds",
            t`Claim all and Stake`
          )}
        </LoadingButton>
      </Box> */}
      <Box
        sx={{
          width: "100%",
        }}
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {!!directBonds.length && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              mb: 2,
            }}
          >
            <CustormPagination
              siblingCount={isSmallScreen ? 0 : 1}
              boundaryCount={1}
              count={directBondCount}
              onChange={directBondChangePage}
            />
          </Box>
        )}

        {/* {numberOfDirectBonds > 0 && (
          <Box
            sx={{
              width: { xs: "100%", sm: "290px" },
              borderRadius: "6px",
              height: "44px",
              border: "1px solid #000",
              background: "#FCD000",
              boxShadow: "-3px 3px 0px 0px #ECAA00",
              mb: "10px",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <LoadingButton
              variant="contained"
              disabled={
                pendingClaim() ||
                isClaimAllNumber <= 0 ||
                isPendingTxn(pendingTransactions, "redeem_bond")
              }
              loading={
                pendingClaim() ||
                isPendingTxn(pendingTransactions, "redeem_bond")
              }
              loadingPosition="end"
              onClick={() => {
                onRedeemAll({ autostake: true });
              }}
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent",
                color: "#000",
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              {txnButtonTextGeneralPending(
                pendingTransactions,
                "redeem_all_bonds",
                t`Claim all `
              )}
            </LoadingButton>
          </Box>
        )} */}
      </Box>
    </Box>
  );
}

export function ClaimBonds({ activeBonds, count, changePage }) {
  const { isSmallScreen } = useMobile();

  let isClaimAllNumber = 0;
  activeBonds.map((_) => {
    isClaimAllNumber += Number(_.pendingPayout);
  });
  const [activeBond, setActiveBond] = useState(0);
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const { bonds } = useBonds(chainID);
  const [numberOfBonds, setNumberOfBonds] = useState(0);
  // const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query
  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
      isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
    ) {
      return true;
    }

    return false;
  };

  const onRedeemAll = async ({ autostake }) => {
    // console.log("redeeming all bonds");

    await dispatch(
      redeemAllBonds({
        address,
        bonds,
        networkID: chainID,
        provider,
        autostake,
      })
    );

    // console.log("redeem all complete");
  };

  useEffect(() => {
    let bondCount = Object.keys(activeBonds).length;
    setNumberOfBonds(bondCount);
  }, [activeBonds]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems={"center"}
      justifyContent={"space-between"}
      flexDirection={"column"}
    >
      {/* pc view */}
      <TableContainer
        sx={{
          display: { xs: "none", sm: "initial" },
          minHeight: "330px",
        }}
      >
        <Table aria-label="Claimable bonds">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{
                  color: "#868B8F",
                  fontSize: "16px",
                  fontWeight: "400",
                  borderBottom: "none",
                }}
              >
                <Trans>Bond</Trans>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "#868B8F",
                  fontSize: "16px",
                  fontWeight: "400",
                  borderBottom: "none",
                }}
              >
                <Trans>Claimable</Trans>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "#868B8F",
                  fontSize: "16px",
                  fontWeight: "400",
                  borderBottom: "none",
                }}
              >
                <Trans>Pending</Trans>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: "#868B8F",
                  fontSize: "16px",
                  fontWeight: "400",
                  borderBottom: "none",
                }}
              >
                <Trans>Fully Vested</Trans>
              </TableCell>
              <TableCell
                align="right"
                sx={{ borderBottom: "none" }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {numberOfBonds > 0
              ? Object.entries(activeBonds).map((bond, i) => (
                  <ClaimBondTableData
                    key={i}
                    userBond={bond}
                    isInvite={false}
                  />
                ))
              : !isSmallScreen && (
                  <TableRow>
                    <TableCell
                      colSpan={5}
                      sx={{
                        height: "300px",
                        borderBottom: "none",
                      }}
                    >
                      <Box
                        sx={{ width: "100%", height: "100%" }}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <img src={NoData} alt="No Data" />
                        <Typography
                          sx={{
                            pt: 2,
                            color: "#C7C8CC",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                          }}
                        >
                          <Trans>No data</Trans>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          width: "100%",
          display: {
            xs: "initial",
            sm: "none",
          },
        }}
      >
        {Object.entries(activeBonds).length > 0 ? (
          Object.entries(activeBonds).map((bond, i) => (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <ClaimBondCardData key={i} userBond={bond} />
            </Box>
          ))
        ) : (
          <Box
            sx={{ width: "100%", height: "330px" }}
            display="flex"
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <img src={NoData} alt="No Data" />
            <Typography
              sx={{
                pt: 2,
                color: "#C7C8CC",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              <Trans>No data</Trans>
            </Typography>
          </Box>
        )}
      </Box>
      {!!activeBonds.length && (
        <Box
          sx={{
            width: "100%",
          }}
          display="flex"
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {/* {!!activeBonds.length && ( */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              mb: 2,
            }}
          >
            <CustormPagination
              siblingCount={isSmallScreen ? 0 : 1}
              boundaryCount={1}
              count={count}
              onChange={changePage}
            />
          </Box>
          {/* )} */}

          {numberOfBonds > 0 && (
            <Box
              sx={{
                width: { xs: "100%", sm: "290px" },
                borderRadius: "6px",
                height: "44px",
                border: "1px solid #000",
                background: "#FCD000",
                boxShadow: "-3px 3px 0px 0px #ECAA00",
                mb: "10px",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  pendingClaim() ||
                  isClaimAllNumber <= 0 ||
                  isPendingTxn(pendingTransactions, "redeem_bond")
                }
                loading={
                  pendingClaim() ||
                  isPendingTxn(pendingTransactions, "redeem_bond")
                }
                loadingPosition="end"
                onClick={() => {
                  onRedeemAll({ autostake: true });
                }}
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "transparent",
                  color: "#000",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                {txnButtonTextGeneralPending(
                  pendingTransactions,
                  "redeem_all_bonds",
                  t`Claim all and Stake `
                )}
              </LoadingButton>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default ClaimBonds;
