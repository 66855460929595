import { Trans } from "@lingui/macro";
import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import Bg from "../../assets/images/map-bg.png";
import Line1 from "../../assets/images/map-bg-1.png";
import Line2 from "../../assets/images/map-bg-2.png";

import "./map.scss";
import MapLoan from "./MapLoan";
import MapRwa from "./MapRwa";
import MapUniverse from "./MapUniverse";
import MapModular from "./MapModular";
import { useMobile } from "../../hooks";

const Map = () => {
  const isSmallScreen = useMobile();

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={"column"}
      sx={{
        width: "100%",
        mb: {
          xs: "40px",
          md: "100px",
        },
      }}
    >
      <Box
        sx={{
          maxWidth: "1304px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
        }}
      >
        {/* title */}
        <Box>
          <Typography
            sx={{
              color: "#fff",
              fontSize: { xs: "24px", sm: "39.435px" },
              fontWeight: "700",
              "& > span": {
                color: "#fcd000",
                mx: 0.75,
              },
            }}
            align="center"
          >
            <Trans>NovaBank </Trans>
            <span>
              <Trans>Ecological</Trans>
            </span>
            <Trans>Layout Map</Trans>
          </Typography>
        </Box>
        {/* content */}
        <Box
          sx={{
            width: { xs: "95%", sm: "100%" },
            // height: "987px",
            // maxHeight: "987px",
            // minHeight: "400px",
            position: "relative",
            zIndex: "2",
            // backgroundImage: `${Bg}`,
          }}
        >
          {/* bg */}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              // background: `url(${Bg}) no-repeat`,
              // backgroundPosition: "center center",
              // bac
              // width: { md: "827px", sm: "280px" },
              // height: { md: "827px", sm: "280px" },
              position: "relative",
              left: "50%",
              top: "30px",
              transform: "translate(-50%)",
              "&>img": {
                width: "100%",
                // height: "100%",
              },
            }}
          >
            <img src={Bg} />
          </Box>
          {/* rwa */}
          <MapRwa />
          {/* loan */}
          <MapLoan />
          {/* universe */}
          <MapUniverse />
          {/* modular */}
          <MapModular />
          {/* <Box
            sx={{
              width: "80%",
              position: "absolute",
              left: "50%",
              top: "30px",
              transform: "translate(-50%)",
              zIndex: "1",
              "& img": {
                width: "100%",
                height: "100%",
              },
            }}
          >
            <Box className="map-line1">
              <img src={Line1} />
            </Box>
          </Box>
          <Box
            sx={{
              width: "80%",
              position: "absolute",
              left: "50%",
              top: "30px",
              transform: "translate(-50%)",
              zIndex: "1",
              "& img": {
                width: "100%",
                height: "100%",
              },
            }}
          >
            <Box className="map-line1">
              <img src={Line2} />
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Map;
