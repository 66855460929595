import { Trans, t } from "@lingui/macro";
import {
  Box,
  Button,
  IconButton,
  Modal,
  OutlinedInput,
  Skeleton,
  Slider,
  styled,
  SvgIcon,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector, useMobile } from "src/hooks";
import { ReactComponent as XIcon } from "src/assets/icons/x.svg";
import { customNumberFormat, toolNumber, trim } from "src/helpers";
let debounceTimeout: NodeJS.Timeout;
const activeBtns = [7, 30, 180, 365];

const CustomSlider = styled(Slider)(({ theme }) => ({
  height: "12px !important",
  borderRadius: "100px !important",
  padding: "1px 2px 2px !important",
  "& > .MuiSlider-track": {
    background: "#FCD000 !important",
    border: "none",
  },
  "& > .MuiSlider-thumb": {
    width: "28px",
    height: "28px",
    color: "#3B3100 !important",
    border: "10px solid #FFE569",
    padding: "7px",
    "&:hover": {
      boxShadow: "4px 6px 20px rgba(0, 0, 0, 0.35) !important",
    },
    "&.Mui-focusVisible": {
      boxShadow: "4px 6px 20px rgba(0, 0, 0, 0.35) !important",
    },
  },
  "& .MuiSlider-rail": {
    color: "#202226 !important",
  },
}));

const CalculatorModal = ({
  open,
  handleClose,
  stakingRebasePercentage,
}: any) => {
  const { isSmallScreen } = useMobile();
  const [calcValue, setCalcValue] = useState("");
  const [activeBtn, setActiveBtn] = useState(0);
  const [sliderValue, setSliderValue] = useState(stakingRebasePercentage);
  const [calTokenBal, setCalTokenBal] = useState<number | string>(0);
  const [calTokenPrice, setCalTokenPrice] = useState<number | string>(0);

  useEffect(() => {
    if (stakingRebasePercentage) {
      setSliderValue(stakingRebasePercentage);
    }
  }, [stakingRebasePercentage]);

  const marketPrice = useAppSelector((state) => {
    return state.app.marketPrice;
  });

  const calculateValue = (_value: any, activeType: number) => {
    let value = _value.replace(/[^\d.]/g, "");
    if (value < 0) {
      setCalcValue("");
      return;
    }
    setCalcValue(value);

    const _tokenBal =
      Math.pow(
        1 + Number(sliderValue) / 100,
        3 * activeBtns[typeof activeType === "number" ? activeType : activeBtn]
      ) * value;
    // console.log(
    //   "_tokenBal",
    //   trim(toolNumber(_tokenBal), 4),
    //   toolNumber(toolNumber(_tokenBal) * Number(trim(marketPrice, 8)))
    // );
    setCalTokenBal(_tokenBal as number);
    console.log("price", toolNumber(_tokenBal) * Number(trim(marketPrice, 8)));
    marketPrice && setCalTokenPrice(_tokenBal * marketPrice);
  };

  const sliderChange = (value: any) => {
    setSliderValue(value);
    calculateValue(calcValue, activeBtn);
  };

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        px: isSmallScreen ? 1.875 : 0,
      }}
      onClose={handleClose}
    >
      <Box
        sx={{
          width: { xs: "345px", sm: "835px" },
          height: { xs: "auto", sm: "520px" },
          borderRadius: { xs: "6px", sm: "10px" },
          background: "#151515",
          px: { xs: "15px", sm: "30px" },
          py: 2.5,
        }}
      >
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{
            mb: { xs: "24px", sm: "30px" },
          }}
        >
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: { xs: "18px", sm: "24px" },
              fontWeight: "600",
            }}
            align="center"
          >
            <Trans>Calculator</Trans>
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              p: 0,
            }}
          >
            <SvgIcon sx={{ color: "#EAECEF" }} component={XIcon} />
          </IconButton>
        </Box>
        <Box
          sx={{
            mb: "16px",
          }}
        >
          <Box
            sx={{
              mb: 2,
            }}
          >
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: { xs: "16px", sm: "18px" },
                fontWeight: "400",
              }}
            >
              <Trans>Estimated Return</Trans>
            </Typography>
          </Box>
          <OutlinedInput
            sx={{
              width: "100%",
              borderRadius: "6px",
              border: "1px solid #2D2D2D",
              background: "#0C0C0C",
              height: "44px",
              color: "#EAECEF",
              "&>input": {
                height: "100%",
                padding: "0 18px",
              },
            }}
            value={calcValue}
            placeholder={t`Enter the amount of $NVB`}
            onChange={(e) => calculateValue(e.target.value, activeBtn)}
          />
        </Box>
        {/* select buttons */}
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            mb: { xs: "30px", sm: "50px" },
            height: "40px",
            borderRadius: "6px",
            border: "1px solid #2D2D2D",
            background: "#0c0c0c",
            p: 0.75,
          }}
        >
          <Button
            variant={activeBtn == 0 ? "contained" : "text"}
            sx={{
              width: "100%",
              height: "100%",
              background: activeBtn == 0 ? "#FCD000" : "transparent",
              borderRadius: activeBtn == 0 ? "4px" : "none",
              "&:hover": {
                background: activeBtn == 0 ? "#FCD000" : "transparent",
              },
            }}
            onClick={() => {
              setActiveBtn(0);
              calculateValue(calcValue, 0);
            }}
          >
            <Typography
              sx={{
                color: activeBtn == 0 ? "#0C0C0C" : "#EAECEF",
                fontSize: { sm: "16px", xs: "12px" },
                fontWeight: "400",
              }}
            >
              <Trans>7 Days</Trans>
            </Typography>
          </Button>
          <Button
            variant={activeBtn == 1 ? "contained" : "text"}
            sx={{
              width: "100%",
              height: "100%",
              background: activeBtn == 1 ? "#FCD000" : "transparent",
              borderRadius: activeBtn == 1 ? "4px" : "none",
              "&:hover": {
                background: activeBtn == 1 ? "#FCD000" : "transparent",
              },
            }}
            onClick={() => {
              setActiveBtn(1);
              calculateValue(calcValue, 1);
            }}
          >
            <Typography
              sx={{
                color: activeBtn == 1 ? "#0C0C0C" : "#EAECEF",
                fontSize: { sm: "16px", xs: "12px" },
                fontWeight: "400",
              }}
            >
              <Trans>1 Month</Trans>
            </Typography>
          </Button>
          <Button
            variant={activeBtn == 2 ? "contained" : "text"}
            sx={{
              width: "100%",
              height: "100%",
              background: activeBtn == 2 ? "#FCD000" : "transparent",
              borderRadius: activeBtn == 2 ? "4px" : "none",
              "&:hover": {
                background: activeBtn == 2 ? "#FCD000" : "transparent",
              },
            }}
            onClick={() => {
              setActiveBtn(2);
              calculateValue(calcValue, 2);
            }}
          >
            <Typography
              sx={{
                color: activeBtn == 2 ? "#0C0C0C" : "#EAECEF",
                fontSize: { sm: "16px", xs: "12px" },
                fontWeight: "400",
              }}
            >
              <Trans>6 Month</Trans>
            </Typography>
          </Button>
          <Button
            variant={activeBtn == 3 ? "contained" : "text"}
            sx={{
              width: "100%",
              height: "100%",
              background: activeBtn == 3 ? "#FCD000" : "transparent",
              borderRadius: activeBtn == 3 ? "4px" : "none",
              "&:hover": {
                background: activeBtn == 3 ? "#FCD000" : "transparent",
              },
            }}
            onClick={() => {
              setActiveBtn(3);
              calculateValue(calcValue, 3);
            }}
          >
            <Typography
              sx={{
                color: activeBtn == 3 ? "#0C0C0C" : "#EAECEF",
                fontSize: { sm: "16px", xs: "12px" },
                fontWeight: "400",
              }}
            >
              <Trans>1 Year</Trans>
            </Typography>
          </Button>
        </Box>
        {/* Next Reward Yield */}
        <Box>
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: { xs: "18px", sm: "24px" },
              fontWeight: "600",
              mb: {
                xs: "18px",
                sm: "27px",
              },
            }}
          >
            <Trans>Next Reward Yield</Trans>
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={isSmallScreen ? "column" : "row"}
            sx={{
              mb: 2,
            }}
          >
            {Number(stakingRebasePercentage) <= 0 ? (
              <Typography>
                <Skeleton width="200px" />
              </Typography>
            ) : (
              <>
                <CustomSlider
                  step={0.0001}
                  min={0.0001}
                  max={2}
                  defaultValue={Number(stakingRebasePercentage)}
                  aria-label="Default"
                  onChange={(e: any, value: any) => sliderChange(value)}
                  sx={{
                    mb: isSmallScreen ? 2.5 : 0,
                    mr: isSmallScreen ? 0 : 2.875,
                  }}
                />
                <Box
                  sx={{
                    width: isSmallScreen ? "100%" : "auto",
                    px: isSmallScreen ? 1.25 : 2,
                    height: "44px",
                    borderRadius: "6px",
                    py: 1.25,
                    border: "1px solid #000",
                    mb: isSmallScreen ? 2.5 : 0,
                    background: "#2d2d2d",
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    sx={{
                      color: "#EAECEF",
                      fontSize: "16px",
                      fontWeight: "400",
                    }}
                    align="center"
                  >
                    {`${sliderValue}%`}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "44px",
              borderRadius: "6px",
              border: "1px solid #000",
              background: "#2d2d2d",
              px: "18px",
              mb: 2,
            }}
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "16px",
                wordBreak: "break-all",
                fontWeight: 400,
              }}
            >
              <Trans>NVB Balance</Trans>
            </Typography>
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "16px",
                wordBreak: "break-all",
                fontWeight: 400,
              }}
              align="right"
            >
              {customNumberFormat(Number(calTokenBal), 2)}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "44px",
              borderRadius: "6px",
              border: "1px solid #000",
              background: "#2d2d2d",
              px: "18px",
            }}
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "16px",
                wordBreak: "break-all",
                fontWeight: 400,
              }}
            >
              <Trans>Total USD</Trans>
            </Typography>
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "16px",
                wordBreak: "break-all",
                fontWeight: 400,
              }}
              align="right"
            >
              ${customNumberFormat(Number(calTokenPrice), 2)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CalculatorModal;
