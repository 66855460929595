import { useState } from "react";
import { ReactComponent as Info } from "../../assets/icons/info.svg";
import { ReactComponent as Tip } from "../../assets/icons/tip.svg";
import { SvgIcon, Paper, Typography, Box, Popper } from "@mui/material";
import "./infotooltip.scss";
import { useMobile } from "src/hooks";

function InfoTooltip({ message }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isSmallScreen } = useMobile();
  const handleHover = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "info-tooltip" : undefined;

  return (
    <Box
      style={{
        display: "inline-flex",
        justifyContent: "center",
        alignSelf: "center",
      }}
    >
      <SvgIcon
        // component={Tip}
        component={Info}
        onMouseOver={handleHover}
        onMouseOut={handleHover}
        // onClick={handleHover}
        style={{ margin: "0 5px", width: "20px", height: "20px" }}
        className="info-icon"
        viewBox="0 0 20 20"
      ></SvgIcon>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom"}
        className="tooltip"
      >
        {/* <Paper className="info-tooltip ohm-card"> */}
        <Box
          sx={{
            maxWidth: { xs: "240px", sm: "320px" },
            width: "100%",
            p: "10px",
            borderRadius: "4px",
            border: "1px solid #2d2d2d",
            background: "#202020",
            boxShadow:
              "0px 49px 14px 0px rgba(0, 0, 0, 0.01), 0px 31px 13px 0px rgba(0, 0, 0, 0.09), 0px 18px 11px 0px rgba(0, 0, 0, 0.30), 0px 8px 8px 0px rgba(0, 0, 0, 0.51), 0px 2px 4px 0px rgba(0, 0, 0, 0.59)",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: "12px",
              fontWeight: "400",
            }}
          >
            {message}
          </Typography>
        </Box>
        {/* </Paper> */}
      </Popper>
    </Box>
  );
}

export default InfoTooltip;
