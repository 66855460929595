import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import BorderBg from "../../assets/icons/border.svg";
import TenBg from "../../assets/icons/ten.svg";
import BorderLineBg from "../../assets/icons/border-line.svg";
import BorderLineBgMobile from "../../assets/icons/border-line-m.svg";
import { Trans } from "@lingui/macro";
import MenuItemIcon from "../../assets/icons/menu-item.svg";
import Card1 from "../../assets/images/card-1.png";
import Card2 from "../../assets/images/card-2.png";
import Card3 from "../../assets/images/card-3.png";
import HighlightsBg from "../../assets/images/highlights-bg.png";
import BorderBgMobile from "../../assets/icons/border-m.svg";
import { useMobile } from "../../hooks";

const CardList = () => {
  const isSmallScreen = useMobile();

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={"column"}
      sx={{
        width: "100%",
        px: {
          xs: "15px",
          sm: "0",
        },
        mb: {
          xs: "40px",
          md: "100px",
        },
      }}
    >
      <Box
        sx={{
          maxWidth: "1340px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
          minHeight: "600px",
        }}
      >
        <Grid container>
          {/* 1 */}
          <Grid item sm={12} md={6}>
            <Box
              sx={{
                width: "100%",
                maxHeight: "600px",
                minHeight: "526px",
                height: "100%",
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: "1px 100%, 100% 1px, 1px 100%, 100% 1px",
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
                borderRadius: { xs: "4px 4px 0 0", sm: "10px 0 0 0" },
                // overflow: "hidden",
                position: "relative",
                p: { md: "60px 40px", xs: "25px" },
              }}
            >
              {/* content */}
              <Box>
                <Box
                  sx={{
                    mb: { md: "50px", xs: "20px" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "30px", sm: "40px" },
                      fontWeight: "600",
                      mb: 1,
                    }}
                  >
                    <Trans>NovaBank RWA</Trans>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#fcd000",
                      fontSize: { xs: "14px", sm: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    <Trans>Blockchain RWA combined with Nova Bank for decentralized liquidity and stability</Trans>
                  </Typography>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "& > span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Liquidity guarantees:</Trans>
                      <span>
                        <Trans> POL mechanisms to support liquidity</Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "& > span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Price stabilization:</Trans>
                      <span>
                        <Trans>RBS provides a price stabilization framework</Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "& > span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Long-term value:</Trans>
                      <span>
                        <Trans>Reserve asset backing</Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Box
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "16px", sm: "18px" },
                          fontWeight: "700",
                          color: "#fff",
                          "&>span": {
                            fontWeight: "400",
                            ml: 0.5,
                          },
                        }}
                        component={"span"}
                      >
                        <Trans>Efficient use of capital:</Trans>
                        <span>
                          <Trans>Rapid provisioning of automated contracts</Trans>
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* top left bg */}
              <Box
                sx={{
                  width: { xs: "12px", sm: "26px" },
                  height: { xs: "12px", sm: "26px" },
                  position: "absolute",
                  zIndex: 0,
                  top: { sm: "-1px", xs: "-6px" },
                  left: { sm: "-1px", xs: "0px" },
                  borderRadius: "10px 0 0 0",
                  // overflow: "hidden",
                  // rotate: "90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={isSmallScreen ? BorderBgMobile : BorderBg} />
              </Box>
              {/* mobile right top bg */}
              <Box
                sx={{
                  display: { sm: "none" },
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  zIndex: 0,
                  right: "0",
                  top: "-6px",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={BorderLineBgMobile} />
              </Box>
              {/* top center bg */}
              <Box
                sx={{
                  width: { xs: "72px", sm: "189px" },
                  height: "4px",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                  position: "absolute",
                  left: "50%",
                  top: "-2px",
                  transform: "translate( -50%)",
                }}
              />
              {/* bottom right icon */}
              <Box
                sx={{
                  display: { xs: "none", sm: "initial" },
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 1,
                  right: "-20.5px",
                  bottom: "-20.5px",
                  // rotate: "90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={TenBg} />
              </Box>
            </Box>
          </Grid>
          {/* 2 */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: { xs: "336px", sm: "600px" },
                borderTop: { xs: "none", sm: "1px solid #FCD000" },
                borderLeft: { xs: "1px solid #FCD000", sm: "none" },
                borderRight: "1px solid #FCD000",
                borderRadius: { xs: "none", sm: "0 10px 0 0" },
                background: "#000",
                position: "relative",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: { md: "672px", xs: "345px" },
                  height: { md: "542px", xs: "277px" },
                  // width: { md: "582px", xs: "300px" },
                  // height: { md: "555px", xs: "286px" },
                  position: "relative",
                  zIndex: "1",
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={Card1} />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: {
                    md: "514px",
                    sm: "264px",
                  },
                  position: "absolute",
                  left: "0",
                  top: "0",
                  zIndex: 0,
                  "& > img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={HighlightsBg} />
              </Box>
            </Box>
          </Grid>
          {/* 3 */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", sm: "initial" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "336px", sm: "600px" },
                borderLeft: "1px solid #FCD000",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "#000",
                  position: "relative",
                }}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    width: { md: "672px", xs: "345px" },
                    height: { md: "542px", xs: "277px" },
                    position: "relative",
                    zIndex: "1",
                    "& > img": {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <img src={Card2} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: {
                      md: "514px",
                      sm: "264px",
                    },
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: 0,
                    "& > img": {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <img src={HighlightsBg} />
                </Box>
              </Box>
              {/* bottom right icon */}
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 1,
                  right: "-20.5px",
                  bottom: "-20.5px",
                  // rotate: "90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={TenBg} />
              </Box>
            </Box>
          </Grid>
          {/* 4 */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: "600px",
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: "1px 100%, 100% 1px, 1px 100%, 100% 1px",
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
                position: "relative",
                p: { md: "60px 40px", xs: "25px" },
              }}
            >
              {/* content */}
              <Box>
                <Box
                  sx={{
                    mb: { md: "50px", xs: "20px" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "30px", sm: "40px" },
                      fontWeight: "600",
                      mb: 1,
                    }}
                  >
                    <Trans>NovaBank Metaverse</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fcd000",
                      fontSize: { xs: "14px", sm: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    <Trans>Blockchain Metaverse combines with Nova Bank to create a decentralized economy</Trans>
                  </Typography>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "&>span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Virtual land lending:</Trans>
                      <span>
                        <Trans>Land mortgaging for liquidity </Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "&>span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Decentralized Store:</Trans>
                      <span>
                        <Trans>NVB Supports Virtual Stores</Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "&>span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Identity management:</Trans>
                      <span>
                        <Trans> Decentralized authentication</Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "&>span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Liquidity Bonus: </Trans>
                      <span>
                        <Trans>Provides Liquidity to the metaverse market</Trans>
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* right center bg */}
              <Box
                sx={{
                  display: { xs: "none", sm: "initial" },
                  width: "4px",
                  height: "189px",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                  position: "absolute",
                  top: "50%",
                  right: "-2px",
                  transform: "translateY( -50%)",
                }}
              />
              {/* top center bg */}
              <Box
                sx={{
                  display: { xs: "initial", sm: "none" },
                  width: "72px",
                  height: "4px",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                  position: "absolute",
                  left: "50%",
                  top: "-2px",
                  transform: "translate( -50%)",
                }}
              />
            </Box>
          </Grid>
          {/* 3 mobile */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "initial", sm: "none" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "336px", sm: "600px" },
                borderLeft: "1px solid #FCD000",
                borderRight: "1px solid #FCD000",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "#000",
                  position: "relative",
                }}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    width: { md: "672px", xs: "345px" },
                    height: { md: "542px", xs: "277px" },
                    position: "relative",
                    zIndex: "1",
                    "& > img": {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <img src={Card2} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: {
                      md: "514px",
                      sm: "264px",
                    },
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: 0,
                    "& > img": {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <img src={HighlightsBg} />
                </Box>
              </Box>
              {/* bottom right icon */}
              <Box
                sx={{
                  display: { xs: "none", sx: "initial" },
                  width: "40px",
                  height: "40px",
                  position: "absolute",
                  zIndex: 1,
                  right: "-20.5px",
                  bottom: "-20.5px",
                  // rotate: "90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={TenBg} />
              </Box>
              {/* top center bg */}
              <Box
                sx={{
                  display: { xs: "initial", sm: "none" },
                  width: "72px",
                  height: "4px",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                  position: "absolute",
                  left: "50%",
                  top: "-2px",
                  transform: "translate( -50%)",
                }}
              />
            </Box>
          </Grid>
          {/* 5 */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: "600px",
                backgroundImage:
                  "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                backgroundSize: {
                  xs: "1px 100%, 100% 1px, 1px 100%, 100% 1px",
                  sm: "1px 100%, 100% 1px, 0 100%, 100% 1px",
                },
                backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                backgroundRepeat: "no-repeat",
                position: "relative",
                borderRadius: { xs: "none", sm: "0 0 0 10px" },
                p: { md: "60px 40px", xs: "25px" },
              }}
            >
              {/* content */}
              <Box>
                <Box
                  sx={{
                    mb: { md: "50px", xs: "20px" },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { xs: "30px", sm: "40px" },
                      fontWeight: "600",
                      mb: 1,
                    }}
                  >
                    <Trans>NovaBank Modular</Trans>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#fcd000",
                      fontSize: { xs: "14px", sm: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    <Trans>
                      Enhance the flexibility and stability of the financial system through decentralized liquidity,
                      smart contract automation and cross-chain asset management
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "& > span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Modular Lending:</Trans>
                      <span>
                        <Trans> Lntegrating Cooler Loans</Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "& > span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Pluggable Liquidity:</Trans>
                      <span>
                        <Trans> POL Managed Liquidity</Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                      backgroundImage:
                        "repeating-linear-gradient(0deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(90deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(180deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px), repeating-linear-gradient(270deg, #FCD000, #FCD000 2px, transparent 2px, transparent 12px, #FCD000 12px)",
                      backgroundSize: "0px 100%, 100% 0px, 0px 100%, 100% 1px",
                      backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", sm: "18px" },
                        fontWeight: "700",
                        color: "#fff",
                        "& > span": {
                          fontWeight: "400",
                          ml: 0.5,
                        },
                      }}
                    >
                      <Trans>Module Incentives:</Trans>
                      <span>
                        <Trans> Decentralized Incentives</Trans>
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{
                      py: 3,
                    }}
                  >
                    <Box
                      sx={{
                        width: "24px",
                        height: "24px",
                        mr: 1,
                        "& > img": {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                    >
                      <img src={MenuItemIcon} alt="" />
                    </Box>
                    <Box
                      sx={{
                        display: "inline-flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: { xs: "16px", sm: "18px" },
                          fontWeight: "700",
                          color: "#fff",
                          "&>span": {
                            fontWeight: "400",
                            ml: 0.5,
                          },
                        }}
                      >
                        <Trans>Automated Management:</Trans>
                        <span>
                          <Trans> Smart Contract Automation</Trans>
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* left bottom bg */}
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "initial",
                  },
                  width: { xs: "12px", sm: "26px" },
                  height: { xs: "12px", sm: "26px" },
                  position: "absolute",
                  zIndex: 0,
                  left: { xs: "0", sm: "-2px" },
                  bottom: { xs: "5px", sm: "-2px" },
                  rotate: { xs: "0deg", sm: "90deg" },
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={isSmallScreen ? BorderBgMobile : BorderBg} />
              </Box>
              {/* left center bg */}
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "initial",
                  },
                  width: "4px",
                  height: "189px",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                  position: "absolute",
                  top: "50%",
                  left: "-2px",
                  transform: "translateY( -50%)",
                }}
              />
              {/* top center bg */}
              <Box
                sx={{
                  display: { xs: "initial", sm: "none" },
                  width: "72px",
                  height: "4px",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                  position: "absolute",
                  left: "50%",
                  top: "-2px",
                  transform: "translate( -50%)",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                height: { xs: "336px", sm: "600px" },
                borderRight: "1px solid #FCD000",
                borderBottom: "1px solid #FCD000",
                borderLeft: { xs: "1px solid #fcd000" },
                position: "relative",
                borderRadius: { xs: "0 0 4px 4px", sm: "0 0 10px 0" },
              }}
            >
              {/* content */}
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "#000",
                  position: "relative",
                }}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box
                  sx={{
                    width: { md: "672px", xs: "345px" },
                    height: { md: "542px", xs: "277px" },
                    position: "relative",
                    zIndex: "1",
                    "& > img": {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <img src={Card3} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: {
                      md: "514px",
                      sm: "264px",
                    },
                    position: "absolute",
                    left: "0",
                    top: "0",
                    zIndex: 0,
                    "& > img": {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <img src={HighlightsBg} />
                </Box>
              </Box>
              {/* right center bg */}
              <Box
                sx={{
                  width: { xs: "12px", sm: "26px" },
                  height: { xs: "12px", sm: "26px" },
                  position: "absolute",
                  zIndex: 0,
                  right: { xs: "-6px", sm: "-1px" },
                  bottom: "-1px",
                  rotate: "90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={isSmallScreen ? BorderLineBgMobile : BorderLineBg} />
              </Box>
              {/* mobile left bottom bg */}
              <Box
                sx={{
                  display: {
                    // xs: "none",
                    sm: "none",
                  },
                  width: "12px",
                  height: "12px",
                  position: "absolute",
                  zIndex: 0,
                  left: "4px",
                  bottom: "-1px",
                  rotate: "90deg",
                  "&>img": {
                    width: "100%",
                    height: "100%",
                  },
                }}
              >
                <img src={BorderBgMobile} />
              </Box>
              {/* top center bg */}
              <Box
                sx={{
                  display: { xs: "initial", sm: "none" },
                  width: "72px",
                  height: "4px",
                  background: "#FFE360",
                  boxShadow: "0px 0px 4px 0px #FFE360",
                  position: "absolute",
                  left: "50%",
                  top: "-2px",
                  transform: "translate( -50%)",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CardList;
