import React, { useCallback, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  ButtonBase,
  InputBase,
  Popper,
  SvgIcon,
  Typography,
  Fade,
  Paper,
  Skeleton,
  Link,
} from "@mui/material";
import BuyTitle from "src/assets/images/buy-title.png";
import BuyTitleMobile from "src/assets/images/buy-title-m.png";
import { t, Trans } from "@lingui/macro";
import { ReactComponent as SettingsIcon } from "src/assets/icons/settings.svg";
import { ReactComponent as USDTIcon } from "src/assets/tokens/USDT.svg";
import { ReactComponent as OHMIcon } from "src/assets/tokens/OHM.svg";
import { ReactComponent as BNBIcon } from "src/assets/tokens/BNB.svg";
import { ReactComponent as AVCIcon } from "src/assets/tokens/AVC.svg";
import { ReactComponent as DownIcon } from "src/assets/icons/arrow-down.svg";
import { ReactComponent as ExchangeIcon } from "src/assets/icons/exchange.svg";
import { ReactComponent as DexscreenIcon } from "src/assets/icons/dexscreener.svg";
import { ReactComponent as ArrowRightIcon } from "src/assets/icons/arrow-right.svg";
import FooterBg from "../../assets/images/footer-bg.png";
import {
  approveSwap,
  clearAmount,
  getAmountsIn,
  getAmountsOut,
  getSelectToken,
  swapToken,
} from "src/slices/SwapThunk";
import { useUnmount } from "ahooks";
import { useAppSelector, useMobile, useWeb3Context } from "src/hooks";
import { customNumberFormat, trim } from "src/helpers";
import { getBalances, loadAccountDetails } from "src/slices/AccountSlice";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import AdvancedSettings from "./AdvancedSettings";
import { addresses } from "src/constants";

let debounceTimeout;
const slippage = 35;
const Swap = () => {
  const { isSmallScreen } = useMobile();
  const location = useLocation();
  const dispatch = useDispatch();
  const [topIcon, setTopIcon] = useState(USDTIcon);
  const [topToken, setTopToken] = useState("USDT");
  const [bottomIcon, setBottomIcon] = useState(OHMIcon);
  const [bottomToken, setBottomToken] = useState("NVB");
  const [amountIn, setAmountIn] = useState("");
  const [amountOut, setAmountOut] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inOrOut, setInorOut] = useState("");
  const [deadline, setDeadline] = useState(5);
  const [isBtn, setIsBtn] = useState(localStorage.getItem("isBtn") || "btn");
  const [slippage, setSlippage] = useState(
    localStorage.getItem("slippage") || 6
  );

  const { provider, address, connected, connect, chainID } = useWeb3Context();

  const topBal = useAppSelector((state) => state.swap.topBal);
  const bottomBal = useAppSelector((state) => state.swap.bottomBal);
  const amountsOut = useAppSelector((state) => state.swap.amountsOut);
  const amountsIn = useAppSelector((state) => state.swap.amountsIn);

  const isAppLoading = useAppSelector((state) => state.app.loading);

  const ohmBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.ohm;
  });
  const usdtBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.dai;
  });
  const OHMAllowanceInRouter = useAppSelector((state) => {
    return state.account.ohmAllowanceInRouter;
  });
  const wbnbAllowanceInRouter = useAppSelector((state) => {
    return state.account.wbnbAllowanceInRouter;
  });
  const usdtAllowanceInRouter = useAppSelector((state) => {
    return state.account.usdtAllowanceInRouter;
  });
  const pendingTransactions = useAppSelector((state) => {
    return state.pendingTransactions;
  });
  const handleClick = (event) => {
    // setOpen(true);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useUnmount(() => {
    dispatch(clearAmount());
  });

  useEffect(() => {
    dispatch(loadAccountDetails({ address, provider, networkID: chainID }));
    if (address && provider) {
      console.log("topToken", topToken);
      dispatch(
        getSelectToken({
          provider,
          address,
          networkID: chainID,
          topToken,
          bottomToken,
        })
      );
    }
  }, [isAppLoading, bottomToken, topToken, address]);

  useEffect(() => {
    setAmountOut(trim(amountsOut, 8));
  }, [amountsOut, slippage]);

  useEffect(() => {
    console.log("amountsIn", amountsIn);
    setAmountIn(trim(amountsIn, 4));
  }, [amountsIn]);
  const onDeadlineChange = (e) => {
    localStorage.setItem("deadline", e.target.value);
    return setDeadline(e.target.value);
  };

  const onSlippageChange = (value, isBtn) => {
    if (isBtn == "ipt") {
      if (value == "0.1" || value == "0.5" || value == "1.0") {
        setIsBtn("btn");
        setSlippage(value);
        localStorage.setItem("slippage", value);
        localStorage.setItem("isBtn", "btn");
        return;
      } else {
        localStorage.setItem("isBtn", isBtn);
        localStorage.setItem("slippage", value);

        setIsBtn(isBtn);
        return setSlippage(value);
      }
    } else {
      localStorage.setItem("isBtn", isBtn);
      localStorage.setItem("slippage", value);

      setIsBtn(isBtn);
      return setSlippage(value);
    }
  };

  const onSlippageBlur = (value) => {
    if (value <= 0) {
      setIsBtn("btn");
      setSlippage("0.1");
      localStorage.setItem("slippage", "0.1");
      localStorage.setItem("isBtn", "btn");
    }
  };

  const onDeadlineBlur = (e) => {
    // console.log(e.target.value);
    if (e.target.value <= 0 || !e.target.value) {
      setDeadline(5);
      localStorage.setItem("deadline", "5");
    }
  };

  const setMax = () => {
    if (view === 0) {
      setQuantity(Number(LGNSBalance));
    } else {
      setQuantity(Number(sLGNSBalance));
    }
  };

  const hasAllowance = useCallback(
    (token) => {
      console.log("token", token, usdtAllowanceInRouter, wbnbAllowanceInRouter);
      if (token === "NVB") return Number(OHMAllowanceInRouter);
      if (token === "USDT") return Number(usdtAllowanceInRouter);
      if (token === "WBNB") return Number(wbnbAllowanceInRouter);

      return 0;
    },
    [
      OHMAllowanceInRouter,
      usdtAllowanceInRouter,
      wbnbAllowanceInRouter,
      connected,
      provider,
      address,
    ]
  );

  const approveForSwap = async () => {
    // console.log("approve");
    await dispatch(
      approveSwap({
        address,
        provider,
        networkID: chainID,
        type: topToken == "USDT",
        topToken,
        bottomToken,
      })
    );
    await Promise.all([
      dispatch(getBalances({ address, provider, networkID: chainID })),
      dispatch(loadAccountDetails({ address, provider, networkID: chainID })),
    ]);
  };

  const exchangeTokenForToken = async () => {
    if (!amountIn || !amountOut) {
      setAmountOut("");
      setAmountIn("");
      return;
    }
    await dispatch(
      swapToken({
        address,
        provider,
        networkID: chainID,
        amountsIn: amountIn,
        amountsOut: amountOut,
        // type: topToken == "BUSD",
        topToken,
        bottomToken,
      })
    );
    // dispatch(getBalances({ address, provider, networkID: chainID }));
    setAmountOut("");
    setAmountIn("");
    dispatch(
      getSelectToken({
        address,
        provider,
        networkID: chainID,
        topToken,
        bottomToken,
      })
    );
    // setTopBal();
    // setBottomBal();
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const handleOpen = () => {
    setModalOpen(true);
  };

  const exchangeToken = () => {
    console.log("exchangeToken", {
      bottomToken,
      topToken,
      amountIn,
      amountOut,
    });
    setTopToken(bottomToken);
    setBottomToken(topToken);
    setAmountIn(amountOut);
    setAmountOut(amountIn);
    setTopIcon(bottomIcon);
    setBottomIcon(topIcon);
    dispatch(
      getSelectToken({
        address,
        provider,
        networkID: chainID,
        topToken: bottomToken,
        bottomToken: topToken,
      })
    );
  };

  // useDebounceEffect(
  //   () => {
  // useEffect(
  //   () => {
  //     // console.log(amountOut, "amountOut", amountsOut);
  //     if (!!amountIn && !!amountOut && !isChanged) {
  //       const tempPrice = !top2Bottom
  //         ? amountOut / amountIn
  //         : amountIn / amountOut;
  //       setPrice(trim(tempPrice, 4));
  //       const tempFee = (amountIn * 2.5) / 1000;
  //       setFee(trim(tempFee, 2));
  //     }
  //   },
  //   [amountOut, amountIn, top2Bottom, isChanged]
  //   // { wait: 100 },
  // );

  const handleOpenTokenList = (event, type) => {
    if (!connected) connect();
    else {
      // if (busdBalance && LGNSBalance) {
      setInorOut(type);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const selectToken = (token, tokenIcon, bal, type) => {
    setAmountIn("");
    setAmountOut("");
    setAnchorEl(null);

    if (type == "in") {
      if (token == bottomToken) {
        exchangeToken();
        return;
      }
      setTopToken(token);
      setTopIcon(tokenIcon);
      // setTopBal(bal);
      dispatch(
        getSelectToken({
          address,
          provider,
          networkID: chainID,
          topToken: token,
          bottomToken,
        })
      );
    } else {
      if (token == topToken) {
        exchangeToken();
        return;
      }
      setBottomToken(token);
      setBottomIcon(tokenIcon);
      dispatch(
        getSelectToken({
          address,
          provider,
          networkID: chainID,
          topToken,
          bottomToken: token,
        })
      );
    }
  };

  const onChangeTop = useCallback(
    (value) => {
      console.log("val", value);
      let val = value.replace(/[^\d.]/g, "");

      if (!val) {
        setAmountIn("");
        setAmountOut("");
        dispatch(clearAmount());
        clearTimeout(debounceTimeout);

        // dispatch(
        //   getAmountsOut({
        //     address,
        //     provider,
        //     networkID: chainID,
        //     amountsIn: "0",
        //     type: topToken != "NVB",
        //     topToken,
        //     bottomToken,
        //   })
        // );
        return;
      }
      setAmountIn(val);

      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      debounceTimeout = setTimeout(() => {
        console.log("burn", value, topToken, bottomToken);
        dispatch(
          getAmountsOut({
            address,
            provider,
            networkID: chainID,
            amountsIn: val,
            type: topToken != "NVB",
            topToken,
            bottomToken,
          })
        );
      }, 500);
    },
    [provider, chainID, dispatch, topToken, bottomToken]
  );

  const onChangeBottom = useCallback(
    (value) => {
      console.log("val", value);
      let val = value.replace(/[^\d.]/g, "");

      if (!val) {
        setAmountIn("");
        setAmountOut("");
        dispatch(clearAmount());
        clearTimeout(debounceTimeout);

        return;
      }
      setAmountOut(val);

      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      debounceTimeout = setTimeout(() => {
        console.log("burn", value, topToken, bottomToken);
        dispatch(
          getAmountsIn({
            address,
            provider,
            networkID: chainID,
            amountsOut: val,
            topToken,
            bottomToken,
          })
        );
      }, 500);
    },
    [provider, chainID, dispatch, topToken, bottomToken]
  );

  const open = Boolean(anchorEl);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          pt: { sm: "88px", xs: "20px" },
          maxWidth: "1340px",
          minWidth: "345px",
          width: { xs: "100%", sm: "97%" },
          position: "relative",
          zIndex: "1",
        }}
      >
        <Box
          sx={{
            width: { xs: "125px", sm: "160px" },
            height: { xs: "37.2px", sm: "61.2px" },
            mb: { xs: "20px", sm: "60px" },
            px: { xs: "15px", sm: "0" },
            "&>img": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <img src={isSmallScreen ? BuyTitleMobile : BuyTitle} alt="buy" />
        </Box>

        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          sx={{
            width: "100%",
            px: { xs: "15px", sm: "0" },
            mb: { xs: "40px", sm: "100px" },
          }}
        >
          {/* dexscreen */}
          <Link
            target="_blank"
            href={`https://dexscreener.com/bsc/${addresses[chainID].OHM_ADDRESS}`}
            sx={{
              width: { xs: "100%", sm: "500px" },
            }}
          >
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                height: "68px",
                px: "20px",
                width: { xs: "100%", sm: "500px" },
                borderRadius: "10px",
                border: "1px solid #2D2D2D",
                background: "#151515",
                mb: { xs: "8px", sm: "14px" },
              }}
            >
              <Box display="flex" alignItems="center">
                <Box
                  sx={{
                    mr: "15px",
                  }}
                >
                  <SvgIcon
                    sx={{
                      width: "24px",
                      height: "24px",
                    }}
                    viewBox="0 0 28 34"
                    component={DexscreenIcon}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#848E9C",
                      fontSize: { xs: "12px", sm: "14px" },
                      fontWeight: "500",
                    }}
                  >
                    <Trans>Transmission to</Trans>
                  </Typography>
                  <Typography
                    sx={{
                      color: "#EAECEF",
                      fontSize: { xs: "16px", sm: "18px" },
                      fontWeight: "500",
                    }}
                  >
                    <Trans>Dexscreener</Trans>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  background: "#0c0c0c",
                  border: "1px solid #2D2D2D",
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <SvgIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                  }}
                  viewBox="0 0 16 16"
                  component={ArrowRightIcon}
                />
              </Box>
            </Box>
          </Link>

          {/* swap card */}

          <Box
            sx={{
              width: { xs: "100%", sm: "500px" },
              height: { xs: "auto", sm: "auto" },
              border: "1px solid #2d2d2d",
              background: "#151515",
              borderRadius: { xs: "6px", sm: "10px" },
              p: { sm: "24px", xs: "15px" },
            }}
          >
            <Box
              sx={{
                width: "100%",
                mb: "15px",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography
                sx={{
                  color: "#eaecef",
                  fontSize: { xs: "15px", sm: "20px" },
                  fontWeight: "500",
                }}
              >
                <Trans>NovaBank</Trans>
              </Typography>
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  cursor: "pointer",
                }}
                onClick={handleOpen}
              >
                <SvgIcon
                  component={SettingsIcon}
                  style={{
                    width: isSmallScreen ? "20px" : "24px",
                    height: isSmallScreen ? "20px" : "24px",
                  }}
                  viewBox="0 0 24 24"
                />
              </Box>
            </Box>
            {/* swap in */}
            <Box
              sx={{
                width: "100%",
                height: "120px",
                borderRadius: "6px",
                border: "1px solid #2d2d2d",
                background: "#0c0c0c",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  px: "14px",
                }}
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    color: "#eaecef",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <Trans>From</Trans>
                </Typography>
                <ButtonBase
                  style={{
                    color: "#FCD000",
                    fontSize: "14px",
                    fontWeight: "500",
                    // width: isSmallScreen ? "28px" : "38px",
                  }}
                  onClick={() =>
                    onChangeTop("" + Math.trunc(Number(topBal) * 10000) / 10000)
                  }
                >
                  <Typography align="right">
                    <Trans>Max</Trans>
                  </Typography>
                </ButtonBase>
              </Box>
              <Box
                sx={{
                  borderRadius: "10px 10px 0 0",
                  border: "1px solid #2d2d2d",
                  background: "#000",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  width: "100%",
                  height: "calc(100% - 40px)",
                  px: { xs: "10px", sm: "18px" },
                }}
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box
                  sx={{
                    width: { xs: "130px", sm: "147px" },
                    height: { xs: "47px", sm: "56px" },
                    borderRadius: "8px",
                    background: "#151515",
                    px: "12px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  // onClick={handleClick}
                  onClick={(e) => handleOpenTokenList(e, "in")}
                >
                  <SvgIcon
                    color="primary"
                    component={topIcon}
                    style={{
                      width: isSmallScreen ? "26px" : "32px",
                      height: isSmallScreen ? "26px" : "32px",
                      // marginRight: isSmallScreen ? "8px" : "8px",
                    }}
                    viewBox="0 0 32 32"
                  />
                  <Typography
                    sx={{
                      color: "#EAECEF",
                      fontSize: { xs: "18px", sm: "22px" },
                      fontWeight: "500",
                    }}
                  >
                    {topToken}
                  </Typography>
                  <SvgIcon
                    component={DownIcon}
                    viewBox="0 0 16 16"
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-end"}
                  sx={
                    {
                      // width: "100%",
                    }
                  }
                >
                  <InputBase
                    size="medium"
                    placeholder="0.0"
                    value={amountIn}
                    // autoComplete={undefined}
                    // onBlur={e => onBlurTop(e.target.value)}
                    // onChange={run}
                    onChange={(e) => onChangeTop(e.target.value)}
                    sx={{
                      color: "#fff",
                      textAlign: "right",
                      alignItems: "flex-end",
                      height: { xs: "47px", sm: "56px" },
                      fontSize: { xs: "20px", sm: "24px" },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right",
                        height: "100%",
                        padding: "0",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "30px",
                width: "100%",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Box display="flex" alignItems={"center"}>
                <Typography
                  sx={{
                    color: "#eaecef",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <Trans>Balance</Trans>:
                </Typography>
                <Typography
                  sx={{
                    color: "#eaecef",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {isAppLoading ? (
                    <Skeleton width="100px" />
                  ) : (
                    customNumberFormat(Number(topBal), 4)
                  )}
                </Typography>
              </Box>
            </Box>
            {/* exchange icon */}
            <Box
              sx={{
                height: "60px",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  background: "#0c0c0c",
                  border: "1px solid #2d2d2d",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                display="flex"
                alignItems={"center"}
                justifyContent={"center"}
                onClick={exchangeToken}
              >
                <SvgIcon
                  component={ExchangeIcon}
                  style={{
                    width: "16px",
                    height: "16px",
                    // marginRight: isSmallScreen ? "8px" : "8px",
                  }}
                  viewBox="0 0 16 16"
                />
              </Box>
            </Box>
            {/* swap out */}
            <Box
              sx={{
                width: "100%",
                height: "120px",
                borderRadius: "6px",
                border: "1px solid #2d2d2d",
                background: "#0c0c0c",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "40px",
                  px: "14px",
                }}
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography
                  sx={{
                    color: "#eaecef",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <Trans>To</Trans>
                </Typography>
              </Box>
              <Box
                sx={{
                  borderRadius: "10px 10px 0 0",
                  border: "1px solid #2d2d2d",
                  background: "#000",
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  width: "100%",
                  height: "calc(100% - 40px)",
                  px: { xs: "10px", sm: "18px" },
                }}
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box
                  sx={{
                    width: { xs: "119px", sm: "147px" },
                    height: { xs: "47px", sm: "56px" },
                    borderRadius: "8px",
                    background: "#151515",
                    px: "12px",
                    cursor: "pointer",
                  }}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  // onClick={handleClick}
                  onClick={(e) => handleOpenTokenList(e, "out")}
                >
                  <SvgIcon
                    color="primary"
                    component={bottomIcon}
                    style={{
                      width: isSmallScreen ? "28px" : "32px",
                      height: isSmallScreen ? "28px" : "32px",
                      // marginRight: isSmallScreen ? "8px" : "8px",
                    }}
                    viewBox="0 0 32 32"
                  />
                  <Typography
                    sx={{
                      color: "#EAECEF",
                      fontSize: { xs: "18px", sm: "22px" },
                      fontWeight: "500",
                    }}
                  >
                    {bottomToken}
                  </Typography>
                  <SvgIcon
                    component={DownIcon}
                    viewBox="0 0 16 16"
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-end"}
                  sx={
                    {
                      // width: "100%",
                    }
                  }
                >
                  <InputBase
                    size="medium"
                    placeholder="0.0"
                    value={amountOut}
                    // value={amountIn}
                    // autoComplete={undefined}
                    // onBlur={e => onBlurTop(e.target.value)}
                    // onChange={run}
                    onChange={(e) => onChangeBottom(e.target.value)}
                    // onBlur={e => onBlurIpt(e.target.value)}
                    sx={{
                      color: "#fff",
                      textAlign: "right",
                      alignItems: "flex-end",
                      height: { xs: "47px", sm: "56px" },
                      fontSize: { xs: "20px", sm: "24px" },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "right",
                        height: "100%",
                        padding: "0",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "30px",
                mb: "30px",
              }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Box display="flex" alignItems={"center"}>
                <Typography
                  sx={{
                    color: "#eaecef",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <Trans>Balance</Trans>:
                </Typography>
                <Typography
                  sx={{
                    color: "#eaecef",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  {isAppLoading ? (
                    <Skeleton width="100px" />
                  ) : (
                    customNumberFormat(Number(bottomBal), 4)
                  )}
                </Typography>
              </Box>
            </Box>
            {/* swap action  */}
            {isAppLoading ? (
              <Skeleton width="100%" height="44px" />
            ) : !address ? (
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  height: "44px",
                  border: "1px solid #000",
                  background: "#FCD000",
                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                  mb: { xs: "10px", sm: "0" },
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <LoadingButton
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                    color: "#000",
                    "&:hover": {
                      background: "transparent",
                    },
                    // width: isSmallScreen ? "100%" : "240px",
                    // height: isSmallScreen ? "34px" : "40px",
                    // borderRadius: "30px",
                    // border: "1.3px solid #B50C05",
                    // background:
                    //   "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
                    // boxShadow:
                    //   "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
                  }}
                  variant="contained"
                  onClick={connect}
                  key={1}
                >
                  <Trans>Connect Wallet</Trans>
                </LoadingButton>
              </Box>
            ) : Number(hasAllowance(topToken)) >= Number(amountIn) ? (
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  height: "44px",
                  border: "1px solid #000",
                  background: "#FCD000",
                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                  mb: { xs: "10px", sm: "0" },

                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <LoadingButton
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                    color: "#000",
                    "&:hover": {
                      background: "transparent",
                    },
                  }}
                  onClick={exchangeTokenForToken}
                  className="approve-button"
                  variant="contained"
                  loading={isPendingTxn(pendingTransactions, "Swap_TOKEN")}
                  disabled={
                    isPendingTxn(pendingTransactions, "Swap_TOKEN") ||
                    !amountIn ||
                    !amountOut ||
                    Number(amountIn) > Number(topBal)
                  }
                  loadingPosition="end"
                >
                  {!amountIn && !amountOut
                    ? t`Enter an amount`
                    : Number(amountIn) > Number(topBal)
                    ? t`Insufficient balance`
                    : txnButtonText(pendingTransactions, "Swap_TOKEN", t`Swap`)}
                </LoadingButton>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  borderRadius: "6px",
                  height: "44px",
                  border: "1px solid #000",
                  background: "#FCD000",
                  boxShadow: "-3px 3px 0px 0px #ECAA00",
                  mb: { xs: "10px", sm: "0" },

                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <LoadingButton
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "transparent",
                    color: "#000",
                    "&:hover": {
                      background: "transparent",
                    },
                  }}
                  onClick={approveForSwap}
                  variant="contained"
                  loading={isPendingTxn(pendingTransactions, "approve_swap")}
                  disabled={isPendingTxn(pendingTransactions, "approve_swap")}
                  loadingPosition="end"
                >
                  {txnButtonText(
                    pendingTransactions,
                    "approve_swap",
                    t`Approve`
                  )}
                </LoadingButton>
              </Box>
            )}
            <Box sx={{ pt: { xs: "10px", sm: "20px" } }}>
              <Typography
                sx={{
                  color: "#eaecef",
                  fontSize: { xs: "12px", sm: "16px" },
                  fontWeight: "500",
                }}
                align="center"
              >
                <Trans>
                  The service is provided by PancakSwap for trading depth.
                </Trans>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        sx={{ zIndex: "1112" }}
      >
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper
                elevation={1}
                sx={{
                  // display: showPopper ? "initial" : "none",
                  background: "none",
                  pt: "12px",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    width: "136px",
                    height: "auto",
                    // height: "108px",
                    borderRadius: "4px",
                    border: "1px solid #2D2D2D",
                    background: "#202020",
                    boxShadow:
                      "0px 49px 14px 0px rgba(0, 0, 0, 0.01), 0px 31px 13px 0px rgba(0, 0, 0, 0.09), 0px 18px 11px 0px rgba(0, 0, 0, 0.30), 0px 8px 8px 0px rgba(0, 0, 0, 0.51), 0px 2px 4px 0px rgba(0, 0, 0, 0.59)",
                  }}
                >
                  {/* {(topToken != "NVB" || bottomToken != "NVB") && ( */}
                  <Box
                    sx={{
                      height: "54px",
                      width: "100%",
                      borderBottom: "1px solid #2d2d2d",
                      px: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      selectToken("NVB", OHMIcon, ohmBalance, inOrOut)
                    }
                    display="flex"
                    alignItems={"center"}
                  >
                    <SvgIcon
                      component={OHMIcon}
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                      viewBox="0 0 32 32"
                    />
                    <Typography
                      sx={{
                        ml: 1,
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#fff",
                      }}
                    >
                      NVB
                    </Typography>
                  </Box>
                  {/* )} */}
                  <Box
                    sx={{
                      height: "54px",
                      width: "100%",
                      borderBottom: "1px solid #2d2d2d",
                      px: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      selectToken("USDT", USDTIcon, usdtBalance, inOrOut)
                    }
                    display="flex"
                    alignItems={"center"}
                  >
                    <SvgIcon
                      component={USDTIcon}
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                      viewBox="0 0 32 32"
                    />
                    <Typography
                      sx={{
                        ml: 1,
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#fff",
                      }}
                    >
                      USDT
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={{
                      height: "54px",
                      width: "100%",
                      px: "15px",
                    }}
                    display="flex"
                    alignItems={"center"}
                  >
                    <SvgIcon
                      component={AVCIcon}
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                      viewBox="0 0 32 32"
                    />
                    <Typography
                      sx={{
                        ml: 1,
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#fff",
                      }}
                    >
                      AVC
                    </Typography>
                  </Box> */}
                  <Box
                    sx={{
                      height: "54px",
                      width: "100%",
                      px: "15px",
                      // borderBottom: "1px solid #2d2d2d",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      selectToken("WBNB", BNBIcon, usdtBalance, inOrOut)
                    }
                    display="flex"
                    alignItems={"center"}
                  >
                    <SvgIcon
                      component={BNBIcon}
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                      viewBox="0 0 32 32"
                    />
                    <Typography
                      sx={{
                        ml: 1,
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#fff",
                      }}
                    >
                      WBNB
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={{
                      height: "54px",
                      width: "100%",
                      px: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      selectToken("BNB", BNBIcon, usdtBalance, inOrOut)
                    }
                    display="flex"
                    alignItems={"center"}
                  >
                    <SvgIcon
                      component={BNBIcon}
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                      viewBox="0 0 32 32"
                    />
                    <Typography
                      sx={{
                        ml: 1,
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#fff",
                      }}
                    >
                      BNB
                    </Typography>
                  </Box> */}
                </Box>
              </Paper>
            </Fade>
          );
        }}
      </Popper>
      {/* setting */}
      <AdvancedSettings
        open={modalOpen}
        handleClose={handleClose}
        slippage={slippage}
        deadline={deadline}
        onDeadlineChange={onDeadlineChange}
        onDeadlineBlur={onDeadlineBlur}
        onSlippageChange={onSlippageChange}
        onSlippageBlur={onSlippageBlur}
        theme={"dark"}
        isBtn={isBtn}
      />
      {/* left top bg */}
      <Box
        sx={{
          position: "absolute",
          left: "-20px",
          top: { xs: "-50px", sm: "-25px" },
          width: { xs: "186px", sm: "390px" },
          height: { xs: "309px", sm: "509px" },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={FooterBg} />
      </Box>
    </Box>
  );
};

export default Swap;
