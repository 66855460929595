import { useSelector, useDispatch } from "react-redux";
import {
  getRebaseBlock,
  secondsUntilBlock,
  prettifySeconds,
} from "../../helpers";
import { Box, Typography } from "@mui/material";
import "./rebasetimer.scss";
import { Skeleton } from "@mui/material";
// import { Skeleton } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { loadAppDetails } from "../../slices/AppSlice";
import { useWeb3Context } from "../../hooks/web3Context";
import { Trans } from "@lingui/macro";

function RebaseTimer() {
  const dispatch = useDispatch();
  const { provider, chainID } = useWeb3Context();

  const SECONDS_TO_REFRESH = 60;
  const [secondsToRebase, setSecondsToRebase] = useState(0);
  const [rebaseString, setRebaseString] = useState("");
  const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);

  const currentBlock = useSelector((state) => {
    return state.app.currentBlock;
  });
  // const { data: currentBlock } = useBlockNumber({ pollInterval: 10000, watch: true });

  const endBlock = useSelector((state) => {
    return state.app.endBlock;
  });
  function initializeTimer() {
    // const rebaseBlock = getRebaseBlock(currentBlock);
    // console.log("seconds", currentBlock, endBlock, rebaseBlock);
    // const seconds = secondsUntilBlock(currentBlock, rebaseBlock);
    const seconds = secondsUntilBlock(currentBlock, endBlock);
    setSecondsToRebase(seconds);
    const prettified = prettifySeconds(seconds);
    setRebaseString(
      prettified !== "" ? prettified : <Trans>Less than a minute</Trans>
    );
  }

  // This initializes secondsToRebase as soon as currentBlock becomes available
  useMemo(() => {
    if (currentBlock) {
      initializeTimer();
    }
  }, [currentBlock]);

  // After every period SECONDS_TO_REFRESH, decrement secondsToRebase by SECONDS_TO_REFRESH,
  // keeping the display up to date without requiring an on chain request to update currentBlock.
  useEffect(() => {
    let interval = null;
    if (secondsToRefresh > 0) {
      interval = setInterval(() => {
        setSecondsToRefresh((secondsToRefresh) => secondsToRefresh - 1);
      }, 1000);
    } else {
      // When the countdown goes negative, reload the app details and reinitialize the timer
      if (secondsToRebase < 0) {
        async function reload() {
          await dispatch(
            loadAppDetails({ networkID: chainID, provider: provider })
          );
        }
        reload();
        setRebaseString("");
      } else {
        clearInterval(interval);
        setSecondsToRebase(
          (secondsToRebase) => secondsToRebase - SECONDS_TO_REFRESH
        );
        setSecondsToRefresh(SECONDS_TO_REFRESH);
        const prettified = prettifySeconds(secondsToRebase);
        setRebaseString(
          prettified !== "" ? prettified : <Trans>Less than a minute</Trans>
        );
      }
    }
    return () => clearInterval(interval);
  }, [secondsToRebase, secondsToRefresh]);

  return (
    <Box className="rebase-timer">
      <Typography
        sx={{
          color: "#fff",
          fontSize: { xs: "12px", sm: "18px" },
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {currentBlock ? (
          secondsToRebase > 0 ? (
            <>
              <strong>{rebaseString}&nbsp;</strong>
              <Trans>to next rebase</Trans>
            </>
          ) : (
            <strong>rebasing</strong>
          )
        ) : (
          <Skeleton width="155px" />
        )}
      </Typography>
    </Box>
  );
}

export default RebaseTimer;
