import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t, Trans } from "@lingui/macro";
import {
  shorten,
  trim,
  prettyVestingPeriod,
  customNumberFormat,
} from "../../helpers";
import { redeemBond } from "../../slices/BondSlice";
import BondLogo from "../../components/BondLogo";
import {
  Box,
  Button,
  Link,
  Paper,
  Typography,
  TableRow,
  TableCell,
  SvgIcon,
  Slide,
} from "@mui/material";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { NavLink } from "react-router-dom";
import "./choosebond.scss";
import { Skeleton } from "@mui/material";
import { useWeb3Context, useBonds } from "src/hooks";
import {
  isPendingTxn,
  txnButtonText,
  txnButtonTextGeneralPending,
} from "src/slices/PendingTxnsSlice";
import { LoadingButton } from "@mui/lab";

export function ClaimBondTableData({ userBond, isInvite = false }) {
  const dispatch = useDispatch();
  const { address, chainID, provider } = useWeb3Context();
  const { bonds, expiredBonds } = useBonds(chainID);
  console.log("[userBond]", { userBond });
  const bond = userBond[1];
  const bondName = bond.bond;

  const isAppLoading = useSelector((state) => state.app.loading ?? true);

  const currentBlock = useSelector((state) => {
    return state.app.currentBlock;
  });

  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
  };

  async function onRedeem({ autostake, id, type }) {
    // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
    let currentBond = [...bonds, ...expiredBonds].find(
      (bnd) => bnd.name === bondName
    );
    await dispatch(
      redeemBond({
        address,
        bond: currentBond,
        networkID: chainID,
        provider,
        autostake,
        id,
        isInvite,
        type,
      })
    );
  }

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
      isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
    ) {
      return true;
    }

    return false;
  };

  return (
    <TableRow id={`${bondName}--claim`}>
      <TableCell
        align="left"
        className="bond-name-cell"
        sx={{ borderBottom: "none", height: "59px" }}
      >
        <Box display="flex" alignItems={"center"}>
          <BondLogo bond={bond} />
          <Box sx={{ ml: 1 }} className="bond-name">
            <Typography
              sx={{
                color: "#EAECEF",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              {bond.displayName ? bond.displayName : <Skeleton width={100} />}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          borderBottom: "none",
          height: "59px",
          color: "#EAECEF",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {bond.pendingPayout ? (
          customNumberFormat(Number(bond.pendingPayout), 4)
        ) : (
          <Skeleton width={100} />
        )}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          borderBottom: "none",
          height: "59px",
          color: "#EAECEF",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
        }}
      >
        {bond.interestDue ? (
          customNumberFormat(Number(bond.interestDue), 4)
        ) : (
          <Skeleton width={100} />
        )}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          borderBottom: "none",
          height: "59px",
          color: "#EAECEF",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          whiteSpace: "nowrap",
        }}
      >
        {isAppLoading ? <Skeleton /> : vestingPeriod()}
      </TableCell>
      <TableCell align="right" sx={{ borderBottom: "none", height: "59px" }}>
        <Box
          sx={{
            width: "100%",
          }}
          display={"flex"}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "144px" },
              height: "33px",
              borderRadius: "6px",
              border: "1px solid #000",
              background: "#fcd000",
              boxShadow: "-3px 3px 0px 0px #ECAA00",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <LoadingButton
              variant="contained"
              sx={{
                width: "100%",
                height: "100%",
                background: "transparent",
                "&:hover": {
                  background: "transparent",
                },
              }}
              disabled={
                isPendingTxn(pendingTransactions, "redeem_bond") ||
                bond.pendingPayout == 0.0 ||
                pendingClaim()
              }
              loading={
                isPendingTxn(pendingTransactions, "redeem_bond") ||
                pendingClaim()
              }
              loadingPosition="end"
              onClick={() =>
                onRedeem({ autostake: true, id: bond.id, type: "redeem_bond" })
              }
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {txnButtonTextGeneralPending(
                  pendingTransactions,
                  "redeem_bond",
                  isInvite ? t`Claim` : t`Claim and Stake`
                )}
              </Typography>
            </LoadingButton>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export function ClaimBondCardData({ userBond, isInvite = false }) {
  const dispatch = useDispatch();
  const { address, chainID, provider } = useWeb3Context();
  const { bonds, expiredBonds } = useBonds(chainID);

  const bond = userBond[1];
  const bondName = bond.bond;
  const currentBlock = useSelector((state) => {
    return state.app.currentBlock;
  });

  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    return prettyVestingPeriod(currentBlock, bond.bondMaturationBlock);
  };

  const pendingClaim = () => {
    if (
      isPendingTxn(pendingTransactions, "redeem_all_bonds") ||
      isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")
    ) {
      return true;
    }

    return false;
  };

  async function onRedeem({ autostake, id, type }) {
    // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
    // let currentBond = [...bonds, ...expiredBonds].find(bnd => bnd.name === bondName);
    let currentBond = [...bonds, ...expiredBonds].find(
      (bnd) => bnd.name === bondName
    );

    await dispatch(
      redeemBond({
        address,
        bond: currentBond,
        networkID: chainID,
        provider,
        autostake,
        id,
        isInvite,
        type,
      })
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        borderBottom: "1px solid #2d2d2d",
        py: "20px",
        mb: "16px",
      }}
    >
      <Box
        display="flex"
        alignItems={"center"}
        sx={{
          mb: "25px",
        }}
      >
        <BondLogo bond={bond} />
        <Box
          sx={{
            ml: "10px",
          }}
        >
          <Typography
            sx={{
              color: "#EAECEF",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            {bond.displayName ? bond.displayName : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#848E9C",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Trans>Claimable</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          {bond.pendingPayout ? (
            customNumberFormat(Number(bond.pendingPayout), 4)
          ) : (
            <Skeleton width={100} />
          )}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#848e9c",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Trans>Pending</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {bond.interestDue ? (
            customNumberFormat(Number(bond.interestDue), 4)
          ) : (
            <Skeleton width={100} />
          )}
        </Typography>
      </Box>

      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          mb: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#848e9c",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <Trans>Fully Vested</Trans>
        </Typography>
        <Typography
          sx={{
            color: "#EAECEF",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          {vestingPeriod()}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        {/* {!isInvite && ( */}
        <Box
          sx={{
            width: "100%",
            borderRadius: "6px",
            height: "33px",
            border: "1px solid #000",
            background: "#FCD000",
            boxShadow: "-3px 3px 0px 0px #ECAA00",
          }}
        >
          <LoadingButton
            variant="contained"
            sx={{
              width: "100%",
              height: "100%",
              background: "transparent",
              "&:hover": {
                background: "transparent",
              },
            }}
            loadingPosition="end"
            loading={
              isPendingTxn(pendingTransactions, "redeem_bond") || pendingClaim()
            }
            onClick={() =>
              onRedeem({ autostake: true, id: bond.id, type: "redeem_bond" })
            }
            disabled={
              isPendingTxn(pendingTransactions, "redeem_bond") ||
              bond.pendingPayout == 0.0 ||
              pendingClaim()
            }
          >
            <Typography
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {txnButtonText(
                pendingTransactions,
                "redeem_bond",
                isInvite ? t`Claim` : t`Claim and Stake`
              )}
            </Typography>
          </LoadingButton>
        </Box>
        {/* )} */}
      </Box>
    </Box>
  );
}
