import React from "react";
import Loan1 from "../../assets/images/loan-1.png";
import Loan2 from "../../assets/images/loan-2.png";
import Loan3 from "../../assets/images/loan-3.png";
import Loan4 from "../../assets/images/loan-4.png";
import LoanBg from "../../assets/images/loan-bg.png";

import { Box, Tooltip, Typography } from "@mui/material";

const MapLoan = () => {
  return (
    <Box
      sx={{
        width: "20%",
        height: "22.08%",
        // background: `url(${RWABg}) no-repeat 100% 100%`,
        // backgroundSize: "cover",
        position: "absolute",
        inset: "64% auto auto 18%",
        "&> img": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <img src={LoanBg} />
      <Box
        sx={{
          position: "absolute",
          inset: "40% auto auto -87%",
          width: "92.47%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Loan2} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "-13% auto auto -52%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Loan1} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "90% auto auto 73%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Loan4} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          inset: "87% auto auto -53%",
          width: "76.54%",
          height: "27.58%",
          cursor: "pointer",
          "&:hover": {
            drop: "drop-shadow(0 10px 20px #cdd1fa)",
            transform: "translate(0,-1px)",
          },
          "&> img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={Loan3} />
      </Box>
    </Box>
  );
};

export default MapLoan;
